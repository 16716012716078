import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { GetReconciliationPopupThunk } from "../../../../store/features/accounting/getreconciliationpopup/slice";
// import { SaveReconciliationStatusThunk } from "../../../../store/features/accounting/savereconciliationstatus/slice";
import {
  GetGAAPReconciliationListThunk,
  resetGAAPMonthlyReconciliation,
} from "../../../../store/features/gaap-accounting/get-gaap-accounting-monthly-reconciliation/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
import { formatCUMCurrency } from "../../../../utils/helper";
// import {
//   errorToastMessage,
//   successToastMessage,
// } from "../../../../utils/toast-message";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

// import currencyFormat from "../../../utils/currency-formatter";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function GaapMonthlyReconciliation() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const GetUserList = useAppSelector(
    (state) => state.getGAAPReconciliationList.data
  );

  //   const flag = useAppSelector((state) => state.GetReconciliationList.status);
  console.log("GAAPComp", GetUserList);

  //   const GetTotalExpense = useAppSelector(
  //     (state) => state.GetTotalYearMonthExpenses?.data
  //   );
  //   const [id, setId] = useState<any>();
  // const [excelData, setExcelData] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [stateName, setName] = useState<any>(false);
  const [modalvisible, setmodalvisible] = useState(false);
  const [popupData, setPopupData] = useState<PopupDataItem[]>([]);
  const [openDailog, setOpenDailog] = useState(false);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const fieldValuesColor = useAppSelector((state) => state.color.Color);
  const [popuptype, setpopuptype] = useState("");
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);

  interface PopupDataItem {
    partnerType: string;
    name: string;
    capitalContibutionAmount: number;
    capitalWithdrawalAmount: number;
    interestIncomeAmount: number;
    equityCashDistriAmount: number;
  }

  console.log(popupData, "popupData");
  const formik = useFormik({
    initialValues: {
      Year: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (!data.Year) {
        errors.Year = "Please enter year";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      //
    },
  });
  console.log("remark Empty2", userList, GetUserList);

  useEffect(() => {
    if (GetUserList.length > 0) {
      setUserList(GetUserList);
    }
  }, [GetUserList]);
  // useEffect(() => {
  //   const saveParam = {
  //     LEID: fundDropdown.fundid,
  //     Year: formik.values.Year.toString(),
  //   };
  //   dispatch(GetReconciliationListThunk(saveParam))
  //     .then((res: any) => {
  //       setName(false);
  //       setloading(false);
  //     })
  //     .catch(() => {
  //       setloading(false);
  //     });
  // }, [fundDropdown.fundid]);
  useEffect(() => {
    setName(true);
    formik.resetForm();
    setUserList([]);
    dispatch(resetGAAPMonthlyReconciliation());
  }, [fundDropdown?.fundid]);

  useEffect(() => {
    setName(true);
  }, [formik.values.Year]);

  useEffect(() => {
    setloading(false);
    setName(true);
  }, []);

  const handleCancel = () => {
    setmodalvisible(false);
    setpopuptype("");
    setPopupData([]);
  };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const cumulativeValue: any = userList
    ? userList![0]?.cumulativeEqDistiForPastYears
    : "";

  const createRawObj = (Object: any) => {
    const tableData: any = [];
    if (userList.length > 0) {
      for (const value of Object) {
        console.log("Row1", value);
        const obj = JSON.parse(JSON.stringify(value));
        tableData.push(obj);
      }
    }
    return tableData;
  };

  const tableData = createRawObj(userList);

  const ReconciledButton = (rowData: any, index: any) => {
    console.log(rowData.id);
    const [editedValue, setEditedValue] = useState(rowData.reconciled);
    const handleInputChange = (e: { target: { value: any } }, id: any) => {
      const newValue = e.target.value;
      setEditedValue(newValue);

      tableData[index.rowIndex].reconciled = newValue;

      // for (const value of tableData) {
      //   // console.log("value", value);
      //   if (value.id === id) {
      //     value.businessIncome = newValue;
      //   }
      // }

      console.log("tableData sndfksndfksndf", tableData);
    };
    return (
      <div className="flex">
        <div className="p-field-radiobutton">
          <RadioButton
            inputId="yesRadio"
            name="radio"
            value={1}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            checked={editedValue === 1}
          />
          <label htmlFor="yesRadio">Yes</label>
        </div>
        <div className="p-field-radiobutton pl-4">
          <RadioButton
            inputId="noRadio"
            name="radio"
            disabled={true}
            value={0}
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            checked={editedValue === 0}
          />
          <label htmlFor="noRadio">No</label>
        </div>
      </div>
    );
  };
  //   const waveAssetIncomeCellEditor = (rowData: any, index: any) => {
  //     // console.log("yes------", rowData.waveAssetIncome);
  //     // const numberOnlyString = +rowData.waveAssetIncome.replace(/[^\d.]/g, "");
  //     const endOfTheMonthValue11 = rowData.endOfTheMonthValue.replace(
  //       /^-?[^\d.]/g,
  //       ""
  //     );
  //     console.log("newList index", index, index.rowIndex);
  //     console.log("newList rowData", rowData);

  //     // const [editedValue, setEditedValue] = useState(numberOnlyString);
  //     // useEffect(() => {
  //     //   setEditedValue(numberOnlyString);
  //     // }, [numberOnlyString]);

  //     const handleInputChange = (e: any, id: any, rowIndex: any) => {
  //       console.log("newList handleInputChange", rowIndex);
  //       let newValue = e.value;
  //       if (newValue === null) {
  //         newValue = 0;
  //       }

  //       console.log("newList Party Country", id);
  //       // setEditedValue(newValue);
  //       // tableData[index.rowIndex].waveAssetIncome = newValue.toString();
  //       console.log(
  //         "yesingngg----------",
  //         newValue
  //         // editedValue
  //       );
  //       const difference = newValue - endOfTheMonthValue11;
  //       console.log("Diff", newValue, difference, typeof difference);

  //       const newList = userList.map((item: any) => {
  //         if (item.yearMonth === rowData.yearMonth) {
  //           return {
  //             ...item,
  //             waveAssetIncome: newValue,
  //             diffInWaveAssetPartnershipAssets: difference,
  //           };
  //         }
  //         return item;
  //       });
  //       console.log("newList", newList);
  //       setUserList(newList);
  //       // tableData[index.rowIndex].waveAssetIncome = newValue.toString();
  //       // const difference = +newValue - +rowData.endOfTheMonthValue;
  //       // userList[index.rowIndex].diffInWaveAssetPartnershipAssets =
  //       //   difference ?? difference;
  //       // tableData[index.rowIndex].diffInWaveAssetPartnershipAssets = difference
  //       //   ? difference.toString()
  //       //   : 0;
  //     };

  //     return (
  //       <div>
  //         <div className="flex justify-content-center flex-column relative-container">
  //           <InputNumber
  //             inputId="currency-us"
  //             value={rowData.waveAssetIncome}
  //             className="gridinputcell"
  //             onChange={(e: any) =>
  //               handleInputChange(e, rowData.id, index.rowIndex)
  //             }
  //             mode="currency"
  //             currency="USD"
  //             locale="en-US"
  //           />
  //         </div>
  //       </div>
  //     );
  //   };

  // eslint-env es6

  // tableData = [...GetUserList!];

  console.log("tableData", tableData);
  // const tableData = Object.create(GetUserList);
  const CellEditor = (rowData: any, index: any) => {
    const numberOnlyString = +rowData.businessIncome.replace(/[^\d.]/g, "");

    const [editedValue, setEditedValue] = useState(numberOnlyString);
    useEffect(() => {
      setEditedValue(numberOnlyString);
    }, [numberOnlyString]);

    const handleInputChange = (e: any, id: any) => {
      let newValue = e.value;
      if (newValue === null) {
        newValue = 0;
      }
      setEditedValue(newValue);
      tableData[index.rowIndex].businessIncome = newValue.toString();
    };

    return (
      <div>
        <div className="flex justify-content-center flex-column relative-container">
          <InputNumber
            inputId="currency-us"
            value={editedValue}
            className="gridinputcell"
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
      </div>
    );
  };

  const realEstateCellEditor = (rowData: any, index: any) => {
    const numberOnlyString = +rowData.realEstateIncome.replace(/[^\d.]/g, "");

    const [editedValue, setEditedValue] = useState(numberOnlyString);
    const handleInputChange = (e: any, id: any) => {
      let newValue = e.value;
      if (newValue === null) {
        newValue = 0;
      }

      setEditedValue(newValue);

      tableData[index.rowIndex].realEstateIncome = newValue.toString();
    };

    return (
      <div>
        <div className="flex justify-content-center flex-column relative-container">
          <InputNumber
            inputId="currency-us"
            value={editedValue}
            className="gridinputcell"
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
      </div>
    );
  };

  const otherAssignmentsCellEditor = (rowData: any, index: any) => {
    const numberOnlyString = +rowData.adjustment.replace(/[^\d.]/g, "");

    const [editedValue, setEditedValue] = useState(numberOnlyString);

    const handleInputChange = (e: any, id: any) => {
      let newValue = e.value;
      if (newValue === null) {
        newValue = 0;
      }
      setEditedValue(newValue);

      tableData[index.rowIndex].adjustment = newValue.toString();
    };

    return (
      <div>
        <div className="flex justify-content-center flex-column relative-container">
          <InputNumber
            inputId="currency-us"
            value={editedValue}
            className="gridinputcell"
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
      </div>
    );
  };
  const remarkCellEditor = (rowData: any, index: any) => {
    const { remark } = rowData;
    const [editedValue, setEditedValue] = useState(remark);
    console.log(
      "remarkCellEditor check",
      rowData,
      editedValue,
      rowData.remark,
      remark
    );
    useEffect(() => {
      setEditedValue(remark);
    }, [remark]);
    const handleInputChange = (e: { target: { value: any } }, id: any) => {
      console.log("idd---", id);
      console.log("GetUserList", GetUserList);
      const newValue = e.target.value;
      setEditedValue(newValue);
      console.log("tableData", tableData);
      console.log("newValue", newValue);
      tableData[index.rowIndex].remark = newValue;

      // for (const value of tableData) {
      //   // console.log("value", value);
      //   if (value.id === id) {
      //     value.remark = newValue;
      //   }
      // }

      console.log("tableData sndfksndfksndf", tableData);
    };

    return (
      <div>
        <div className="flex justify-content-center flex-column relative-container">
          <InputText
            type="text"
            value={editedValue}
            className="p-1"
            onChange={(e: any) => handleInputChange(e, rowData.id)}
            style={{ fontSize: "11px" }}
          />
        </div>
      </div>
    );
  };

  const formatCurrency = (rowData: any) => {
    // if (rowData === undefined || rowData === null) {
    //   return "";
    // }
    // console.log(rowData, "rowdata reconcil");
    // const finalValue = rowData.search("$")
    //   ? Number.parseFloat(rowData.replace("$", "")).toFixed(2)
    //   : Number(rowData).toFixed(2);
    // const parseValue = currencyFormat(Number(finalValue));
    // return `${parseValue}`;

    if (rowData === undefined || rowData === null) {
      return "";
    }
    const rowDataString = rowData.toString();
    console.log(rowDataString, "rowdata reconcil");
    const finalValue = rowDataString.search("$")
      ? Number.parseFloat(rowDataString.replace("$", ""))
      : Number(rowDataString);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };

  const actionBodyTemplate = (rowData: any, column: any, type: any) => {
    const fieldValue = rowData[column];
    const legalEntityId = fundDropdown.fundid;
    const yearMonth = rowData.yearMonth;

    const handleClick = () => {
      setIsPopupLoading(true);
      const params = {
        LegalEntityId: legalEntityId,
        YearMonth: yearMonth,
        Type: type,
      };
      dispatch(GetReconciliationPopupThunk(params))
        .then((response) => {
          const { data } = response.payload._response;
          setPopupData(data);
          setpopuptype(type);
          setmodalvisible(true);
          setIsPopupLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching popup data:", error);
          setIsPopupLoading(false);
        });
    };

    return (
      <>
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: `${fieldValuesColor}`,
          }}
          onClick={(e) => {
            e.preventDefault();
            setmodalvisible(true);
            handleClick();
          }}
        >
          {formatCurrency(fieldValue)}
        </span>
      </>
    );
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const renderPopupDataTable = (popupDataItems: any, type: any) => {
    const nameColumn = (rowData: any) => {
      return rowData.name || "";
    };
    let totalAmount = 0;
    for (const data of popupDataItems) {
      let amount = 0;

      switch (type) {
        case "Capital Contribution":
          amount = data.capitalContibutionAmount || 0;
          break;
        case "Capital Withdrawal":
          amount = data.capitalWithdrawalAmount || 0;
          break;
        case "Interest Income":
          amount = data.interestIncomeAmount || 0;
          break;
        case "Equity Cash Distribution":
          amount = data.equityCashDistriAmount || 0;
          break;
        default:
          break;
      }

      totalAmount += amount || 0;
    }

    return (
      <div>
        {isPopupLoading ? (
          <Loader />
        ) : (
          <DataTable value={popupData}>
            <Column field="name" header="Investor Account" body={nameColumn} />
            {type === "Capital Contribution" && (
              <Column
                field="capitalContibutionAmount"
                header="Amount"
                body={(rowData) =>
                  currencyFormat(rowData.capitalContibutionAmount)
                }
              />
            )}
            {type === "Capital Withdrawal" && (
              <Column
                field="capitalWithdrawalAmount"
                header="Amount"
                body={(rowData) =>
                  currencyFormat(rowData.capitalWithdrawalAmount)
                }
              />
            )}
            {type === "Interest Income" && (
              <Column
                field="interestIncomeAmount"
                header="Amount"
                body={(rowData) => currencyFormat(rowData.interestIncomeAmount)}
              />
            )}
            {type === "Equity Cash Distribution" && (
              <Column
                field="equityCashDistriAmount"
                header="Amount"
                body={(rowData) =>
                  currencyFormat(rowData.equityCashDistriAmount)
                }
              />
            )}
          </DataTable>
        )}
        <div className="mt-3 d-flex">
          <div
            style={{
              marginLeft: "16px",
              fontWeight: "600",
            }}
          >
            Total Amount:{" "}
          </div>
          <div
            style={{
              marginLeft: "10px",
              fontWeight: "600",
            }}
          >
            {formatCurrency(totalAmount)}
          </div>
        </div>
      </div>
    );
  };
  const dataTableElements = [
    {
      field: "yearMonth",
      header: "Month",
      style: { minWidth: "150px", minHeight: "30px" },
      // dataType: "date",
      // filterMatchMode: "contains", // Add filterMatchMode here
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "interestIncomeVal",
      header: "Interest Income",
      // body: (rowData: any) => formatCurrency(rowData.interestIncomeVal),
      body: (rowData: any) =>
        actionBodyTemplate(rowData, "interestIncomeVal", "Interest Income"),
      style: { minWidth: "200px" },
      // body: formatCurrency,
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "equityCashDistriVal",
      header: "Equity Cash Distribution",
      // body: (rowData: any) => formatCurrency(rowData.equityCashDistriVal),
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,

      body: (rowData: any) =>
        actionBodyTemplate(
          rowData,
          "equityCashDistriVal",
          "Equity Cash Distribution"
        ),
      style: { minWidth: "200px" },
    },

    {
      field: "otherIncomeVal",
      header: "Other Income",
      body: (rowData: any) => formatCurrency(rowData.otherIncomeVal),
      style: { minWidth: "180px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "capitalAdded",
      header: "Capital Contribution",
      // body: (rowData: any) => formatCurrency(rowData.capitalAdded),
      body: (rowData: any) =>
        actionBodyTemplate(rowData, "capitalAdded", "Capital Contribution"),
      style: { minWidth: "180px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "lpCapitalContribution",
      header: "LP Capital Contribution",
      body: (rowData: any) => formatCurrency(rowData.lpCapitalContribution),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "gpCapitalContribution",
      header: "GP Capital Contribution",
      body: (rowData: any) => formatCurrency(rowData.gpCapitalContribution),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "capitalWithdrawal",
      header: "Capital Withdrawal",
      body: (rowData: any) =>
        actionBodyTemplate(rowData, "capitalWithdrawal", "Capital Withdrawal"),
      style: { minWidth: "180px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },

    {
      field: "lpCapitalWithdrawal",
      header: "LP Capital Withdrawal",
      body: (rowData: any) => formatCurrency(rowData.lpCapitalWithdrawal),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "gpCapitalWithdrawal",
      header: "GP Capital Withdrawal",
      body: (rowData: any) => formatCurrency(rowData.gpCapitalWithdrawal),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },

    {
      field: "supplierCashInterest",
      header: "Supplier Cash Interest",
      body: (rowData: any) => formatCurrency(rowData.supplierCashInterest),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "managerFeePayout",
      header: "Manager Fee Payout",
      body: (rowData: any) => formatCurrency(rowData.managerFeePayout),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "managerBusinessIncome",
      header: "Manager Business Income",
      body: (rowData: any) => formatCurrency(rowData.managerBusinessIncome),
      style: { minWidth: "230px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "achAmount",
      header: "ACH Initiallted Amount",
      body: (rowData: any) => formatCurrency(rowData.achAmount),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "gaapAssets",
      header: "GAAP Assets",
      body: (rowData: any) => formatCurrency(rowData.gaapAssets),
      style: { minWidth: "170px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },

    {
      field: "monthlyExpenses",
      header: "Total Expenses",
      body: (rowData: any) => formatCurrency(rowData.monthlyExpenses),
      style: { minWidth: "170px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "totalMangeFee",
      header: "Management Fees",
      body: (rowData: any) => formatCurrency(rowData.totalMangeFee),
      style: { minWidth: "180px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "badDebtVal",
      header: "Bad Debt",
      body: (rowData: any) => formatCurrency(rowData.badDebtVal),
      filterMatchMode: "contains", // Add filterMatchMode here
      style: { minWidth: "180px" },
      noFilter: true,
      noSort: true,
    },
    {
      field: "businessIncome",
      header: "Business Income/Loss Adjustments",
      // body: (rowdata: any) => CellEditor(rowdata),
      body: (rowData: any) => formatCUMCurrency(rowData.businessIncome),

      //   body: (rowData: any, index: any) => CellEditor(rowData, index),
      style: { minWidth: "300px" },
      //   filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },

    {
      field: "realEstateIncome",
      header: "Real Estate Income/Loss Adjustments",
      body: (rowData: any) => formatCUMCurrency(rowData.realEstateIncome),
      //   body: (rowData: any, index: any) => realEstateCellEditor(rowData, index),
      style: { minWidth: "300px" },
      //   filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "adjustment",
      header: "Other Adjustments",
      body: (rowData: any) => formatCUMCurrency(rowData.adjustment),
      //   body: (rowData: any, index: any) =>
      //     otherAssignmentsCellEditor(rowData, index),
      style: { minWidth: "180px" },
      //   filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "cumAdjustment",
      header: "Cumulative Adjustments",
      body: (rowData: any) => formatCUMCurrency(rowData.cumAdjustment),
      style: { minWidth: "220px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
      // body: (rowData: any) => {
      //   formatDecimal(rowData.cumAdjustment);
      // },
    },
    {
      field: "waveAssetIncome",
      header: "Wave Asset Income",
      body: (rowData: any) => formatCurrency(rowData.waveAssetIncome),
      style: { minWidth: "200px" },
      noFilter: true,
      noSort: true,
    },

    {
      field: "endOfTheMonthValue",
      header: "Partnership Assets (End Of Month)",
      body: (rowData: any) => formatCUMCurrency(rowData.endOfTheMonthValue),
      style: { minWidth: "290px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
      // body: formatDecimal,
    },
    {
      field: "diffInWaveAssetPartnershipAssets",
      header: "Wave-Av Delta",
      body: (rowdata: any) =>
        currencyFormat(rowdata.diffInWaveAssetPartnershipAssets),
      // body: (rowData: any, index: any) =>
      //   waveAssetIncomeCellEditor(rowData, index),
      style: { minWidth: "280px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "cumulativeEqCashDisti",
      header: "Current Year Cum Equity Cash Distribution",
      body: (rowData: any) => formatCurrency(rowData.cumulativeEqCashDisti),
      style: { minWidth: "300px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
    {
      field: "reconciled",
      header: "Reconciled",
      body: (rowData: any, index: any) => ReconciledButton(rowData, index),
      style: { minWidth: "150px" },
      noFilter: true,
      noSort: true,
    },
    {
      field: "remark",
      header: "Remark",
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.remark}>
          {rowData?.remark}
        </div>
      ),
      //   body: (rowData: any, index: any) => remarkCellEditor(rowData, index),
      style: { minWidth: "200px" },
      filterMatchMode: "contains", // Add filterMatchMode here
      noFilter: true,
      noSort: true,
    },
  ];

  const exportExcelHeader = [
    {
      field: "yearMonth",
      header: "Month",
      // dataType: "date",
    },
    {
      field: "interestIncomeVal",
      header: "Interest Income",
      body: (rowData: any) => formatCurrency(rowData.interestIncomeVal),
      dataType: "stringCurrency",
      // body: formatCurrency,
    },
    {
      field: "equityCashDistriVal",
      header: "Equity Cash Distribution",
      body: (rowData: any) => formatCurrency(rowData.equityCashDistriVal),
      dataType: "stringCurrency",
    },

    {
      field: "otherIncomeVal",
      header: "Other Income",
      body: (rowData: any) => formatCurrency(rowData.otherIncomeVal),
      dataType: "stringCurrency",
    },
    {
      field: "capitalAdded",
      header: "Capital Contribution",
      body: (rowData: any) => formatCurrency(rowData.capitalAdded),
      dataType: "stringCurrency",
    },
    {
      field: "lpCapitalContribution",
      header: "LP Capital Contribution",
      body: (rowData: any) => formatCurrency(rowData.lpCapitalContribution),
      dataType: "stringCurrency",
    },
    {
      field: "gpCapitalContribution",
      header: "GP Capital Contribution",
      body: (rowData: any) => formatCurrency(rowData.gpCapitalContribution),
      dataType: "stringCurrency",
    },
    {
      field: "lpCapitalWithdrawal",
      header: "LP Capital Withdrawal",
      body: (rowData: any) => formatCurrency(rowData.lpCapitalWithdrawal),
      dataType: "stringCurrency",
    },
    {
      field: "gpCapitalWithdrawal",
      header: "GP Capital Withdrawal",
      body: (rowData: any) => formatCurrency(rowData.gpCapitalWithdrawal),
      dataType: "stringCurrency",
    },
    {
      field: "capitalWithdrawal",
      header: "Capital Withdrawal",
      body: (rowData: any) => formatCurrency(rowData.capitalWithdrawal),
      dataType: "stringCurrency",
    },
    {
      field: "supplierCashInterest",
      header: "Supplier Cash Interest",
      body: (rowData: any) => formatCurrency(rowData.supplierCashInterest),
      dataType: "stringCurrency",
    },
    {
      field: "managerFeePayout",
      header: "Manager Fee Payout",
      body: (rowData: any) => formatCurrency(rowData.managerFeePayout),
      dataType: "stringCurrency",
    },
    {
      field: "managerBusinessIncome",
      header: "Manager Business Income",
      body: (rowData: any) => formatCurrency(rowData.managerBusinessIncome),
      dataType: "stringCurrency",
    },
    {
      field: "achAmount",
      header: "ACH Initiallted Amount",
      body: (rowData: any) => formatCurrency(rowData.achAmount),
      dataType: "stringCurrency",
    },
    {
      field: "gaapAssets",
      header: "GAAP Assets",
      body: (rowData: any) => formatCurrency(rowData.gaapAssets),
      dataType: "stringCurrency",
    },
    {
      field: "monthlyExpenses",
      header: "Total Expenses",
      body: (rowData: any) => formatCurrency(rowData.monthlyExpenses),
      dataType: "stringCurrency",
    },
    {
      field: "totalMangeFee",
      header: "Management Fees",
      body: (rowData: any) => formatCurrency(rowData.totalMangeFee),
      dataType: "stringCurrency",
    },
    {
      field: "badDebtVal",
      header: "Bad Debt",
      body: (rowData: any) => formatCurrency(rowData.badDebtVal),
      dataType: "stringCurrency",
    },
    {
      field: "businessIncome",
      header: "Business Income/Loss Adjustments",
      // body: (rowdata: any) => CellEditor(rowdata),
      body: (rowData: any, index: any) => CellEditor(rowData, index),
      dataType: "stringCurrency",
    },
    {
      field: "realEstateIncome",
      header: "Real Estate Income/Loss Adjustments",
      body: (rowData: any, index: any) => realEstateCellEditor(rowData, index),
      dataType: "stringCurrency",
    },
    {
      field: "adjustment",
      header: "Other Adjustments",
      body: (rowData: any, index: any) =>
        otherAssignmentsCellEditor(rowData, index),
      dataType: "stringCurrency",
    },
    {
      field: "cumAdjustment",
      header: "Cumulative Adjustments",
      body: (rowData: any) => formatCUMCurrency(rowData.cumAdjustment),
      dataType: "diffcurrency",
      // body: (rowData: any) => {
      //   formatDecimal(rowData.cumAdjustment);
      // },
    },
    {
      field: "waveAssetIncome",
      header: "Wave Asset Income",
      body: (rowData: any) => formatCurrency(rowData.waveAssetIncome),
      dataType: "stringCurrency",
    },
    {
      field: "endOfTheMonthValue",
      header: "Partnership Assets (End Of Month)",
      body: (rowData: any) => formatCUMCurrency(rowData.endOfTheMonthValue),
      // body: formatDecimal,
      dataType: "diffcurrency",
    },
    {
      field: "diffInWaveAssetPartnershipAssets",
      header: "Wave-Av Delta",
      body: (rowData: any) =>
        currencyFormat(rowData.diffInWaveAssetPartnershipAssets),
      dataType: "stringCurrency",
    },
    {
      field: "cumulativeEqCashDisti",
      header: "Current Year Cum Equity Cash Distribution",
      body: (rowData: any) => formatCurrency(rowData.cumulativeEqCashDisti),
      dataType: "stringCurrency",
    },
    {
      field: "reconciled",
      header: "Reconciled",
      body: (rowData: any, index: any) => ReconciledButton(rowData, index),
      dataType: "noyes",
    },
    {
      field: "remark",
      header: "Remark",
      body: (rowData: any, index: any) => remarkCellEditor(rowData, index),
    },
  ];

  const handleChange = (e: any, selectedYear: any) => {
    const { name } = e.target;
    formik.setFieldValue(name, selectedYear);
    setloading(true);
    dispatch(resetGAAPMonthlyReconciliation());
    setUserList([]);
    const saveParam = {
      LEID: fundDropdown.fundid,
      Year: selectedYear.toString(),
    };
    dispatch(GetGAAPReconciliationListThunk(saveParam))
      .then((res: any) => {
        // const resp = res?.payload._response.data.getReconciliation;
        // if (resp !== undefined || resp !== null || resp?.length > 0) {
        //   setUserList(resp);
        //   console.log("DataSet", userList, GetUserList);
        // }
        // console.log("res", resp);
        setName(false);
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      });
  };
  const onCancelYear = () => {
    dispatch(resetGAAPMonthlyReconciliation());
    setUserList([]);
    formik.resetForm();
  };
  return (
    <>
      {loading && <Loader />}
      <Dialog
        visible={modalvisible}
        onHide={() => handleCancel()}
        className="w-100 md:w-5"
      >
        {<div>{renderPopupDataTable(popupData, popuptype)}</div>}
      </Dialog>
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={visible}
        className="w-75 md:w-3 DialogInvestment"
        onHide={() => setVisible(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          Are you sure you want to delete ?
        </h4>

        <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
          <Button
            //   onClick={onDelete}
            className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setVisible(false);
            }}
            className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
          >
            No
          </Button>
        </div>
      </Dialog>
      <div className="grid col-12 p-0">
        <div className="col-12 md:col-9  pl-1 p-0">
          <h1 className="pageHeader fs-2">Monthly Reconciliation - (GAAP) </h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className=" col-12 md:col-3 flex justify-content-end">
          <Button
            className="remove-shadow"
            type="button"
            // icon="pi pi-file"
            // rounded
            onClick={() =>
              exportExcel(
                exportExcelHeader,
                userList,
                "Monthly Reconciliation-(GAAP)",
                true
              )
            }
            data-pr-tooltip="exportExcel"
            disabled={
              GetUserList === undefined ||
              GetUserList?.length === 0 ||
              GetUserList === null
            }
          >
            {" "}
            <span className="blueButtonShadowText">Download </span>
          </Button>
          <div className="flex align-items-center gap-2 ml-2 mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetUserList === undefined ||
                GetUserList?.length === 0 ||
                GetUserList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      {/* <div className=" col-12 md:col-5 flex justify-content-end mt-2 ml-6">
     
      </div> */}
      <div className="card">
        {/* <div className="col-12 flex"> */}
        <div
          className="row sm:flex align-items-center col-12 m-0 p-0"
          // style={{ height: "3rem" }}
        >
          <div className="col-12 md:col-2 sm:col-2 m-0 mb-md-0 ">
            <div className="d-flex align-items-center">
              <Calendar
                name="Year"
                value={
                  formik.values.Year === null || formik.values.Year === ""
                    ? undefined
                    : new Date(+formik.values.Year, 0, 1)
                }
                onChange={(e) => {
                  const selectedYear = new Date(
                    Number(e.target.value)
                  ).getFullYear();
                  handleChange(e, selectedYear);
                  // formik.setFieldValue("Year", selectedYear);
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("Year"),
                  "card-inputBox": true,
                })}
                view="year"
                dateFormat="yy"
                placeholder="Select year *"
              />
              {getFormErrorMessage("Year")}
            </div>
          </div>

          {/* <div className="col-12 md:col-1 m-0 mb-md-0">
            <div className="d-flex align-items-center">
              <label className="inputLabel"></label>
             <Button
                className="remove-shadow w-full "
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  console.log("clicked");
                  formik.handleSubmit();
                }}
              >
                Show{" "}
              </Button>
            </div>
          </div> */}

          <div className="col-12 md:col-1  m-0 mb-md-0 ">
            <div className="d-flex align-items-center">
              <label className="inputLabel"></label>
              <Button
                className="remove-shadow w-full"
                onClick={() => onCancelYear()}
              >
                Reset
              </Button>
            </div>
          </div>

          <div className="col-12 md:col-7">
            <div className="d-flex align-items-center">
              <span>{`Cumulative Equity Cash Distributions for Previous Years = ${
                cumulativeValue !== undefined &&
                formik.values.Year &&
                !stateName
                  ? currencyFormat(
                      Number.parseFloat(cumulativeValue.replace("$", ""))
                    )
                  : ""
              }`}</span>
            </div>
          </div>
          {/* <div className="d-flex col-2 justify-content-end">
            <Button
              className="btn-nav w-7rem justify-content-center"
              onClick={onSave}
              disabled={
                GetUserList === undefined ||
                GetUserList?.length === 0 ||
                GetUserList === null ||
                userList.length === 0
              }

              // disabled={true}
            >
              Save
            </Button>
          </div> */}
          {/* </div> */}
        </div>
        {/* </div> */}
      </div>
      <DataTableComponent
        valueData={userList || []}
        fieldsElements={dataTableElements}
        scrollable={true}
        isDownload={false}
        openDailog={openDailog}
        setOpenDailog={setOpenDailog}
        // isDownload={true}
        maxHeight={"calc(-210px + 100vh)"}
        resizableColumns
        columnResizeMode="expand"
        // fieldsElements={fieldsElementsWithEditors}
        isPaginationAllowed={true}
        isFullView={true}
        fileName="Monthly Reconciliation"
        className="gridcell"
      />
    </>
  );
}
