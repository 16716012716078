// import {
//   // useIsAuthenticated,
//   AuthenticatedTemplate,
//   UnauthenticatedTemplate,
// } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// import ChatbotComponent from "./components/chatbot/ChatbotComponent";
import Spinner from "./components/loader/Loader";
import OpportunityDetails from "./components/opportunities/OpportunityDetails";
import GuardedRoute from "./components/routes/GaurdedRoute";
// import SlugValidator from "./components/slug-validation/SlugValidator";
import Support from "./components/support/Support";
// import Tabs from "./components/toptabs/tabs";
import Debt from "./pages//features/NavTabPages/Opportunities/Debt";
import Equity from "./pages//features/NavTabPages/Opportunities/Equity";
import Opportunities from "./pages//features/NavTabPages/Opportunities/Oppotunities";
import BalanceSheet from "./pages/balance-sheet";
import ManagementDashboard from "./pages/dashboard/ManagementDashboard";
import ManageFundAdminAccountsAdmin from "./pages/features/Admin/ManageFundAdminAccounts";
import ManageFundUserAccountsAdmin from "./pages/features/Admin/ManageFundUserAccounts";
import ManageInvestorAccountsAdmin from "./pages/features/Admin/ManageInvestorAccounts";
import Investment from "./pages/features/NavTabPages/Investment/Investment";
import AllOpportunities from "./pages/features/NavTabPages/Opportunities/AllOportunities";
import Portfolio from "./pages/features/NavTabPages/Portfolio/Potfolio";
import Transaction from "./pages/features/NavTabPages/Transaction/Transaction";
import AccountStatement from "./pages/features/account-statement/AccountStatement";
import AddFundLevelExpenses from "./pages/features/accounting/Add-Fund-level-Expenses";
import AssetsUnderManagement from "./pages/features/accounting/AssetsUnderManagement";
import DailyAum from "./pages/features/accounting/DailyAum";
import DistributeExpensesInvestors from "./pages/features/accounting/DistributeExpensesInvestors";
import DistributeManagementFees from "./pages/features/accounting/DistributeManagementFees";
import MonthlyReconciliation from "./pages/features/accounting/MonthlyReconciliation";
import ReverseDailyAum from "./pages/features/accounting/ReverseDailyAum";
import DebitCreditInvestorAccounts from "./pages/features/accounting/debit-credit/Debit-CreditInvestorAccounts";
import AccessDenied from "./pages/features/b2c/access-denied/AccessDenied";
import ChangePassword from "./pages/features/b2c/change-password/ChangePassword";
import DefaultLandingPage from "./pages/features/b2c/default-page/DefaultLandingPage";
import ForgotPassword from "./pages/features/b2c/forgot-password/ForgotPassword";
import Login from "./pages/features/b2c/login/Login";
import NewLogin from "./pages/features/b2c/login/NewLogin";
import Logout from "./pages/features/b2c/logout/Logout";
import LogoutWhenSignupRequestCallForAuthenticatedUser from "./pages/features/b2c/logout/LogoutWhenSignupRequestCallForAuthenticatedUser";
import ACHTransactionsReport from "./pages/features/dwolla/achtransactionsreport/achtransactionsreport";
import SetupBusinessInvestor from "./pages/features/dwolla/businessinvestor/setupbusinessinvestor";
import ViewSetupBusinessInvestorEntity from "./pages/features/dwolla/businessinvestor/viewsetupbusinessinvestor";
import ViewSetupBusinessInvestorUnverifiedEntity from "./pages/features/dwolla/bussnessinvestorunverified/ViewSetupBusinessInvestorunverified";
import SetupBusinessInvestorUnverified from "./pages/features/dwolla/bussnessinvestorunverified/setupBusinessInvestorunverified";
import DwollaCustomerDocument from "./pages/features/dwolla/customer-documents";
import DwollaInvestorReports from "./pages/features/dwolla/dwollareports/dwollareports";
import SetupFundEntity from "./pages/features/dwolla/fund/setupfundentity";
import SetupFundEntityList from "./pages/features/dwolla/fund/setupfundlist";
import SetupFundManagerEntity from "./pages/features/dwolla/gp/setupfundmanagerentity";
import ViewSetupFundGPEntity from "./pages/features/dwolla/gp/viewsetupfundgpentity";
import TransferFundsFromFundToGP from "./pages/features/dwolla/gpfundtransfer/transferfundsfromfundtogp";
import SetupInvestorDwollaBankUnverified from "./pages/features/dwolla/individualunverified/setupnvestorlistunverified";
import ViewSetupInvestorBankUnverified from "./pages/features/dwolla/individualunverified/viewsetupInvestorbankunverifed";
import TransferFundsToInvestor from "./pages/features/dwolla/investorfundtransfer/transferfundstoinvestor";
import SetupInvestorDwollaBank from "./pages/features/dwolla/investorsetup/investorsetuplist";
import ViewSetupInvestorBank from "./pages/features/dwolla/investorsetup/viewSetupInvestorbank";
import FileManager from "./pages/features/file-manager/list/FileManagerNew";
import Dashboardnew from "./pages/features/gateway/dashboard";
import Deals from "./pages/features/investments//list/deals/Deals";
import { CreateStep5 } from "./pages/features/investments/create/create-forms/CreateStep5";
import { CreateStep6 } from "./pages/features/investments/create/create-forms/CreateStep6";
import CreateInvestmentDeals from "./pages/features/investments/create/create-new-investments-deals";
import CreateInvestmentsAndDeals from "./pages/features/investments/create/create-new-investments-deals/CreateInvestmentsAndDeals";
import ContinuousDealToInvestment from "./pages/features/investments/deal-to-investment/ContinuousDealToInvestment";
import { DealToInvestment } from "./pages/features/investments/deal-to-investment/DealToInvestment";
import ExecuteEsign from "./pages/features/investments/e-sign/execute-e-sign/ExecuteEsign";

import StepupESign from "./pages/features/investments/e-sign/setup-e-sign/StepupESign";
import InvestmentPageContinuous from "./pages/features/investments/list/InvestmentPageContinuous";
import InvestmentsPage from "./pages/features/investments/list/InvestmentsPage";
// import ViewInvestmentDetails from "./pages/features/investments/update/ViewInvestmentDetails";
import ViewGatherSoftCommit from "./pages/features/investments/view-gather-softcommit/ViewGatherSoftCommit";
import InvestorsList from "./pages/features/investor/InvestorsList";
import ManageInvestorDocuments from "./pages/features/investor/manage-investor-documents/ManageInvestorDocuments";
import ManageCountryMaster from "./pages/features/investor/managecountrymaster/Manage-Country-Master";
import ManageInvestor from "./pages/features/investor/manageinvestor/Manage-Investor";
import SetupInvestorFees from "./pages/features/investor/setupinvestorfees/SetupInvestorFees";
import Viewapproveregistrations from "./pages/features/investor/view-approve-registrations/View-&-approve-registrations";
import ManageFundSupplier from "./pages/features/manage-fund-supplier/ManageFundSupplier";
import ManagerFees from "./pages/features/manager-fees";
import ManageFundSDIRACustodians from "./pages/features/master/ManageFundSD-IRACustodians";
import ManageMenuMaster from "./pages/features/master/ManageMenuMaster";
// import SearchEarningsTransactions from "./pages/features/operation/SearchEarningsTransactions";
import AllocateEarningContinuous from "./pages/features/operation/allocate-earning";
import AllocateSlices from "./pages/features/operation/allocation-slice/allocateslices";
import AllocationSlices from "./pages/features/operation/allocation-slices/add-allocation/AllocationSlices";
// import EditAllocationSlices from "./pages/features/operation/allocation-slices/edit-allocation/EditAllocationSlices";
import EditAllocationStep from "./pages/features/operation/allocation-slices/edit-allocation/EditAllocationStep";
import ReverseAllocationSlice from "./pages/features/operation/allocation-slices/reverse-allocation/ReverseAllocationSlice";
import ManageEarnings from "./pages/features/operation/manage-earning";
import ManageFundDocuments from "./pages/features/operation/manage-fund-document/ManageFundDocuments";
// import ReverseEarningAllocation from "./pages/features/operation/reverse-earning-allocation";
import ReverseEarningAllocationContinuous from "./pages/features/operation/reverse-earning-allocation/ReverseEarningAllocationContinuous";

import ManageRole from "./pages/features/operation/rolemanagement/ManageRole";
import ManageRoleCapability from "./pages/features/operation/rolemanagement/ManageRoleCapability";
import ManageUser from "./pages/features/operation/rolemanagement/ManageUser";
import ManageUserAccess from "./pages/features/operation/rolemanagement/ManageUserAccess";
// import FundEnrollment from "./pages/features/registration-steps/FundEnrollment";
// import InvestorAccount from "./pages/features/registration-steps/InvestorAccount";
import SetupFund from "./pages/features/operation/set-up-fund/SetupFund";
// import SetupFundForm from "./pages/features/operation/set-up-fund/SetupFundForm";
import Enroll from "./pages/features/registration-steps/Enroll";
import ApprovedInvestor from "./pages/features/registration-steps/approved-investor/ApprovedInvestor";
import ConsolidatedPlatformDashboard from "./pages/features/reports/ConsolidatedPlatformDashboard";
// import SetupFund from "./pages/features/reports/Fund-Setup/SetupFund";
import SetupFundReport from "./pages/features/reports/Fund-Setup/SetupFundReport";
import OutstandingBlueskyFilings from "./pages/features/reports/Outstanding-Bluesky-Filings/OutstandingBlueskyFilings";
import SponsorRegulatoryDashboard from "./pages/features/reports/SponsorRegulatoryDashboard";
import AnnualizedReturnsCompleted from "./pages/features/reports/annualized-returns-completed/AnnualizedReturnsCompleted";
import BlueSkyFiling from "./pages/features/reports/blue-sky-filing/BlueSkyFiling";
import ConsolidatedPlatform from "./pages/features/reports/consolidated-platform-report/ConsolidatedPlatform";
import CumulativeEarning from "./pages/features/reports/cumulative-earning/CumulativeEarning";
import EarningsErrataReport from "./pages/features/reports/earnings errata-report/EarningsErrataReport";
import GaapMonthlyReconciliation from "./pages/features/reports/gaap-monthlyreconiliation/GaapMonthlyReconciliation";
import GetCurrentOpportunities from "./pages/features/reports/get-current-opportunities/GetCurrentOpportunities";
import GetInvestorsVerifiedByAccredd from "./pages/features/reports/getInvestors-verified-by-accredd/GetInvestorsVerifiedByAccredd";
import InvestmentAllocationReport from "./pages/features/reports/investment-allocation-report/InvestmentAllocationReport";
import InvestmentReturnsbySupplier from "./pages/features/reports/investment-returns-by-supplier-report/InvestmentReturnsbySupplier";
import InvestorAssets from "./pages/features/reports/investor-assets/InvestorAssets";
import InvestorLoginHistory from "./pages/features/reports/investor-login-history/InvestorLoginHistory";
import InvestorsinFund from "./pages/features/reports/investors-in-Fund/InvestorsinFund";
import LateInvestmentExits from "./pages/features/reports/late-investment-exits/LateInvestmentExits";
import SearchInvestorEmployee from "./pages/features/reports/search-investor-employee/SearchInvestorEmployee";
import SoftCommits from "./pages/features/reports/soft-commits/SoftCommits";
import ManageTransactionReports from "./pages/features/reports/transaction-reports/ManageTransactionReports";
import SendInviteToRegister from "./pages/features/send-invite-to-register/SendInviteToRegister";
import GetBlueSkyFillingDetails from "./pages/features/sponsor/getblue-sky-flling-details/GetBlueSkyFillingDetails";
import ManageHelpVideos from "./pages/features/sponsor/managehelpvideos/ManageHelpVideos";
// eslint-disable-next-line import/order
import ManageSubscription from "./pages/features/operation/rolemanagement/ManageSubscription";
import InterestIncomeAtFundLevel from "./pages/features/taxmanagement/1099/InterestIncomeAtFundLevel";
import InterestIncomeByInvestor from "./pages/features/taxmanagement/1099/InterestIncomeByInvestor";
import InputForm1099 from "./pages/features/taxmanagement/1099/input-form-supplier/InputForm1099";
import AllocationByInvestment from "./pages/features/taxmanagement/Allocation%ByInvestment";
import BadDebtExpenses from "./pages/features/taxmanagement/BadDebtExpenses";
import FundExpensesProRataCalculation from "./pages/features/taxmanagement/FundExpensesPro-RataCalculation";
import Generation from "./pages/features/taxmanagement/Generation";
import InvestorProrataShareInvestment from "./pages/features/taxmanagement/InvestorPro-rataShareInvestment";
import K1ReconciliationReport from "./pages/features/taxmanagement/K-1-Reconciliation-Report";
import K1ReconciliationByInvestment from "./pages/features/taxmanagement/K1ReconciliationByInvestment";
import K1ReconciliationByInvestor from "./pages/features/taxmanagement/K1ReconciliationByInvestor";
import LPLevelBreakdown from "./pages/features/taxmanagement/LPLevelBreakdown";
import OwnershipChangeReport from "./pages/features/taxmanagement/OwnershipChangeReport";
import PartnerInformation from "./pages/features/taxmanagement/Partner-Information";
import PartnerCapitalAccCalculation from "./pages/features/taxmanagement/PartnerCapitalAccCalculation";
import SendAUMUsageUnitsForInvoicing from "./pages/features/taxmanagement/SendAUMUsageUnitsForInvoicing";
import SummaryReportbyPartner from "./pages/features/taxmanagement/SummaryReportbyPartner";
import FundManagementFeesPaid from "./pages/features/taxmanagement/fund-management-fees-paid";
import InvestorShareOfCashDistributions from "./pages/features/taxmanagement/investor-share-of-cash-distributions";
import K1InputSupplier from "./pages/features/taxmanagement/k1-input-supplier/K1InputSupplier";
import UploadInvestorK1s from "./pages/features/taxmanagement/upload-investork1/UploadInvestorK1s";
import AddPoolFunds from "./pages/poolfunds/addpoolfunds";
import EditPoolFunds from "./pages/poolfunds/editpoolfunds";
import ExitUnitPoolFund from "./pages/poolfunds/exitunits";
import ReversePoolFund from "./pages/poolfunds/reverse";
import NewReverseFund from "./pages/poolfunds/reverse/NewReverse";
import ReverseFund from "./pages/poolfunds/reverse/Reverse";
import ProfitLoss from "./pages/profit-loss";
import Quest from "./pages/quest";
import Signup from "./pages/signup/signup";
import DashboardTabView from "./pages/tabView/DashboardTabView";

const Dashboard = lazy(() => import("./pages/features/Dashboard"));
function PublicRoute() {
  return (
    <Routes>
      <Route path="accessDenied" element={<AccessDenied />} />
      <Route path="signup/:slug" element={<Signup />} />
      <Route id="login" path="/:slug" element={<Login />} />
      <Route id="newLogin" path="newLogin/:slug" element={<NewLogin />} />
      <Route
        id="forgotPassword"
        path="forgotPassword/:slug"
        element={<ForgotPassword />}
      />
      <Route id="dashboard" path="/dashboard" element={<Dashboard />} />
      <Route id="quest" path="/capital-quest" element={<Quest />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

function ProtectedRoute() {
  return (
    <Routes>
      <Route
        path="signup/:slug"
        element={<LogoutWhenSignupRequestCallForAuthenticatedUser />}
      />
      <Route
        id="DefaultLandingPage"
        path="login/:slug"
        element={<DefaultLandingPage />}
      />
      <Route id="dashboard" path="/dashboard" element={<Dashboard />} />
      <Route
        id="DashboardTabView"
        path="/ManagementDashboard/Index"
        element={<DashboardTabView />}
      />
      {/* <Route
        id="default"
        path="/"
        element={<Navigate to="/ManagementDashboard/Index" />}
      /> */}
      <Route
        id="default"
        path="/"
        element={<Navigate to="/ManagementDashboard/Index" />}
      />
      {/* <Route path="*" element={<Dashboard />} /> */}
      <Route id="logout" path="/logout" element={<Logout />} />
      <Route
        id="changePassword"
        path="/changePassword"
        element={<ChangePassword />}
      />
      <Route
        id="lateInvestmentExits"
        path="/Report/MissedExitDatesReport"
        element={<LateInvestmentExits />}
      />
      <Route
        id="softCommits"
        path="/Report/SoftCommitsByFund"
        element={<SoftCommits />}
      />
      <Route
        id="consolidatedPlatformreport"
        path="/Report/ConsolidatedPlatformReport"
        element={<ConsolidatedPlatform />}
      />
      <Route
        id="SponsorRegulatoryDashboard"
        path="/SponsorRegulatoryDashboard"
        element={<SponsorRegulatoryDashboard />}
      />
      <Route
        id="investorsinFund"
        path="/Master/InvestorList"
        element={<InvestorsinFund />}
      />
      <Route
        id="blueSkyFilingReport"
        path="/Report/BlueSkyFilingReport"
        element={<BlueSkyFiling />}
      />
      <Route
        id="cumulativeEarningReport"
        path="/Report/CumulativeEarningReport"
        element={<CumulativeEarning />}
      />{" "}
      {/* <Route
        id="setupFundReport"
        path="/Report/SetupFundReport"
        element={<SetupFund />}
      /> */}
      <Route
        id="setupFundReport"
        path="/Report/SetupFundReport"
        element={<SetupFundReport />}
      />
      <Route
        id="gaapMonthlyReconciliation"
        path="GaapMonthlyReconciliation"
        element={<GaapMonthlyReconciliation />}
      />
      <Route
        id="OutstandingBlueskyFilings"
        path="/Report/OutstandingBlueskyFilings"
        element={<OutstandingBlueskyFilings />}
      />
      <Route
        id="earningserratareport"
        path="/ReverseEarningDistribution/ReturnsDistributionErrata"
        element={<EarningsErrataReport />}
      />
      <Route
        id="investorAssets"
        path="/ManagementDashboard/InvestorAccOverview"
        element={<InvestorAssets />}
      />
      <Route
        id="InvestorLoginHistory"
        path="/Report/InvestorLoginHistory"
        element={<InvestorLoginHistory />}
      />
      <Route
        id="GetCurrentOpportunities"
        path="Report/CurrentOpportunitiesAcrossFunds"
        element={<GetCurrentOpportunities />}
      />
      <Route
        id="AnnualizedReturnsCompleted"
        path="/CompletedInvestment/AnnualizedReturns"
        element={<AnnualizedReturnsCompleted />}
      />
      <Route
        id="SearchInvestorEmployee"
        path="/SearchInvestorEmployee"
        element={<SearchInvestorEmployee />}
      />
      <Route
        id="InvestorsVerifiedByAccredd"
        path="Report/InvestorsVerifiedByAccreddReport"
        element={<GetInvestorsVerifiedByAccredd />}
      />
      <Route
        id="InvestmentAllocationReport"
        path="/Report/InvestmentAllocationReport"
        element={<InvestmentAllocationReport />}
      />
      <Route
        id="investmentreturnsbysupplier"
        path="InvestmentReturnDistiCalcLog/InvestmentReturnBySupplier"
        element={<InvestmentReturnsbySupplier />}
      />
      <Route
        id="accountStatement"
        path="accountStatement"
        element={<AccountStatement />}
      />
      <Route id="Portfolio" path="Portfolio" element={<Portfolio />}>
        <Route path="/Portfolio" element={<Navigate to="dashboard" />} />
        <Route id="dashboardnew" path="dashboard" element={<Dashboardnew />} />
        <Route id="Investment" path="Investment" element={<Investment />} />
        <Route id="Transaction" path="Transaction" element={<Transaction />} />
      </Route>
      {/* Opportunities */}
      <Route
        path="/Opportunities"
        element={<Navigate to="AllOpportunities" />}
      />
      {/* <Route
        id="AllOpportunities"
        path="Opportunities/AllOpportunities"
        element={<AllOpportunities />}
      />
      <Route id="Equity" path="Opportunities/Equity" element={<Equity />} />
      <Route id="Debt" path="Opportunities/Debt" element={<Debt />} /> */}
      <Route
        id="Opportunities"
        path="Opportunities"
        element={<Opportunities />}
      >
        <Route
          path="/Opportunities"
          element={<Navigate to="AllOpportunities" />}
        />
        <Route
          id="AllOpportunities"
          path="AllOpportunities"
          element={<AllOpportunities />}
        />

        <Route id="Equity" path="Equity" element={<Equity />} />
        <Route id="Debt" path="Debt" element={<Debt />} />
        {/* <Route
          id="OpportunityDetails"
          path="OpportunityDetails/:id"
          element={<OpportunityDetails />}
        /> */}
      </Route>
      <Route element={<GuardedRoute />}>
        <Route
          id="OpportunityDetails"
          path="OpportunityDetails/:id"
          element={<OpportunityDetails />}
        />
      </Route>
      <Route
        id="NewInvestment"
        path="/ProductDetails/NewInvestment"
        element={<CreateInvestmentsAndDeals />}
      />
      <Route
        id="newinvestemtdeal"
        path="/Continuous/NewInvestment"
        element={<CreateInvestmentDeals />}
      />
      <Route
        id="SendInviteToRegister"
        path="/SendInviteToRegister"
        element={<SendInviteToRegister />}
      />
      <Route
        id="Viewapproveregistrations"
        path="/Viewapproveregistrations"
        element={<Viewapproveregistrations />}
      />
      <Route
        id="InvestorsList"
        path="/investorsList"
        element={<InvestorsList />}
      />
      <Route
        id="ManageInvestor"
        path="/ManageInvestor"
        element={<ManageInvestor />}
      />
      <Route element={<GuardedRoute />}>
        <Route
          id="Enroll"
          path="Enroll/:investorId/:legalEntityId/:investingAccountId"
          element={<Enroll />}
        />

        <Route
          id="ApprovedInvestor"
          path="ApprovedInvestor/:investorId/:legalEntityId/:investingAccountId"
          element={<ApprovedInvestor />}
        />
      </Route>
      {/* <Route id="Tabs" path="Tabs" element={<Tabs />}>
        <Route path="/Tabs" element={<Navigate to="InvestorProfile" />} />
        <Route
          id="InvestorProfile"
          path="InvestorProfile/:investorID/:legalEntityID/:investingAccountID"
          element={<InvestorProfile />}
        />
        <Route
          id="InvestorAccount"
          path="InvestorAccount"
          element={<InvestorAccount />}
        />
        <Route
          id="FundEnrollment"
          path="FundEnrollment"
          element={<FundEnrollment />}
        />
      </Route> */}
      <Route
        id="ManageFundSupplier"
        path="/ManageFundSupplier"
        element={<ManageFundSupplier />}
      />
      <Route
        id="ManageCountryMaster"
        path="/ManageCountryMaster"
        element={<ManageCountryMaster />}
      />
      <Route
        id="ManageMenuMaster"
        path="/ManageMenuMaster"
        element={<ManageMenuMaster />}
      />
      <Route
        id="GetBlueSkyFillingDetails"
        path="/GetBlueSkyFillingDetails"
        element={<GetBlueSkyFillingDetails />}
      />
      <Route
        id="ManageHelpVideos"
        path="/ManageHelpVideos"
        element={<ManageHelpVideos />}
      />
      <Route
        id="SetupInvestorFees"
        path="/SetupInvestorFees"
        element={<SetupInvestorFees />}
      />
      <Route
        id="PartnerInformation"
        path="/PartnerInformation"
        element={<PartnerInformation />}
      />
      <Route
        id="ManageRole"
        path="RoleManagement/ManageRole"
        element={<ManageRole />}
      />
      <Route
        id="DebitCreditInvestorAccounts"
        path="/DebitCreditInvestorAccounts"
        element={<DebitCreditInvestorAccounts />}
      />
      <Route id="Generation" path="/Generation" element={<Generation />} />
      <Route
        id="ManageUser"
        path="RoleManagement/ManageUser"
        element={<ManageUser />}
      />
      <Route
        id="ManageRoleCapability"
        path="RoleManagement/ManageRoleCapability"
        element={<ManageRoleCapability />}
      />
      <Route
        id="ManageUserAccess"
        path="RoleManagement/ManageUserAccess"
        element={<ManageUserAccess />}
      />
      <Route
        id="ManageSubscription"
        path="RoleManagement/ManageSubscription"
        element={<ManageSubscription />}
      />
      <Route
        id="AllocationByInvestment"
        path="/AllocationByInvestment"
        element={<AllocationByInvestment />}
      />
      <Route
        id="AddFundLevelExpenses"
        path="/AddFundLevelExpenses"
        element={<AddFundLevelExpenses />}
      />
      <Route
        id="InvestorProrataShareInvestment"
        path="/InvestorProrataShareInvestment"
        element={<InvestorProrataShareInvestment />}
      />
      <Route
        id="InvestorShareOfCashDistributions"
        path="/InvestorShareOfCashDistributions"
        element={<InvestorShareOfCashDistributions />}
      />
      <Route
        id="FundManagementFeesPaid"
        path="/FundManagementFeesPaid"
        element={<FundManagementFeesPaid />}
      />
      <Route
        id="OwnershipChangeReport"
        path="/OwnershipChangeReport"
        element={<OwnershipChangeReport />}
      />
      <Route
        id="PartnerCapitalAccCalculation"
        path="/PartnerCapitalAccCalculation"
        element={<PartnerCapitalAccCalculation />}
      />
      <Route
        id="K1ReconciliationReport"
        path="/K1ReconciliationReport"
        element={<K1ReconciliationReport />}
      />
      <Route
        id="FundExpensesProRataCalculation"
        path="/FundExpensesProRataCalculation"
        element={<FundExpensesProRataCalculation />}
      />
      <Route
        id="BadDebtExpenses"
        path="/BadDebtExpenses"
        element={<BadDebtExpenses />}
      />
      <Route
        id="LPLevelBreakdown"
        path="/LPLevelBreakdown"
        element={<LPLevelBreakdown />}
      />
      <Route
        id="K1ReconciliationByInvestment"
        path="/K1ReconciliationByInvestment"
        element={<K1ReconciliationByInvestment />}
      />
      <Route
        id="K1ReconciliationByInvestor"
        path="/K1ReconciliationByInvestor"
        element={<K1ReconciliationByInvestor />}
      />
      <Route
        id="SummaryReportbyPartner"
        path="/SummaryReportbyPartner"
        element={<SummaryReportbyPartner />}
      />
      <Route
        id="InterestIncomeAtFundLevel"
        path="/InterestIncomeAtFundLevel"
        element={<InterestIncomeAtFundLevel />}
      />
      <Route
        id="InterestIncomeByInvestor"
        path="/InterestIncomeByInvestor"
        element={<InterestIncomeByInvestor />}
      />
      <Route id="DailyAum" path="/DailyAum" element={<DailyAum />} />
      <Route
        id="SearchEarningsTransactions"
        path="/SearchEarningsTransactions"
        element={<ManageEarnings />}
      />
      <Route
        id="ReverseDailyAum"
        path="/ReverseDailyAum"
        element={<ReverseDailyAum />}
      />
      <Route
        id="ReverseDailyAum"
        path="/AssetsUnderManagement"
        element={<AssetsUnderManagement />}
      />
      <Route
        id="DistributeManagementFees"
        path="/DistributeManagementFees"
        element={<DistributeManagementFees />}
      />
      <Route
        id="DistributeExpensesInvestors"
        path="/DistributeExpensesInvestors"
        element={<DistributeExpensesInvestors />}
      />
      <Route
        id="ManageFundSDIRACustodians"
        path="/ManageFundSDIRACustodians"
        element={<ManageFundSDIRACustodians />}
      />
      <Route
        id="ManagementDashboard"
        path="/ManagementDashboard"
        element={<ManagementDashboard />}
      />
      <Route
        id="ConsolidatedPlatformDashboard"
        path="/ConsolidatedPlatformDashboard"
        element={<ConsolidatedPlatformDashboard />}
      />
      <Route
        id="MonthlyReconciliation"
        path="/MonthlyReconciliation"
        element={<MonthlyReconciliation />}
      />
      <Route
        id="ManageInvestorAccountsAdmin"
        path="/ManageInvestorAccountsAdmin"
        element={<ManageInvestorAccountsAdmin />}
      />
      <Route
        id="ManageFundUserAccountsAdmin"
        path="/ManageFundUserAccountsAdmin"
        element={<ManageFundUserAccountsAdmin />}
      />
      <Route
        id="ManageFundAdminAccountsAdmin"
        path="/ManageFundAdminAccountsAdmin"
        element={<ManageFundAdminAccountsAdmin />}
      />
      <Route
        id="createInvestmentsAndDealsStep5"
        path="/createInvestmentsAndDealsStep5"
        element={
          <CreateStep5
            handlePrevious={undefined}
            handleNext={undefined}
            offering={undefined}
          />
        }
      />
      <Route
        id="createInvestmentsAndDealsStep6"
        path="/createInvestmentsAndDealsStep6"
        element={
          <CreateStep6
            handlePrevious={undefined}
            handleNext={undefined}
            offering={undefined}
          />
        }
      />
      <Route
        id="Investment"
        path="/ManageOffering"
        element={<InvestmentsPage />}
      />
      <Route element={<GuardedRoute />}>
        <Route id="FileManager" path="/Filemanager" element={<FileManager />} />
        <Route
          id="Investment"
          path="/ManageOffering/:id"
          element={<InvestmentsPage />}
        />
      </Route>
      <Route id="Deals" path="/deals" element={<Deals />} />
      <Route
        id="NewDeals"
        path="/ProductDetails/SoftCommit"
        element={<CreateInvestmentsAndDeals />}
      />
      {/* <Route
        id="viewDetails"
        path="/viewDetails/:id"
        element={<ViewInvestmentDetails />}
      /> */}
      <Route
        id="convertDealToInvestment"
        path="/ProductDetails/ViewSoftCommit"
        element={<DealToInvestment />}
      />
      <Route
        id="SetupFundEntity"
        path="/SetupFundEntity/:id"
        element={<SetupFundEntity />}
      />
      <Route
        id="SetupFundEntityList"
        path="/SetupFundEntityList"
        element={<SetupFundEntityList />}
      />
      <Route
        id="SetupFundGPEntity"
        path="/SetupFundGPEntity"
        element={<SetupFundManagerEntity />}
      />
      <Route
        id="viewSetupFundGPEntity"
        path="/viewSetupFundGPEntity/:id"
        element={<ViewSetupFundGPEntity />}
      />
      <Route
        id="SetupBusinessInvestor"
        path="/SetupBusinessInvestor"
        element={<SetupBusinessInvestor />}
      />
      <Route
        id="SetupBusinessInvestorUnverified"
        path="/SetupBusinessInvestorUnverified"
        element={<SetupBusinessInvestorUnverified />}
      />
      <Route
        id="SetupInvestorDwollaBank"
        path="/SetupInvestorDwollaBank"
        element={<SetupInvestorDwollaBank />}
      />
      <Route
        id="SetupInvestorDwollaBankUnverified"
        path="/SetupInvestorDwollaBankUnverified"
        element={<SetupInvestorDwollaBankUnverified />}
      />
      <Route
        id="ViewSetupBusinessInvestorUnverifiedEntity"
        path="/ViewSetupBusinessInvestorUnverifiedEntity/:id/:pid"
        element={<ViewSetupBusinessInvestorUnverifiedEntity />}
      />
      <Route
        id="viewSetupBusinessInvestorEntity"
        path="/viewSetupBusinessInvestorEntity/:id/:pid"
        element={<ViewSetupBusinessInvestorEntity />}
      />
      <Route
        id="ViewSetupInvestorBankUnverified"
        path="/ViewSetupInvestorBankUnverified/:id/:pid"
        element={<ViewSetupInvestorBankUnverified />}
      />
      <Route
        id="ViewSetupInvestorBank"
        path="/viewSetupInvestorBank/:id/:pid"
        element={<ViewSetupInvestorBank />}
      />
      <Route
        id="TransferFundsFromFundToGP"
        path="/TransferFundsFromFundToGP"
        element={<TransferFundsFromFundToGP />}
      />
      <Route
        id="TransferFundsToInvestort"
        path="/TransferFundsToInvestor"
        element={<TransferFundsToInvestor />}
      />
      <Route
        id="ACHTransactionsReport"
        path="/ACHTransactionsReport"
        element={<ACHTransactionsReport />}
      />
      <Route
        id="SetupESign"
        path="/ProductDetails/ManageInvestmentAgreement"
        element={<StepupESign />}
      />
      <Route
        id="ExecuteEsign"
        path="/ProductDetails/SendAgreementsForSignature"
        element={<ExecuteEsign />}
      />
      <Route id="support" path="/Support" element={<Support />} />
      <Route
        id="viewGatherSoftCommit"
        path="/ProductDetails/GenerateSoftCommit"
        element={<ViewGatherSoftCommit />}
      />
      <Route
        id="1099InputForm"
        path="/TaxManagement/FormFromSuppliers"
        element={<InputForm1099 />}
      />
      <Route
        id="UploadInvestorK1s"
        path="/UploadDocument/UploadK1Form"
        element={<UploadInvestorK1s />}
      />
      <Route
        id="manageInvestorDocuments"
        path="/Manageinvestordocument"
        element={<ManageInvestorDocuments />}
      />
      <Route
        id="manageFundDocuments"
        path="/UploadDocument/Index"
        element={<ManageFundDocuments />}
      />
      <Route
        id="SendAUMUsageUnitsForInvoicing"
        path="/SendAUMUsageUnitsForInvoicing"
        element={<SendAUMUsageUnitsForInvoicing />}
      />
      <Route
        id="K1InputSupplier"
        path="/K1Details/InputK1FormsfromSuppliers"
        element={<K1InputSupplier />}
      />
      <Route
        id="DwollaInvestorReports"
        path="DwollaInvestorReports"
        element={<DwollaInvestorReports />}
      />
      <Route
        id="SetupFundForm"
        path="/Master/SetupFundMaster"
        element={<SetupFund />}
      />
      <Route
        id="AllocateEarnings"
        path="/ManageInvestmentReturn/Index"
        element={<ManageEarnings />}
      />
      <Route
        id="AllocateEarningsContinuous"
        path="/ManageInvestmentReturn/Continuous"
        element={<AllocateEarningContinuous />}
      />
      <Route
        id="AllocateSlices"
        path="/ManagePartnerInterest/AllocateSponsorsInvSlices"
        element={<AllocateSlices />}
      />
      <Route
        id="ReverseAllocation"
        path="/ReverseEarningDistribution/Index"
        element={<ManageEarnings />}
      />
      <Route
        id="ReverseAllocation"
        path="/ReverseEarningDistribution/Continuous"
        element={<ReverseEarningAllocationContinuous />}
      />
      <Route
        id="ManageTransactionReports"
        path="/ManageTransactionReports"
        element={<ManageTransactionReports />}
      />
      {/* <Route id="default" path="/:slug" element={<SlugValidator />} /> */}
      <Route
        id="default"
        path="/:slug"
        element={<Navigate to="/ManagementDashboard/Index" />}
      />
      <Route
        id="AllocationSliceNew"
        path="/ManagePartnerInterest/AllocationSlices"
        element={<AllocationSlices />}
      />
      {/* <Route
        id="EditAllocationSlice"
        path="/EditAllocationSlices"
        element={<EditAllocationSlices />}
      /> */}
      <Route
        id="EditAllocationSlice"
        path="/ManagePartnerInterest/EditAllocationSlices"
        element={<EditAllocationStep />}
      />
      <Route
        id="ReverseAllocationSlice"
        path="/ManagePartnerInterest/ReverseAllocationSlices"
        element={<ReverseAllocationSlice />}
      />
      <Route
        id="DwollaCustomerDocument"
        path="/DwollaCustomerDocument"
        element={<DwollaCustomerDocument />}
      />
      <Route
        id="AddPoolFunds"
        path="/AddPoolFunds"
        element={<AddPoolFunds />}
      />
      <Route
        id="EditPoolFunds"
        path="/EditPoolFunds"
        element={<EditPoolFunds />}
      />
      <Route id="managerFees" path="/ManagerFees" element={<ManagerFees />} />
      <Route
        id="ExitUnit"
        path="/PoolFunds/ExitUnit"
        element={<ExitUnitPoolFund />}
      />
      <Route
        id="ReverseStake"
        path="/PoolFunds/ReverseStake"
        element={<NewReverseFund />}
      />
      <Route
        id="ContinuousInvestmentOffering"
        path="/Continuous/ManageOffering"
        element={<InvestmentPageContinuous />}
      />
      <Route
        id="ContinuousInvestmentView"
        path="/Continuous/ManageOffering/:id"
        element={<InvestmentPageContinuous />}
      />
      <Route
        id="ContinuousReverse"
        path="/Continuous/Reverse"
        element={<ReversePoolFund />}
      />
      <Route
        id="convertContinuousDealToInvestment"
        path="/ContinuousDealToInvestment"
        element={<ContinuousDealToInvestment />}
      />
      <Route
        id="ContinuousReverse"
        path="/Continuous/OldReverse"
        element={<ReverseFund />}
      />
      <Route
        id="balancesheet"
        path="/BalanceSheet"
        element={<BalanceSheet />}
      />
      <Route id="profitloss" path="/ProfitLoss" element={<ProfitLoss />} />
    </Routes>
  );
}

function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <AuthenticatedTemplate>
        <ProtectedRoute />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PublicRoute />
      </UnauthenticatedTemplate>
    </Suspense>
  );
}
export default AppRoutes;
