import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill, RiExternalLinkFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
import {
  addThunkInvestorDocument,
  deleteThunkInvestorDocument,
  getThunkInvestorDocumentList,
} from "../../../../store/features/investor/manage-investor-document/investor-document-list/slice";
import { getThunkInvestorDocumentTypeDropdown } from "../../../../store/features/investor/manage-investor-document/investor-document-type/slice";
import { getThunkInvestorList } from "../../../../store/features/view-gather-softcommit/investor-list-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import dateFormatter, {
  // formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { isValidUrlRegexNew } from "../../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function ManageInvestorDocuments() {
  const dispatch = useAppDispatch();

  // const [loader, setLoader] = useState(false);

  const fileRef = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const [add, setAdd] = useState(true);
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [DocName, setDocName] = useState("");
  const [investorDocumentId, setInvestorDocumentId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listDocument, setListDocument] = useState<any>();
  const [documentLinkUpdate, setDocumentLinkUpdate] = useState("");
  const [contentType, setContentType] = useState("");
  const [sasKey, setsasKey] = useState("");
  const [stateName, setName] = useState<any>(false);
  const [showButton, setShowButton] = useState<any>(false);
  const [openDialogGrid, setOpenDialogGrid] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [addDoc, setAddDoc] = useState(true);
  const [investorAccountId, setInvestorAccountId] = useState(0);
  // const [partnerIdUpdate, setPartnerIdUpdate] = useState(0);
  // const regexTest = /(.*?).(pdf|png|jpeg|txt|jpg|mp4)$/;
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);
  const partnerList = useAppSelector((state) => state.investorList.data);
  const listInvestorDocuments = useAppSelector(
    (state) => state.investorDocumentList.data
  );
  console.log("listInvestorDocuments", listInvestorDocuments);
  const documentTypeDropdown = useAppSelector(
    (state) => state.investorDocumentTypeDropdown.data
  );
  console.log(showTab, showButton);

  const formatDate = (date: any) => {
    // const dateFormat = formatDateMMDDYYY(date);
    const dateFormat = dateFormatter(date);
    return dateFormat;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formik = useFormik({
    initialValues: {
      // partnerId: 0,
      documentTypeId: 0,
      documentDescription: "",
      documentUrl: "",
      mediaType: "File",
      files: undefined,
    },
    validate: (data) => {
      const errors: any = {};
      // if (!data.partnerId) {
      //   errors.partnerId = " Select investor account";
      // }
      if (!data.documentTypeId) {
        errors.documentTypeId = "Select document type";
      }
      if (formik.values.mediaType === "URL" && !data.documentUrl) {
        errors.documentUrl = "Document url is required";
      }
      if (data.documentUrl && !isValidUrlRegexNew(data.documentUrl)) {
        errors.documentUrl = "Enter a valid url";
      }

      if (!data.files) {
        errors.files = "Select file to upload";
      }
      return errors;
    },
    onSubmit: (data) => {
      //  console.log(data);
    },
  });
  const generateSASKey = (legalEntityId: any) => {
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const uploadDoc = async (fileuploaded?: any) => {
    // if (
    //   (formik.values.files === undefined || formik.values.files === "") &&
    //   docLink === ""
    // ) {
    //   errorToastMessage(toast, "Please Select File or Add a Url");
    //   setLoading(false);
    //   return;
    // }

    if (
      formik.values.mediaType !== "" &&
      investorAccountId !== 0 &&
      formik.values.documentTypeId !== 0
    ) {
      setLoading(true);

      const data = new FormData();
      if (formik.values.mediaType === "File") {
        // data.append(
        //   "files",
        //   formik.values.files === undefined ? " " : formik.values.files
        // );
        data.append("files", fileuploaded === undefined ? "" : fileuploaded);
        data.append(
          "documentLink",
          fileuploaded === undefined ? documentLinkUpdate : ""
        );
        data.append("docType", contentType);
      }
      if (formik.values.mediaType === "URL") {
        data.append("documentLink", formik.values.documentUrl);
      }
      data.append("docTypeId", formik.values.documentTypeId.toString());
      data.append("doNotCompress", "false");
      data.append("id", investorDocumentId.toString());
      data.append("partnerId", investorAccountId.toString());
      data.append("documentDesc", formik.values.documentDescription);
      data.append("docExtension", "");
      data.append("mediaType", formik.values.mediaType);
      data.append("documentName", DocName);
      data.append("legalEntityID", fundDropdown.fundid.toString());
      try {
        await dispatch(addThunkInvestorDocument(data)).then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
            setLoading(false);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.success === false) {
              errorToastMessage(toast, "File not Uploaded");
              setLoading(false);
            } else {
              successToastMessage(toast, "File Uploaded");
              setLoading(true);
              // setListDocument([]);
              setInvestorDocumentId(0);
              setAdd(true);
              setEdit(false);
              setShowTab(true);
              setDocLink("");
              console.log("setDocName 1", DocName);
              setDocName("");
              setContentType("");
              setDocumentLinkUpdate("");
              setShowButton(false);
              setOpenAddDialog(false);
              // setLoading(true);
              // const req = {
              //   LegalEntityId: fundDropdown.fundid,
              //   LPPartnerId: formik.values.partnerId,
              // };
              // const investorId = formik.values.partnerId;
              // dispatch(getThunkInvestorDocumentList(req)).finally(() => {
              setLoading(false);
              setName(false);
              formik.resetForm();
              // formik.setFieldValue("partnerId", investorId);
              // });
            }
          }
        });

        // setPartnerIdUpdate(formik.values.partnerId);
        fileRef?.current.clear();
      } catch (error) {
        // fileRef?.current.clear();
        formik.resetForm();
        console.log("Opps something went wrong", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const uploadFile = async (e: any) => {
    setDocumentLinkUpdate("");
    const file = e.files[0];
    const regex = /(.*?).(docx|doc|pdf|xls|png|jpeg|xlsx|txt|jpg|.mp4|.m4v)$/;

    if (!regex.test(file.name.toLowerCase())) {
      errorToastMessage(
        toast,
        "Upload failed. Please Use Images,Excel,Word,Power points, Plain Text Documents ,Video format only "
      );
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 100) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 100mb"
      );
      fileRef?.current.clear();
      return;
    }
    console.log("setDocName 2", DocName);

    setDocName(file.name);
    // infoToastMessage(toast, "Please save the selected file");
    formik.setFieldValue("files", file);
    setsasKey("");
    console.log("url", URL.createObjectURL(file));
    setDocLink(URL.createObjectURL(file));
    uploadDoc(file);
    // fileRef?.current.clear();
  };

  // const onAddClick: any = () => {
  //   formik.resetForm();
  //   setInvestorDocumentId(0);
  //   setAdd(true);
  //   setEdit(false);
  //   setShowTab(true);
  // };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "mediaType") {
      setDocLink("");
      console.log("setDocName 3", DocName);
      setDocName("");
      if (fileRef.current !== null) {
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
      }
    }

    if (name === "partnerId") {
      setListDocument([]);
      setDocLink("");
      console.log("setDocName 4", DocName);
      setDocName("");
      setContentType("");
      setDocumentLinkUpdate("");
      setInvestorDocumentId(0);
      setAdd(true);
      setEdit(false);
      setShowTab(true);
      setShowButton(false);
      formik.setFieldValue("files", "");
      formik.setFieldValue("documentDescription", "");
      formik.setFieldValue("documentUrl", "");
      formik.setFieldValue("documentTypeId", 0);
      formik.setFieldValue("mediaType", "File");
    }

    formik.setFieldValue(name, value);
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = async (id: any) => {
      setOpenAddDialog(true);
      console.log("setDocName 5", DocName);
      setDocName("");
      setShowButton(true);
      formik.setFieldValue("partnerId", investorAccountId);
      formik.setFieldValue("documentTypeId", rowData.documentTypeId.toString());

      formik.setFieldValue(
        "documentDescription",
        rowData.documentDesc !== null ? rowData.documentDesc : ""
      );
      if (rowData.contentType === null) {
        formik.setFieldValue("documentUrl", rowData.documentLink);
        formik.setFieldValue("mediaType", "URL");
      } else {
        setDocumentLinkUpdate(rowData.documentLink);
        formik.setFieldValue("mediaType", "File");
        formik.setFieldValue("files", rowData.documentLink);
      }

      setContentType(rowData.contentType);

      // dispatch(getThunkInvestorK1ViewDocument(rowData.id)).then((response) => {
      //   if (response.payload._reposnse.success === true) {
      //     setDocLink(response.payload._reposnse.data.document);
      //   }
      // });
      console.log("Doc Name", rowData, rowData.documentName);

      let key = "";
      setLoading(true);
      key = await generateSASKey(fundDropdown.fundid.toString());
      setsasKey(key);
      setDocLink(rowData.documentLink);
      setInvestorDocumentId(rowData.id);
      console.log(
        "setDocName 6",
        DocName,
        "rowData.documentName",
        rowData.documentName,
        rowData.documentName === "null"
      );
      setDocName(rowData.documentName ? rowData.documentName : "");
      setAdd(false);
      setEdit(true);
      setShowTab(false);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog widthdeletebtn align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };
  console.log("setDocName 100", DocName);

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = (id: any) => {
      setInvestorDocumentId(rowData.id);
      formik.setFieldValue("partnerId", investorAccountId);
      formik.setFieldValue("documentTypeId", 0);
      formik.setFieldValue("documentDescription", "");
      formik.setFieldValue("documentUrl", "");
      formik.setFieldValue("mediaType", "File");
      formik.setFieldValue("files", "");

      setDocumentLinkUpdate("");
      setDocLink("");
      console.log("setDocName 7", DocName);
      setDocName("");
      setAdd(true);
      setEdit(false);
      setOpenDialog(true);
      setShowTab(true);
      setShowButton(false);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog widthdeletebtn align-items-center justify-content-center"
            onClick={() => handleDelete(rowData.Id)}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };
  const ViewTemplateButton = (rowData: any) => {
    console.log("ViewTemplate function");
    const viewUploadedFile = (key: any) => {
      setDocLink(rowData.documentLink);
      setVisibleViewUpload(true);
    };

    const openDoc = async (e: any) => {
      e.preventDefault();
      let key = "";
      setLoading(true);
      key = await generateSASKey(fundDropdown.fundid.toString());
      setsasKey(key);
      viewUploadedFile(key);
    };

    let item: any;
    // eslint-disable-next-line unicorn/prefer-ternary
    if (!rowData.documentLink) {
      item = (
        <>
          <Tooltip target=".custom-target-icon" />
          <span
            className="custom-target-icon"
            data-pr-tooltip="No document found!!"
            data-pr-position="right"
            style={{ cursor: "pointer" }}
          >
            <i className="pi pi-eye iconButton"></i>
          </span>
        </>
      );
    } else {
      item = (
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-text iconButton"
          onClick={(e) => openDoc(e)}
        />
      );
    }
    return (
      <React.Fragment>
        {rowData.contentType !== null ? (
          item
        ) : rowData.documentLink === null || rowData.documentLink === "" ? (
          <>
            {" "}
            <Tooltip target=".custom-target-icon" />
            <span
              className="custom-target-icon icons-Link"
              data-pr-tooltip="No link found!!"
              data-pr-position="right"
              style={{ cursor: "pointer" }}
            >
              <div>
                <RiExternalLinkFill />
              </div>
            </span>
          </>
        ) : (
          <NavLink
            to={
              rowData.documentLink === null || rowData.documentLink === ""
                ? ""
                : rowData?.documentLink.startsWith("http")
                ? rowData.documentLink
                : `http://${rowData.documentLink}`
            }
            target={rowData.documentLink === null ? undefined : "_blank"}
            className="icons-Link"
          >
            <div>
              <RiExternalLinkFill />
            </div>
          </NavLink>
        )}
      </React.Fragment>
    );
  };

  const DocumentName = (rowData: any) => {
    console.log(
      "test11",
      rowData,
      rowData.documentName === "" || rowData.documentName === null
    );

    return (rowData.documentName === "" || rowData.documentName === null) &&
      rowData.contentType === null ? (
      <div className="left-align" title={rowData?.documentLink}>
        {rowData?.documentLink}
      </div>
    ) : (
      <div className="left-align" title={rowData?.documentName}>
        {rowData?.documentName}
      </div>
    );
  };
  const dataTableElements = [
    {
      // field: "supplierName",
      header: "View ",
      body: ViewTemplateButton,
      style: { minWidth: "60px" },
    },
    {
      field: "documentType",
      header: "Type",
      style: { minWidth: "200px" },
    },
    {
      field: "documentName",
      header: "Name",
      body: DocumentName,
      style: { minWidth: "180px" },
    },
    {
      field: "documentDesc",
      header: "Description",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.documentDesc}>
          {rowData?.documentDesc}
        </div>
      ),
    },
    {
      field: "uploadedDate",
      header: "Uploaded On",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.uploadedDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "180px" },
    },
    {
      field: "year",
      header: "Year",
      style: { minWidth: "150px" },
    },
    {
      // field: "edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { minWidth: "140px" },
    },
    {
      // field: "delete",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
      style: { minWidth: "140px" },
    },
  ];

  const onDelete = () => {
    setLoading(true);
    const deleteParms = {
      docId: investorDocumentId,
      partnerId: investorAccountId,
    };
    dispatch(deleteThunkInvestorDocument(deleteParms))
      .then((response) => {
        // call the list
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          if (response.payload._response.success === true) {
            successToastMessage(toast, "Data is deleted successfully");
            setInvestorDocumentId(0);
            const req = {
              LegalEntityId: fundDropdown.fundid,
              LPPartnerId: investorAccountId,
            };
            dispatch(getThunkInvestorDocumentList(req)).finally(() => {
              setLoading(false);
              setName(false);
            });
          } else {
            errorToastMessage(toast, "Data is not deleted");
            setLoading(false);
          }
          // setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    setOpenDialog(false);
  };
  const onCancel = () => {
    setInvestorDocumentId(0);
    setOpenDialog(false);
  };

  useEffect(() => {
    setAdd(true);
    setShowTab(true);
    setEdit(false);
    formik.resetForm();
    setShowButton(false);
    setDocLink("");
    setContentType("");
    setDocumentLinkUpdate("");
    console.log("setDocName 8", DocName);
    setDocName("");
    setInvestorDocumentId(0);
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityID: fundDropdown.fundid,
        supplierID: 0,
      };

      dispatch(getThunkDropdown(parameters));
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      dispatch(getThunkInvestorDocumentTypeDropdown(parameter));
      dispatch(getThunkInvestorList(parameter)).finally(() => {
        setLoading(false);
        setName(true);
      });
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (investorAccountId !== 0) {
      setLoading(true);
      const req = {
        LegalEntityId: fundDropdown.fundid,
        LPPartnerId: investorAccountId,
      };
      dispatch(getThunkInvestorDocumentList(req)).finally(() => {
        setLoading(false);
        setName(false);
      });
    }
  }, [investorAccountId]);
  useEffect(() => {
    setListDocument(
      getListDateConversion(listInvestorDocuments, ["uploadedDate"])
    );
  }, [listInvestorDocuments]);
  useEffect(() => {
    if (formik.values.mediaType === "URL") {
      setShowButton(true);
    } else if (edit === false) {
      setShowButton(false);
    }
  }, [formik.values.mediaType]);

  const openDoc = async (e: any) => {
    e.preventDefault();
    // let key = "";
    // setLoading(true);
    // key = await generateSASKey(fundDropdown.fundid.toString());
    // setsasKey(key);
    setVisibleViewUpload(true);
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  const onHideAddDocument = () => {
    setOpenAddDialog(false);
    formik.resetForm();
    formik.setFieldValue("documentTypeId", 0);
    formik.setFieldValue("documentDescription", "");
    formik.setFieldValue("documentUrl", "");
    formik.setFieldValue("files", "");
    formik.setFieldValue("mediaType", "File");
    setInvestorDocumentId(0);
    setDocumentLinkUpdate("");

    setDocLink("");
    console.log("setDocName 9", DocName);
    setDocName("");
    setEdit(false);
    setShowTab(true);
    setAdd(true);
    setShowButton(false);
  };
  console.log("DocLinkHere", docLink, stateDropDownLists.mediaTypes);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Dialog
        visible={openAddDialog}
        className="w-100 md:w-4"
        onHide={() => onHideAddDocument()}
        header={add === true ? "Add Document" : "Edit Document"}
        headerClassName="text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content p-0"
      >
        <div className="m-0">
          <hr className="m-0" />
          <form>
            <div className="mt-1">
              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Document Type*</label>
                    <Dropdown
                      inputId="documentTypeId"
                      name="documentTypeId"
                      value={formik.values.documentTypeId}
                      options={
                        documentTypeDropdown === null
                          ? []
                          : documentTypeDropdown
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select document type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("documentTypeId"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("documentTypeId")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Document Description</label>
                    <InputText
                      name="documentDescription"
                      value={formik.values.documentDescription}
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("documentDescription"),
                        "card-inputBox": true,
                        "p-filled": true,
                      })}
                      placeholder="Enter document description"
                    />
                    {getFormErrorMessage("documentDescription")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Media Type</label>
                    <Dropdown
                      inputId="mediaType"
                      name="mediaType"
                      value={formik.values.mediaType}
                      options={
                        stateDropDownLists === null
                          ? []
                          : stateDropDownLists.mediaTypes
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select media type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("mediaType"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("mediaType")}
                  </div>

                  {formik.values.mediaType === "URL" ? (
                    <>
                      <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                        <label className="inputLabel">Document Url*</label>
                        <InputText
                          name="documentUrl"
                          value={formik.values.documentUrl}
                          onChange={(e: any) => {
                            // setproductdocLink(e.target.value);
                            setDocLink(e.target.value);
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("documentUrl"),
                            "card-inputBox": true,
                          })}
                          placeholder="Enter Document Url"
                        />

                        {getFormErrorMessage("documentUrl")}
                      </div>
                      {/* 
                      <div className="d-flex justify-content-center sm:ml-2">
                        <Button
                          type="submit"
                          className="mr-4 btn-nav w-7rem justify-content-center"
                          onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            uploadDoc();
                          }}
                        >
                          Save
                        </Button>
                      </div> */}
                      {/* </div> */}
                      <div className="col-12 md:col-1 m-0 mb-md-0">
                        {/* <div className="d-flex align-items-center"> */}{" "}
                      </div>
                      {/* </div> */}
                    </>
                  ) : (
                    <>
                      <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6 flex justify-content-center">
                        <>{console.log()}</>
                        {/* <label>&nbsp;</label> */}
                        <FileUpload
                          mode="basic"
                          name="demo"
                          ref={fileRef}
                          auto={true}
                          // maxFileSize={10_000_000}
                          customUpload={true}
                          chooseLabel="Select File"
                          className="btn-dialog border-round-md w-100 UploadAccreBtn"
                          uploadHandler={uploadFile}
                          disabled={
                            formik.values.documentTypeId === 0 ||
                            investorAccountId === 0
                          }
                        />
                      </div>

                      <div className="d-flex flex-column p-2 col-12 align-self-end">
                        {DocName === "" || DocName === undefined ? (
                          <></>
                        ) : (
                          <label className="inputLabel">
                            File Name:{" "}
                            <NavLink
                              className="document-link"
                              onClick={(e) => openDoc(e)}
                              to=""
                            >
                              {DocName}
                            </NavLink>
                          </label>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {formik.values.mediaType === "URL" || edit ? (
                <>
                  {" "}
                  <div className="d-block sm:d-flex sm:flex-column  col-12">
                    <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12 ">
                      <div className="d-flex flex-column r p-2 col-12 sm:col-5 annualClass">
                        <>{console.log("URL Check", formik.errors)}</>
                        <Button
                          className="btn-dialog "
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            // console.log(
                            //   "Formik Erros",
                            //   formik.errors,
                            //   formik.errors.documentUrl,
                            //   Object.keys(formik.errors),
                            // Object.keys(formik.errors)?.includes("documentUrl"),
                            //   !Object.keys(formik.errors)?.includes(
                            //     "documentUrl"
                            //   )
                            // );
                            formik.handleSubmit();
                            console.log(
                              "formik",
                              Object.keys(formik.errors)?.includes(
                                "documentUrl"
                              ),
                              formik.values.documentUrl
                            );
                            if (
                              !Object.keys(formik.errors)?.includes(
                                "documentUrl"
                              )
                            ) {
                              console.log("formik error Api call");
                              uploadDoc();
                            }
                          }}
                          disabled={
                            (formik.values.files === undefined ||
                              formik.values.files === "") &&
                            (docLink === "" || docLink === null)
                          }
                        >
                          <span className="ml-auto mr-auto ">Add Document</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        header=""
        maximizable
        visible={visibleViewUpload}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        {!docLink?.includes("jpeg") &&
        !docLink?.includes("jpg") &&
        !docLink?.includes("png") &&
        !docLink?.includes("pdf") &&
        !docLink?.includes("mp4") &&
        !docLink?.includes("txt") ? (
          <div>The File is Downloading!!!</div>
        ) : (
          <></>
        )}

        <div className="m-0">
          {docLink ? (
            <iframe
              src={docLink + sasKey}
              allowFullScreen
              style={{ width: "100%", height: "80vh" }}
            ></iframe>
          ) : (
            <>
              <div>No document found!!!</div>
            </>
          )}
        </div>
      </Dialog>

      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={onDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />

      {/* <div className="grid"> */}
      <Toast ref={toast} className="themeToast" />
      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Manage Investor Documents</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              listDocument === undefined ||
              listDocument?.length === 0 ||
              listDocument === null
            }
            onClick={() => setOpenDialogGrid(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="col-12 md:col-6">
        <div className="card col-11 p-0 mt-1 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Manage Investor Documents</h1>
          <p className="pageSubHeader"> </p>
        </div>
      </div> */}
      {/* <div className="grid col-12">
        <div className="col-12"> */}
      <div className="card ">
        {/* <Card> */}
        {/* <div className="grid justify-content-start">
            {" "}
            {!add ||
              (edit && <div className="tabHeader-wrap mb-3 col-6"></div>)}
            {showTab && (
              <div className="tabHeader-wrap mb-3   col-6">
                <h4 className="cardHeader"> Add Document </h4>
              </div>
            )}
            {edit && (
              <div className="tabHeader-wrap mb-3 col-6">
                <h4 className="cardHeader"> Edit Document </h4>
              </div>
            )}
          </div> */}
        {add || edit ? (
          <>
            {/* <div className="mb-4 mt-2"> */}
            <div className="formgrid grid pr-0 d-flex justify-content-between col-12">
              <div className="col-12 w-50 md:col-3 m-0 mb-md-0">
                <div className="d-flex align-items-center">
                  {/* <label className="inputLabel"> Investor Account *</label> */}
                  <Dropdown
                    inputId="partnerId"
                    name="partnerId"
                    value={investorAccountId === 0 ? "" : investorAccountId}
                    options={partnerList === null ? [] : partnerList}
                    filter
                    optionLabel="userName"
                    resetFilterOnHide={true}
                    showFilterClear={true}
                    optionValue="userId"
                    placeholder="Select Investor Account"
                    onChange={(e) => {
                      handleChange(e);
                      setInvestorAccountId(e.target.value);
                      setAddDoc(false);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("partnerId"),
                      "card-inputBox": true,
                    })}
                  />
                  {/* {getFormErrorMessage("partnerId")} */}
                </div>
              </div>
              <div className="col-12 md:col-3 m-0 mb-md-0 px-0">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn-dialog"
                    disabled={addDoc}
                    onClick={(e) => {
                      e.preventDefault();
                      // formik.handleSubmit();
                      // uploadDoc();
                      setOpenAddDialog(true);
                      // formik.setFieldValue("partnerId", investorAccountId);
                    }}
                  >
                    <span className="ml-auto mr-auto ">Add Document</span>
                    <span className="p-2">
                      <RiAddFill />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="formgrid grid px-2 p-1 col-12">
              {/* <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 "> */}

              {/* </div> */}
            </div>
            {/* </div> */}
            {/* {showButton ? (
              <div className="d-flex justify-content-center mt-4 mb-3"></div>
            ) : (
              <></>
            )} */}
          </>
        ) : (
          <></>
        )}
        {!stateName && (
          <DataTableComponent
            valueData={listDocument || []}
            fieldsElements={dataTableElements}
            isPaginationAllowed={true}
            scrollable={true}
            maxHeight="calc(-220px + 100vh)"
            className="gridcell deletebtn "
            columnResizeMode="expand"
            openDailog={openDialogGrid}
            setOpenDailog={setOpenDialogGrid}
          />
        )}
        {/* </Card> */}
      </div>
      {/* </div>
      </div> */}
      {/* </div> */}
    </>
  );
}
