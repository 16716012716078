import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import Loader from "../../../../components/loader/Loader";
import { setProductId } from "../../../../store/features/investment/investment-filter/investment-filter-id/slice";
import { addThunkInvestmentReturn } from "../../../../store/features/operation/allocate-earning/add-investment-return/slice";
import { getThunkAllocateEarning } from "../../../../store/features/operation/allocate-earning/get-earning-data/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function AllocateEarning({
  productObjectByFilter,
  setProductObjectByFilter,
  investmentID,
  setInvestmentId,
  editData,
  setEditData,
  setLoadForm,
  setShowAddEarnings,
}: any) {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  // const [productObjectByFilter, setProductObjectByFilter] = useState<any>();

  const [investmentReturnID, setInvestmentReturnId] = useState(0);
  // const [investmentID, setInvestmentId] = useState(0);

  const returnTypeData = useAppSelector(
    (state) => state.getAllocatedEarningData.data.returnType
  );

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const resetProductObjState = () => {
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
      fundingDate: "",
    });
  };

  const formik = useFormik({
    initialValues: {
      returnType: "",
      amount: 0,
      returnDate: "",
      // eslint-disable-next-line unicorn/no-null
      allocationDate: null,
      emailStatus: "N",
      productId: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.returnType) {
        errors.returnType = "Return type is required";
      }
      if (!data.amount) {
        errors.amount = "Amount is required";
      }
      if (data.amount && data.amount > 20_000_000_000) {
        errors.amount = "Amount should be less than equal to $20B";
      }
      if (!data.returnDate) {
        errors.returnDate = "Return date is required";
      }
      if (
        data.returnDate &&
        new Date(data.returnDate).setHours(0, 0) <
          new Date(productObjectByFilter.fundingDate).setHours(0, 0)
      ) {
        errors.returnDate =
          "Return date should be greater than investment start date ";
      }
      if (!data.productId) {
        errors.productId = "Investment id is required";
      }
      return errors;
    },
    onSubmit: (data) => {
      setLoading(true);
      const returnDateTest =
        formik.values.returnDate !== null &&
        formik.values.returnDate !== "" &&
        formik.values.returnDate !== undefined
          ? convertLocalDateToUTC(formik.values.returnDate)
          : formik.values.returnDate;
      const equityCashDistiAllocationDateTest =
        formik.values.allocationDate !== null &&
        formik.values.allocationDate !== "" &&
        formik.values.allocationDate !== undefined
          ? convertLocalDateToUTC(formik.values.allocationDate)
          : formik.values.allocationDate;

      const formData = {
        action: investmentReturnID === 0 ? "Create" : "Update",
        investmentReturnId: investmentReturnID || 0,
        investmentId: investmentID,
        returnTypeId: formik.values.returnType,
        returnAmount: formik.values.amount,
        returnDate: returnDateTest,
        equityCashDistiAllocationDate: equityCashDistiAllocationDateTest,
      };
      dispatch(addThunkInvestmentReturn(formData))
        .then((resposne) => {
          successToastMessage(toast, "Data saved successfully");
          const param = {
            legalEntityID: fundDropdown.fundid,
            investmenId: investmentID,
          };
          setLoadForm(false);
          setShowAddEarnings(true);
          dispatch(getThunkAllocateEarning(param));
          formik.resetForm();
          formik.setFieldValue("amount", "");
          formik.setFieldValue("managerFeesPreSet", "");
          formik.setFieldValue("productId", productObjectByFilter?.productID);
          dispatch(setProductId({ productId: 0, resetFilters: true }));
          resetProductObjState();
          setInvestmentReturnId(0);
          setInvestmentId(0);
          // eslint-disable-next-line unicorn/no-null
          setEditData(null);
        })
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  console.log("formikErrors", formikErrors);
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "returnDate" || name === "allocationDate") {
      const dateValue = value;
      formik.setFieldValue(name, dateValue);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const onReset = () => {
    formik.resetForm();
    formik.values.amount = 0;
    setInvestmentReturnId(0);
    dispatch(setProductId({ productId: 0, resetFilters: true }));
    resetProductObjState();
    // eslint-disable-next-line unicorn/no-null
    setEditData(null);
    setInvestmentId(0);
    setLoadForm(false);
    setShowAddEarnings(true);
  };
  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue("productId", productObjectByFilter?.productID);
  }, [fundDropdown.fundid]);
  useEffect(() => {
    formik.setFieldValue("productId", productObjectByFilter?.productID);
  }, [productObjectByFilter?.productID]);

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "returnDate",
        editData.returnDate === null ? "" : formatDate(editData.returnDate)
      );
      formik.setFieldValue("returnType", editData.returnType);
      formik.setFieldValue("allocationDate", editData.allocationDate);
      formik.setFieldValue("amount", editData.amount);
      formik.setFieldValue("productId", editData.productId);
      setInvestmentReturnId(editData.investmentReturnID);
      setLoadForm(true);
    }
  }, [editData]);

  console.log("test21", productObjectByFilter);

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />

      <>
        <>
          <form>
            <div className="formgrid grid ">
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                <label className="inputLabel">Return Type * </label>
                <Dropdown
                  name="returnType"
                  value={formik.values.returnType}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={returnTypeData === null ? [] : returnTypeData}
                  optionLabel="value"
                  optionValue="key"
                  placeholder="Select return type"
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("returnType"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("returnType")}
                {productObjectByFilter?.equityInvestment?.toLowerCase() ===
                  "yes" &&
                  +formik?.values?.returnType === 1 && (
                    <>
                      <div>
                        <RiErrorWarningLine className="warningIcon" />
                        <span className="inputLabelNotification text-dark">
                          {" "}
                          Please check the earnings type you have selected. Your
                          investment is set as type equity which typically
                          generates equity cash distributions as income.
                        </span>
                      </div>
                    </>
                  )}
                {productObjectByFilter?.equityInvestment?.toLowerCase() ===
                  "no" &&
                  +formik?.values?.returnType === 4 && (
                    <>
                      <div>
                        <RiErrorWarningLine className="warningIcon" />
                        <span className="inputLabelNotification text-dark">
                          {" "}
                          Please check the earnings type you have selected. Your
                          investment is set as type debt which typically
                          generates debt interest income.
                        </span>
                      </div>
                    </>
                  )}
              </div>
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 ">
                <label className="inputLabel">Amount * </label>
                <InputNumber
                  name="amount"
                  value={
                    formik.values.amount !== 0
                      ? formik.values.amount
                      : undefined
                  }
                  //   formik.values.amount === 0
                  //     ? undefined
                  //     : formik.values.amount
                  // }
                  onValueChange={(e) => {
                    handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid ": isFormFieldInvalid("amount"),
                    "card-inputBox": true,
                    "single-border": true,
                  })}
                  // maxFractionDigits={2}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  placeholder="Enter amount"
                  // maxLength={20}
                />
                {getFormErrorMessage("amount")}
              </div>
            </div>
            <div className="formgrid grid">
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                <label className="inputLabel">Return Date* </label>
                <Calendar
                  name="returnDate"
                  value={
                    formik.values.returnDate === "" ||
                    formik.values.returnDate === null
                      ? undefined
                      : new Date(formik.values.returnDate)
                  }
                  onChange={(e) => handleChange(e)}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("returnDate"),
                    "card-inputBox": true,
                  })}
                  placeholder="mm/dd/yyyy"
                  readOnlyInput
                />
                {getFormErrorMessage("returnDate")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                <label className="inputLabel">
                  Equity Cash Distribution Allocation Date{" "}
                </label>
                <Calendar
                  name="allocationDate"
                  value={
                    formik.values.allocationDate === "" ||
                    formik.values.allocationDate === null
                      ? undefined
                      : new Date(formik.values.allocationDate)
                  }
                  onChange={(e) => handleChange(e)}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("allocationDate"),
                    "card-inputBox": true,
                  })}
                  placeholder="mm/dd/yyyy"
                  readOnlyInput
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4 mb-3">
              <Button
                className="mr-4 btn-nav w-7rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  onReset();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn-nav w-7rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  // handleChangeState();
                  formik.handleSubmit();
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </>
      </>
    </>
  );
}
