import { IDeleteRoleparameters } from "../../store/features/accountatatement/rolemanagement/managerole/deleterole";
import { IGetRoleListParameter } from "../../store/features/accountatatement/rolemanagement/managerole/getmanagerole";
import { ISaveRoleparameters } from "../../store/features/accountatatement/rolemanagement/managerole/saverole";
import { IUpdateRoleparameters } from "../../store/features/accountatatement/rolemanagement/managerole/updatemanagerole";
import { IGetAccessForNotificationparameters } from "../../store/features/accountatatement/rolemanagement/managesubscription/getaccessfornotification";
import { IGetDynamicUserListparameters } from "../../store/features/accountatatement/rolemanagement/managesubscription/getdynamicuserlist";
import { IGetNotificationListparameters } from "../../store/features/accountatatement/rolemanagement/managesubscription/getnotificationlist";
import { IBindUserInformationparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/binduser";
import { IDeleteAdminUserparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/deleteuser";
import { IManageUserListparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/getmanageuserlist";
import { IGetInfoByEmailParameter } from "../../store/features/accountatatement/rolemanagement/manageuser/infobyemail";
import { IInformationByUsernameparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/infobyusername";
import { ISaveUserLoginDetailsparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/savemanageuser";
import { IUpdateUserLoginDetailsparameters } from "../../store/features/accountatatement/rolemanagement/manageuser/updatemanageuser";
import { ICheckRoleExistParameters } from "../../store/features/accountatatement/rolemanagement/manageuseraccess/check-role-access";
import { IDeleteUserRoleAccessparameters } from "../../store/features/accountatatement/rolemanagement/manageuseraccess/deleteroleaccess";
import { IGetUserListDetailsparameters } from "../../store/features/accountatatement/rolemanagement/manageuseraccess/getuserdetails";
import { ISaveUserRoleMappingparameters } from "../../store/features/accountatatement/rolemanagement/manageuseraccess/saveroleaccess";
import { IGetMenuListparameters } from "../../store/features/accountatatement/rolemanagement/role-capability/getmenulist";
import { ISetGrantRevokeMenuStatusparameters } from "../../store/features/accountatatement/rolemanagement/role-capability/revokemenu";
import { ICalclateParameter } from "../../store/features/operation/allocate-earning/calculate-earnings";
import { IInvestmentIdParameter } from "../../store/features/operation/allocate-earning/get-investment-id-product";
import { IAssignExpensesToInvparameters } from "../../store/features/operation/distributeexpensestoinvestors/assignexpensestoinv";
import { IGetAllExpensesListparameters } from "../../store/features/operation/distributeexpensestoinvestors/getallexpenseslist";
import { IGetProRataListparameters } from "../../store/features/operation/distributeexpensestoinvestors/getproratalist";
import { ISaveNotesForExpenseparameters } from "../../store/features/operation/distributeexpensestoinvestors/savenotesforexpense";
import { IGetEarningTransactionsparameters } from "../../store/features/operation/searchearningstransactions/getearningtransactions";
import { IGetInvoiceCustomerSubscriptionDetailsparameters } from "../../store/features/operation/sendusageunitsforInvoicing/getInvoicecustomersubscriptiondetails";
import { IGetInvoiceDAVparameters } from "../../store/features/operation/sendusageunitsforInvoicing/getinvoiceDAV";
import { IAddFundProfileDataParameter } from "../../store/features/operation/setup-fund/add-fund-profile";
import { IGetSetupFundEntityParameter } from "../../store/features/operation/setup-fund/get-setup-entity-list";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { ISaveMenuCapabilityparameters } from "./../../store/features/accountatatement/rolemanagement/role-capability/savemenucapability/index";
import { IGetReverseAllocationparameters } from "./../../store/features/operation/distributeexpensestoinvestors/getreverseallocation/index";
import { IAddUsageparameters } from "./../../store/features/operation/sendusageunitsforInvoicing/addusage/index";

export function GetCopyRoleListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_ENTITLEMENT_URL}/ManageRoles/GetCopyRoleList
    `
  );
}
export function SaveMenuCapabilityService(data: ISaveMenuCapabilityparameters) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/SaveMenuCapabilityMapping?FromRoleId=${
      data.FromRoleId
    }&ToRoleId=${data.ToRoleId}&LoginId=${data.LoginId}
      `
  );
}
export function GetRoleListService(data: IGetRoleListParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/GetRoleList?UserId=${data.UserId}&LegalEntityId=${
      data.LegalEntityId
    }`
  );
}

export function UpdateRoleService(data: IUpdateRoleparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ENTITLEMENT_URL}/ManageRoles/UpdateRole?Id=${
      data.Id
    }&Role=${data.Role}&LoginId=${data.LoginId}
      `
  );
}
export function DeleteRoleService(data: IDeleteRoleparameters) {
  return apiFactory().delete(
    `${envVariable!.REACT_APP_API_ENTITLEMENT_URL}/ManageRoles/DeleteRole?Id=${
      data.Id
    }
      `
  );
}
export function SaveRoleService(data: ISaveRoleparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ENTITLEMENT_URL}/ManageRoles/SaveRole?Role=${
      data.Role
    }&LoginId=${data.LoginId}

      `
  );
}

export function ManageUserListService(data: IManageUserListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/ManageUserList?LegalEntityId=${data.LegalEntityId}

    `
  );
}

export function SaveUserLoginDetailsService(
  data: ISaveUserLoginDetailsparameters
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ENTITLEMENT_URL}/ManageRoles/EmployeeCUD
      `,
    data
  );
}
export function InformationByUsernameService(
  data: IInformationByUsernameparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/InformationByUsername?Username=${data.Username}

    `
  );
}

export function DeleteAdminUserService(data: IDeleteAdminUserparameters) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/DeleteAdminUser?LEDId=${data.LEDId}&UserId=${data.UserId}

      `
  );
}

export function UpdateUserLoginDetailsService(
  data: IUpdateUserLoginDetailsparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/UpdateUserLoginDetails
      `,
    data
  );
}

export function GetMenuListService(data: IGetMenuListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/GetMenuList?RoleId=${data.RoleId}

    `
  );
}

export function SetGrantRevokeMenuStatusService(
  data: ISetGrantRevokeMenuStatusparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/SetGrantRevokeMenuStatus?RoleId=${data.RoleId}&MenuId=${
      data.MenuId
    }&Type=${data.Type}&LoginId=${data.LoginId}

    `
  );
}

export function BindUserInformationService(
  data: IBindUserInformationparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/BindUserInformation?LegalEntityId=${
      data.LegalEntityId
    }&UserTypeId=${data.UserTypeId}


    `
  );
}

export function GetUserListDetailsService(data: IGetUserListDetailsparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/GetUserListDetails?LegalEntityId=${data.LegalEntityId}`
  );
}

export function DeleteUserRoleAccessService(
  data: IDeleteUserRoleAccessparameters
) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/DeleteUserRoleAccess?UserId=${data.UserId}
      `
  );
}

export function SaveUserRoleMappingService(
  data: ISaveUserRoleMappingparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/SaveUserRoleMapping
      `,
    data
  );
}

// checking role access
export function CheckRoleAccessExistService(data: ICheckRoleExistParameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/IsAdminRoleExist?LegalEntityId=${data.legalEntityId}`
  );
}

export function GetDynamicUserListService(data: IGetDynamicUserListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_NOTIFICATION_MANAGEMENT_URL
    }/Notifications/GetDynamicUserList?LegalEntityId=${data.LegalEntityId}`
  );
}

export function GetNotificationListService(
  data: IGetNotificationListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_NOTIFICATION_MANAGEMENT_URL
    }/Notifications/GetNotificationList?LEID=${data.LEID}&UserId=${data.UserId}`
  );
}

export function GetAccessForNotificationService(
  data: IGetAccessForNotificationparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_NOTIFICATION_MANAGEMENT_URL
    }/Notifications/GetAccessForNotification?LEID=${data.LEID}&UserId=${
      data.UserId
    }&NotificationId=${data.NotificationId}&Type=${data.Type}&LoginId=${
      data.LoginId
    }
      `,
    data
  );
}

export function GetNotificationCountService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_NOTIFICATION_MANAGEMENT_URL
    }/Notifications/GetNotificationCount?LegalentityId=${data}`
  );
}

// Manage Fund Document

export function GetFundDocumentListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/ManageFundDocument/GetFundDocList?LegalEntityId=${data.legalEntityID}`
  );
}
export function GetFundDocumentTypeDropDownService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/ManageFundDocument/GetDropdownListForManageFundDoc?UserName=${
      data.UserName
    }&UserId=${data.UserId}`
  );
}

export function addFundDocumentService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/ManageFundDocument/AddUpdateFundDocument`,
    data
  );
}

export function deleteFundDocumentService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/ManageFundDocument/DeleteFundDocument?Id=${data.id}&LegalEntityId=${
      data.legalEntityId
    }`
  );
}
// end
export function GetInvoiceCustomerSubscriptionDetailsService(
  data: IGetInvoiceCustomerSubscriptionDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVOICE_URL
    }/Invoice/GetInvoiceCustomerSubscriptionDetails?LegalEntityId=${
      data.LegalEntityId
    }
      `
  );
}

export function GetInvoiceDAVService(data: IGetInvoiceDAVparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVOICE_URL
    }/Invoice/GetDailyAccountValue?LegalEntityId=${
      data.LegalEntityId
    }&TransactionDate=${data.TransactionDate}
      `
  );
}

export function GetEarningTransactionsService(
  data: IGetEarningTransactionsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetEarningTransactions?LegalEntityId=${data.LegalEntityId}
      `
  );
}

export function AddUsageService(data: IAddUsageparameters) {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_INVOICE_URL}/Invoice/AddUsage?LegalEntityId=${
      data.LegalEntityId
    }&transactionDate=${data.transactionDate}&dailyAccountValue=${
      data.dailyAccountValue
    }`
  );
}

export function GetProRataListService(data: IGetProRataListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Operations/GetProRataList?LegalEntityId=${data.LegalEntityId}`
  );
}

export function GetAllInvestorAllocationService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Allocations/GetAllocateUnitsData?LegalEntityId=${
      data.legalEntityId
    }&InvestmentId=${data.investmentId}`
  );
}

export function GetAllocatedInvestorService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Allocations/GetAllocatedInvestorsByInvestment?LegalEntityId=${
      data.legalEntityId
    }&InvestmentId=${data.investmentId}`
  );
}

export function ReverseAllocationSliceService(data: any) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Allocations/ReverseAllocationByStakeTrackingId?LegalEntityId=${
      data.legalEntityId
    }&LPPartnerStakeTrackingId=${data.LPPartnerStakeTrackingId}`
  );
}

export function AddUpdateAllocationService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Allocations/AllocateUnitsCUD`,
    data
  );
}

export function GetAllExpensesListService(data: IGetAllExpensesListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Operations/GetAllExpensesList?LegalEntityId=${data.LegalEntityId}&Year=${
      data.Year
    }`
  );
}

export function GetAllAllocationSListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Allocations/GetInvestmentsInfo?LegalEntityId=${
      data.LegalEntityId
    }&LPPartnerId=${data.LPPartnerId}`
  );
}

export function GetdublicationAllocationService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Allocations/GetDuplicateAllocations`
  );
}

export function SaveAllAllocationSListService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Allocations/AllocatUnitsCUD
      `,
    data
  );
}

export function GetReverseAllocationService(
  data: IGetReverseAllocationparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Operations/GetReverseAllocation?EntityId=${data.EntityId}&LegalEntityId=${
      data.LegalEntityId
    }&YearMonth=${data.YearMonth}`
  );
}

export function AssignExpensesToInvService(
  data: IAssignExpensesToInvparameters
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Operations/AssignExpensesToInv
      `,
    data
  );
}

export function SaveNotesForExpenseService(
  data: ISaveNotesForExpenseparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Operations/SaveNotesForExpense?Notes=${data.Notes}&Year=${
      data.Year
    }&LegalEntityId=${data.LegalEntityId}&YearMonth=${data.YearMonth}`
  );
}

// Setup Fund
export function GetFundTypeListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL}/Fund/FundTypeDropDown`
  );
}
export function AddFundProfileService(data: IAddFundProfileDataParameter) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/AddUpdateFundAndManagerDetails`,
    data
  );
}

export function GetFundProfileService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/GetFundProfileDetails?LegalEntityId=${
      data.legalEntityId
    }&GPLegalEntityId=${data.gpLegalEntityId}`
  );
}

export function GetSetupFundService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/GetFundEntity?LegalEntityId=${data.LegalEntityId}&GPLegalEntityId=${
      data.gpLegalEntityId
    }`
  );
}
export function GetSetupFundEntityListService(
  data: IGetSetupFundEntityParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/GetFundEntityListById?LegalEntityId=${data.legalEntityId}`
  );
}

export function AddGPProfileService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/AddFundGPProfileDetails`,
    data
  );
}

export function AddBankMailInfoService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/AddFundbankAndMailingDetails`,
    data
  );
}
export function CheckFundSlugService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/CheckFundSlugExits?UrlName=${data.urlName}&LegalEntityID=${
      data.legalEntityId
    }`
  );
}

export function CheckGPEMailExistSetupService(data: any) {
  const newGPEmail = encodeURIComponent(data.newGPEmail);
  const oldGPEmail = encodeURIComponent(data.oldGPEmail);
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/CheckGPEmailExist?NewGPEmailID=${newGPEmail}&LegalEntityID=${
      data.legalEntityId
    }&OldGPEmailID=${oldGPEmail}`
  );
}

export function AddThirdPartyApiService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/AddThirdPartyAPIkeys`,
    data
  );
}
export function AddEdgarFilingService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL}/Fund/AddEdgarDetails`,
    data
  );
}
export function GetSetupEdgarReportService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/GetEdgarRequiremntsList?LegalEntityId=${data}`
  );
}
// end

// AllocateEarnings
export function GetInvestmentReturnsAndTypeService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetInvestmentReturnsAndType?LegalEntityId=${
      data.legalEntityID
    }&InvestmenId=${data.investmenId}`
  );
}
export function GetCalculateEarningsService(data: ICalclateParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/CalculateEarnings?InvestmentReturnTypeId=${
      data.investmentReturnTypeID
    }&InvestmentReturnId=${data.investmentReturnID}`
  );
}
export function GetDistributeEarningsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/DistributeEarnings?InvestmentReturnTypeId=${
      data.investmentReturnTypeId
    }&InvestmentReturnId=${data.investmentReturnId}&LegalEntityId=${
      data.legalEntityId
    }&EmailStatus=${data.emailStatus}`
  );
}
export function AddInvestmentReturnService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Earnings/InvestmentReturnCUD`,
    data
  );
}
export function GetInvestmentIdByProductService(data: IInvestmentIdParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetInvestmentByProductId?ProductId=${data.productId}`
  );
}
// end

// Reverse Earnings

export function GetEarningsDataService(data: number) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetEarningsData?LegalEntityId=${data}`
  );
}

export function GetReverseDataService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/ReverseData?InvestmentReturnId=${
      data.investmentReturnId
    }&InvestmentReturnTypeId=${data.investmentReturnTypeId}&LegalEntityId=${
      data.legalEntityId
    }`
  );
}
export function GetEarningsEntriesService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetReverseEntries?InvestmentReturnId=${
      data.investmentReturnId
    }&InvestmentReturnTypeId=${data.investmentReturnTypeId}&InvestmentId=${
      data.investmentId
    }`
  );
}

export function GetLastInvestmentReturnIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetLastInvestmentReturnId?InvestmentId=${data}`
  );
}

export function GetInfoByEmailManageUser(data: IGetInfoByEmailParameter) {
  const value = encodeURIComponent(data.email).replace("%20", "+");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ENTITLEMENT_URL
    }/ManageRoles/GetSponsorUserDetails?EmailAddress=${value}&isEdited=${
      data.isEdited
    }`
  );
}

export function AddInvestmentReturnServicePooledFund(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/InvestmentReturnCUDPooledFunds`,
    data
  );
}

export function getDistributeEarningsPooledFundsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/DistributeEarningsPooledFunds?InvestmentReturnTypeId=${
      data.investmentReturnTypeId
    }&InvestmentReturnId=${data.investmentReturnId}&LegalEntityId=${
      data.legalEntityId
    }&EmailStatus=${data.emailStatus}`
  );
}

export function getReverseDataPooledfundsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/ReverseDataPooledfunds?InvestmentReturnId=${
      data.investmentReturnId
    }&InvestmentReturnTypeId=${data.investmentReturnTypeId}&LegalEntityId=${
      data.legalEntityId
    }`
  );
}

export function getInvestmentReturnsAndTypePooledfunds(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetInvestmentReturnsAndTypePooledfunds?LegalEntityId=${
      data.legalEntityID
    }&InvestmenId=${data.investmenId}`
  );
}

export function getEarningsDataPooledFundsService(data: number) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetEarningsDataPooledFunds?LegalEntityId=${data}`
  );
}

export function getCalculateEarningsPooledfundsService(
  data: ICalclateParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/CalculateEarningsPooledfunds?InvestmentReturnTypeId=${
      data.investmentReturnTypeID
    }&InvestmentReturnId=${data.investmentReturnID}`
  );
}

export const getCalculateEarningByPecentageService = (data: any) => {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Earnings/GetCalculateEarningByPecentage?Percentage=${
      data.percent
    }&ReturnDate=${data.returnDate}&InvestmentID=${data.investmentID}&Type=${
      data.calculateBy
    }`
  );
};
// end
