// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetK1PartnerInformationThunk } from "../../../store/features/taxmanagement/partnerinformation/getk1partnerinformation/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function PartnerInformation() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const portState = useAppSelector(
    (state) => state.GetK1PartnerInformation.loading
  );
  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDailog, setOpenDailog] = useState(false);
  const [getMenuListDateConverted, setGetMenuListDateConverted] = useState<any>(
    []
  );

  console.log("getRevision", getRevision);
  const GetMenuList = useAppSelector(
    (state) => state.GetK1PartnerInformation?.data
  );

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(new Date(rowData));
    return dateFormat;
  };
  const dataTableElements = [
    {
      field: "firstName",
      header: "First Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.firstName}>
            {rowData.firstName}
          </span>
        );
      },
    },
    {
      field: "lastName",
      header: "Last Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.lastName}>
            {rowData.lastName}
          </span>
        );
      },
    },
    {
      field: "accountType",
      header: "Investing Account Type",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.accountType}>
            {rowData.accountType}
          </span>
        );
      },
    },
    {
      field: "accountNickName",
      header: "Investing Account Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.accountNickName}>
            {rowData.accountNickName}
          </span>
        );
      },
    },
    {
      field: "businessName",
      header: "Business Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.businessName}>
            {rowData.businessName}
          </span>
        );
      },
    },
    {
      field: "ein",
      header: "EIN",
      style: { minWidth: "250px" },

      //   body: (rowData: any) => dateFormatter(new Date(rowData.effectiveDate)),
    },
    {
      field: "taxPayerId",
      header: "TaxPayer Id",
      style: { minWidth: "250px" },
    },
    {
      field: "address",
      header: "Investor Address",
      style: { minWidth: "360px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.address}>
            {rowData.address}
          </span>
        );
      },
    },
    {
      field: "investingAccountAddress",
      header: "Investing Account Address",
      style: { minWidth: "360px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.investingAccountAddress}>
            {rowData.investingAccountAddress}
          </span>
        );
      },
    },

    {
      field: "legalEntityName",
      header: "Legal Entity Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.legalEntityName}>
            {rowData.legalEntityName}
          </span>
        );
      },
    },
    {
      field: "dateCreated",
      header: "Date Joined Partnership",
      style: { minWidth: "250px" },
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.dateCreated),
      filterElement: dateFilterTemplate,
    },
    {
      field: "custodianName",
      header: "Custodian Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.custodianName}>
            {rowData.custodianName}
          </span>
        );
      },
    },
    {
      field: "custodianAddress",
      header: "Custodian Address",
      style: { minWidth: "250px" },
      body: (rowData: any) => {
        return (
          <span className="left-align" title={rowData.custodianAddress}>
            {rowData.custodianAddress}
          </span>
        );
      },
    },
  ];

  const exportExcelHeader = [
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
    },
    {
      field: "accountType",
      header: "Investing Account Type",
    },
    {
      field: "accountNickName",
      header: "Investing Account Name",
    },
    {
      field: "businessName",
      header: "Business Name",
    },
    {
      field: "ein",
      header: "EIN",
    },
    {
      field: "taxPayerId",
      header: "TaxPayer Id",
    },
    {
      field: "address",
      header: "Investor Address",
    },
    {
      field: "investingAccountAddress",
      header: "Investing Account Address",
    },
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
    },
    {
      field: "dateCreated",
      header: "Date Joined Partnership",
      dataType: "date",
    },
    {
      field: "custodianName",
      header: "Custodian Name",
    },
    {
      field: "custodianAddress",
      header: "Custodian Address",
    },
  ];

  useEffect(() => {
    if (GetMenuList !== undefined && GetMenuList.length > 0) {
      setGetMenuListDateConverted(
        getListDateConversion(GetMenuList, ["dateCreated"])
      );
    } else {
      setGetMenuListDateConverted([]);
    }
  }, [GetMenuList]);

  useEffect(() => {
    if (fundDropdown.fundid) {
      const param = {
        LegalEntityId: fundDropdown.fundid,
      };
      console.log("info");
      dispatch(GetK1PartnerInformationThunk(param));
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">K-1: Part 1-Partner Information</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  GetMenuList || [],
                  "K-1: Part 1-Partner Information",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  GetMenuList === undefined ||
                  GetMenuList?.length === 0 ||
                  GetMenuList === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <div>
              {portState === "loading" ? (
                <Loader />
              ) : (
                <DataTableComponent
                  valueData={getMenuListDateConverted!}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  isDownload={true}
                  fileName="K1 Part 1 Summary"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-165px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
