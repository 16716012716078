import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvAccrediationStatusService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IGetInvAccrediationStatusPayload } from ".";

export interface IGetUniqueNoResponse {
  legalEntityID?: number;
  investorID?: number;
  accreddDocumentLink: any;
  accreddTransactionID: string;
  isAccredited?: boolean;
  accreddDocument: string;
}

export interface IGetOutput {
  rejectionComment: string;
  dateApproved: string;
  dateExpire: string;
  dateSubmitted: string;
  entityType: string;
  legalName: string;
  pdfLetterUrl: string;
  pdfLetterUrlExpiredInSeconds?: number;
  status: string;
  submissionComment: string;
}

interface IEsignDocument {
  data: IGetUniqueNoResponse;
  output?: IGetOutput;
  loading: string;
  accredStatus: string;
  error?: string | string;
  isDisableGetAccredButton: boolean;
}

const initialState: IEsignDocument = {
  data: {
    // legalEntityID: undefined,
    // investorID: undefined,
    accreddDocumentLink: "",
    accreddTransactionID: "",
    // isAccredited: undefined,
    accreddDocument: "",
  },
  isDisableGetAccredButton: false,
  output: undefined,
  accredStatus: "",
  loading: "loading",
  error: undefined,
};

export const getInvAccrediationStatusDetailsThunk = createAsyncThunk(
  "verify-agreement/accreditation/get-inv-accrediation-status/getInvAccrediationStatusDetails",
  async (data: IGetInvAccrediationStatusPayload) => {
    try {
      const response = await getInvAccrediationStatusService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "verify-agreement/accreditation/get-inv-accrediation-status/getInvAccrediationStatusDetails",
  initialState,
  reducers: {
    resetStateForGetInvAccrediationStatusDetails(state) {
      state.data = {
        accreddDocumentLink: "",
        accreddTransactionID: "",
        accreddDocument: "",
      };
    },
  },
  extraReducers(builder): void {
    builder.addCase(getInvAccrediationStatusDetailsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
      state.isDisableGetAccredButton = true;
      state.data = {
        accreddDocumentLink: "",
        accreddTransactionID: "",
        accreddDocument: "",
      };
    });
    builder.addCase(
      getInvAccrediationStatusDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.data = action.payload._response.data.dataresult[0];
        state.output = action.payload._response.data.output
          ? action.payload._response.data.output.details[0]
          : "";
        state.isDisableGetAccredButton = false;
        state.accredStatus = action.payload._response.message;
      }
    );
    builder.addCase(
      getInvAccrediationStatusDetailsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
        state.isDisableGetAccredButton = false;
      }
    );
  },
});

export const { resetStateForGetInvAccrediationStatusDetails } = slice.actions;

export default slice.reducer;
