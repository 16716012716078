import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import getAssetResponseStatusReducer from "../store/getasset/slice";
import getInvRegistrationReducer from "./3stepVerification/getInvReg/slice";
import mapReducer from "./Googlemap/slice";
import PortfolioInvestmentDropdownReducer from "./Investment/dropdown/slice";
import InvestmentReducer from "./Investment/slice";
import addUpdateInvestorCumIAInfoReducer from "./Investor/investor-approval/add-update-investor-cum-ia-Info/slice";
import getLookUpbyAccountIdReducer from "./Investor/investor-approval/get-lookup-by-accountId/slice";
import getStepStatusReducer from "./Investor/investor-approval/get-step-status/slice";
import getInvestingAccountsLookupsReducer from "./Investor/investor-approval/investing-accounts-lookups/slice";
import getAccrediationLetterReducer from "./Investor/investor-approval/verify-agreement/accreditation/get-accrediation-letter/slice";
import getEmbedUIAcreddLinkReducer from "./Investor/investor-approval/verify-agreement/accreditation/get-embed-ui-acredd-link/slice";
import getInvAccrediationStatusDetailsReducer from "./Investor/investor-approval/verify-agreement/accreditation/get-inv-accrediation-status/slice";
import getUploadedAccredDocumentLinkReducer from "./Investor/investor-approval/verify-agreement/accreditation/get-uploaded-accred-document-link/slice";
import uploadAcrredFileReducer from "./Investor/investor-approval/verify-agreement/accreditation/upload-acrred-file-link/slice";
import getIAEnrollmentDataReducer from "./Investor/investor-approval/verify-agreement/digi-signer/get-ia-enrollment-data/slice";
import getESignDocumentReducer from "./Investor/investor-approval/verify-agreement/e-sign-document/slice";
import getDocumentLinkSignatureDualSignerReducer from "./Investor/investor-approval/verify-agreement/get-document-link-signature-dual-signer/slice";
import AddUpdateIAEnrollentInfoReducer from "./Investor/investor-approval/verify-agreement/investing-account/add-update-ia-enrollent-Info/slice";
import deleteInvestingAccountDataReducer from "./Investor/investor-approval/verify-agreement/investing-account/delete-investing-account-data/slice";
import getInvestingAccountDetailsByIdReducer from "./Investor/investor-approval/verify-agreement/investing-account/get-investing-account-details/slice";
import isAccountNickNameforInvestorExistsReducer from "./Investor/investor-approval/verify-agreement/investing-account/is-account-nick-name-for-Investor-exists/slice";
import getInvestorProfileDetailsReducer from "./Investor/investor-approval/verify-profile/get-data/slice";
import addInvestorProfileReducer from "./Investor/investor-approval/verify-profile/post-data/slice";
import addUpdateInvestorInviteReducer from "./Investor/invite-investor/add-update-investor-invite/slice";
import approveDeleteInvestorInviteReducer from "./Investor/invite-investor/approve-delete-investor-invite/slice";
import getInvestorInviteListReducer from "./Investor/invite-investor/get-investor-invite-list/slice";
import GetFeeListReducer from "./Investor/setupinvestorfees/getinvestorfees/slice";
import GetNonRegisteredInvestorListReducer from "./Investor/setupinvestorfees/getnonregisteredinvestorlist/slice";
import inverstorListReducer from "./Investor/slice";
import addUpdateManagerFeesConfigReducer from "./ManagerFees";
import getManagerFeesTypesReducer from "./ManagerFees/manager-fees-type";
import TransactionReducer from "./Transaction/slice";

import PortfolioAssetDropdownReducer from "./assetdropdown/slice";
import b2cReducer from "./b2c/slice";
import GetDashboardInfoReducer from "./dashboard/dashboardmanagement/slice";
import FundBankReducer from "./dwolla/bank/slice";
import DwollaCustomerInfoReducer from "./dwolla/customer/slice";
import DocumentDwollacustomerReducer from "./dwolla/document-dwollacustomer";
import DwollaDocumentTypeReducer from "./dwolla/dwolla-document-type";
import ToFundListReducer from "./dwolla/fundtotransfer/slice";
import FromFundListReducer from "./dwolla/fundtransfer/slice";
import SetupFundentityOwnersReducer from "./dwolla/owneraccount/slice";
import SetupFundentityReducer from "./dwolla/slice";
import DwollaTokenRedcure from "./dwolla/token/slice";
import DwollaWebhookReducer from "./dwolla/webhooks/slice";
import GetRoleListReducer from "./features/accountatatement/rolemanagement/managerole/getmanagerole/slice";
import GetDynamicUserListReducer from "./features/accountatatement/rolemanagement/managesubscription/getdynamicuserlist/slice";
import GetNotificationListResucer from "./features/accountatatement/rolemanagement/managesubscription/getnotificationlist/slice";
import BindUserInformationReducer from "./features/accountatatement/rolemanagement/manageuser/binduser/slice";
import ManageUserListReducer from "./features/accountatatement/rolemanagement/manageuser/getmanageuserlist/slice";
import getInfoByEmailReducer from "./features/accountatatement/rolemanagement/manageuser/infobyemail/slice";
import checkRoleAccessExistReducer from "./features/accountatatement/rolemanagement/manageuseraccess/check-role-access/slice";
import GetUserListDetailsReducer from "./features/accountatatement/rolemanagement/manageuseraccess/getuserdetails/slice";
import GetCopyRoleListReducer from "./features/accountatatement/rolemanagement/role-capability/getcopyrolelist/slice";
import GetCapabilityMenuListReducer from "./features/accountatatement/rolemanagement/role-capability/getmenulist/slice";
import getAccountStatementReducer from "./features/accountatatement/slice";
import GetDailyAccountValueReducer from "./features/accounting/dailyamu/getdailyaccountvalue/slice";
import GetEndDateReducer from "./features/accounting/dailyamu/getstartdate/slice";
import ProcessDailyAccountReducer from "./features/accounting/dailyamu/processdailyaccount/slice";
import GetGPPartnerReducer from "./features/accounting/debitcreditinvestoraccounts/GetGPPartner/slice";
import ManagerFeePayoutCUDReducer from "./features/accounting/debitcreditinvestoraccounts/ManagerFeePayoutCUD/slice";
import GetLPPartnersByInvestmentIdReducer from "./features/accounting/debitcreditinvestoraccounts/lppartnerbyinvestment/slice";
import GetTransactionTypeReducer from "./features/accounting/debitcreditinvestoraccounts/transactiontype/slice";
import CalculateFeeDetailsReducer from "./features/accounting/distributemanagementees/calculatefeedetails/slice";
import GetFeeDetailsReducer from "./features/accounting/distributemanagementees/getfeedetails/slice";
import GetTotalYearMonthExpensesReducer from "./features/accounting/fundlevelexpences/gettotalmonthexpence/slice";
import GetYearMonthExpensesListReducer from "./features/accounting/fundlevelexpences/getyearmonthexpenselist/slice";
import GetReconciliationListReducer from "./features/accounting/getreconciliationlist/slice";
import GetReconciliationPopupReducer from "./features/accounting/getreconciliationpopup/slice";
import ManageFeeCUDReducer from "./features/accounting/managefeecud/slice";
import DailyAccountProcessListReducer from "./features/accounting/reversedailyaum/dailyaccountprocessList/slice";
import ReverseDailyAccountProcessReducer from "./features/accounting/reversedailyaum/reversedailyaccountprocess/slice";
import GetAdminMenuUsersDetailsReducer from "./features/admin/getadminmenuusersdetails/slice";
import chatBotReducer from "./features/chat-bot-state/slice";
import ColorReducer from "./features/color/slice";
import SoftCommitListReducer from "./features/convert-deal-to-investment/list/slice";
import DropdownListReducer from "./features/dropdown/dropdown-list/slice";
import InvestmentReturnTypeReducer from "./features/dropdown/investmentReturnType/slice";
import LookupDropDownRedcure from "./features/dropdown/lookup-code/slice";
import NonRegisterInvestorReducer from "./features/dropdown/nonregisteredinvestorlist/slice";

import ProductListReducer from "./features/dropdown/productlist/slice";
import RegisterInvestorReducer from "./features/dropdown/registeredinvestorlist/slice";
import LegalEntityListReducer from "./features/dropdown/slice";
import SupplierListReducer from "./features/dropdown/supplier/slice";
import ExecuteEsignCommitmentReducer from "./features/e-sign/execute-e-sign/commitment-dropdown/slice";
import ExcuteEsignReducer from "./features/e-sign/execute-e-sign/execute-agreements/slice";
import GetTemplateByProductReducer from "./features/e-sign/execute-e-sign/get-template-by-productId/slice";
import SendExecuteEsignReducer from "./features/e-sign/execute-e-sign/send-email/slice";
import ExecuteEsignStatusReducer from "./features/e-sign/execute-e-sign/status-dropdown/slice";
import ViewSignDocumentReducer from "./features/e-sign/execute-e-sign/view-sign-document/slice";
import AgreementDropDownReducer from "./features/e-sign/setup-e-sign/agreement-dropdown/slice";
import SetupESignReducer from "./features/e-sign/setup-e-sign/setup-list/slice";
import GetPrivateFilesFileManagerReducer from "./features/filemanager/list/slice";
import GetPublicFilesFileManagerReducer from "./features/filemanager/publicdocumentlist/slice";
import isFundExistReducer from "./features/fund-exist/slice";
import getGAAPAccountingBalanceSheetReducer from "./features/gaap-accounting/get-gaap-accounting-balance-sheet/slice";
import getGAAPReconciliationListReducer from "./features/gaap-accounting/get-gaap-accounting-monthly-reconciliation/slice";
import getGAAPAccountingProfitLossReducer from "./features/gaap-accounting/get-gaap-accounting-profit-loss/slice";
import getReportTypeListReducer from "./features/gaap-accounting/get-report-type-list/slice";
import InvestorDetailsForAdminReducer from "./features/get-investor-details-for-admin/slice";
import AllUsersListReducer from "./features/getallusers/slice";
import getAzureLoginHistoryReducer from "./features/getazureloginhistory/slice";
import DashboardMenusReducer from "./features/home/get-dashboard-menus/slice";
import AddUpdateFundReducer from "./features/investment/addupdatesupplier/slice";
import ListInvestmentByUserId from "./features/investment/all-list/slice";
import AddInvestmentReducer from "./features/investment/create/slice";
import DeleteSupplierReducer from "./features/investment/deletesupplier/slice";
import GetSupplierListReducer from "./features/investment/getsupplierlist/slice";
import GetSupplierTypesReducer from "./features/investment/getsuppliertypes/slice";
import InvestmnentFilterByIdReducer from "./features/investment/investment-filter/investment-filter-id/slice";
import InvestmentByFilterReducer from "./features/investment/investment-filter/slice";
import GetInvestmentsReducer from "./features/investment/list/slice";
import NewInvestmentReducer from "./features/investment/new-investment";
import ProjectUpdateLogReducer from "./features/investment/update/project-update/slice";
import AddSectionReducer from "./features/investment/update/section/create/slice";
import ListSectionReducer from "./features/investment/update/section/list/slice";
import SideNavPanelReducer from "./features/investment/update/side-nav/slice";
import UpdatePageReducer from "./features/investment/update/update-page/slice";
import UplodedFilesReducer from "./features/investment/upload-file/slice";
import deleteInvestorReducer from "./features/investor/delete-investor/slice";
import getStatusAndFlagReducer from "./features/investor/get-investor-status/slice";
import GetInvestorDocumentListReducer from "./features/investor/manage-investor-document/investor-document-list/slice";
import InvestorDocumentTypeDropdownReducer from "./features/investor/manage-investor-document/investor-document-type/slice";
import GetCountriesReducer from "./features/investor/managecountrymaster/getcountries/slice";
import UpdateCountryStatusReducer from "./features/investor/managecountrymaster/updatecountrystatus/slice";
import GetInvestorListReducer from "./features/investor/slice";
import GetCustodianListReducer from "./features/master/getcustodianlist/slice";
import GetMenuListStateReducer from "./features/master/getmenulist/slice";
import GetParentMenuListReducer from "./features/master/getparentmenulist/slice";
import GetStateListReducer from "./features/master/getstatelist/slice";
import SaveMenuDetailsReducer from "./features/master/savemenudetails/slice";
import GetNotificationCountReducer from "./features/notification/get-notification-count/slice";
import AddInvestmentReturnReducer from "./features/operation/allocate-earning/add-investment-return/slice";
import AddInvestmentReturnPooledFundReducer from "./features/operation/allocate-earning/add-investment-return-continuous";
import GetCalculateEarningReducer from "./features/operation/allocate-earning/calculate-earnings/slice";
import GetCalculateEarningPooledFundReducer from "./features/operation/allocate-earning/calculate-earnings-pooledfund";
import GetAllocateEarningDataReducer from "./features/operation/allocate-earning/get-earning-data/slice";
import GetAllocateEarningDataPooledFundReducer from "./features/operation/allocate-earning/get-earning-data-pooledfund";
import GetInvestmentIdByProductReducer from "./features/operation/allocate-earning/get-investment-id-product/slice";
import AllocationSliceInvestorReducer from "./features/operation/allocation-slices/add-investors-allocation-state/slice";
import AddUpdateAllocationReducer from "./features/operation/allocation-slices/create-update-allocation/slice";
import UpdateAllocationSliceStateReducer from "./features/operation/allocation-slices/edit-investor-allocation-state/slice";
import GetAllInvestorAllocationReducer from "./features/operation/allocation-slices/get-all-investor-allocation/slice";
import GetAllocatedInvestorListReducer from "./features/operation/allocation-slices/get-allocated-investor/slice";
import ReverseAllocationSliceReducer from "./features/operation/allocation-slices/reverse-allocation-slice/slice";
import AllocationSlicesReducer from "./features/operation/allocation-slices/slice";
import GetAllExpensesListReducer from "./features/operation/distributeexpensestoinvestors/getallexpenseslist/slice";
import GetProRataListReducer from "./features/operation/distributeexpensestoinvestors/getproratalist/slice";
import FundDocumentListReducer from "./features/operation/manage-fund-documents/fund-document-list/slice";
import FundDocumentTypeDropdownRwducer from "./features/operation/manage-fund-documents/fund-document-type/slice";
import GetEarningsDataReducer from "./features/operation/reverse-earning-allocation/get-earnings-data/slice";
import GetEarningsDataContinuoustReducer from "./features/operation/reverse-earning-allocation/get-earnings-data-continuous";
import GetEarningEntriesReducer from "./features/operation/reverse-earning-allocation/get-earnings-entries/slice";
import GetLastInvestmentReturnIdReducer from "./features/operation/reverse-earning-allocation/last-investment-return-id/slice";
import GetEarningTransactionsReducer from "./features/operation/searchearningstransactions/getearningtransactions/slice";
import GetInvoiceCustomerSubscriptionDetailsReducer from "./features/operation/sendusageunitsforInvoicing/getInvoicecustomersubscriptiondetails/slice";
import GetInvoiceDAVReducer from "./features/operation/sendusageunitsforInvoicing/getinvoiceDAV/slice";
import AddBankMailInfoReducer from "./features/operation/setup-fund/add-bank-mail-info/slice";
import AddFundProfileReducer from "./features/operation/setup-fund/add-fund-profile/slice";
import AddGPProfileReducer from "./features/operation/setup-fund/add-gp-profile/slice";
import AddThirdPartyApiReducer from "./features/operation/setup-fund/add-third-party-api/slice";
import checkGPEMailExistReducer from "./features/operation/setup-fund/check-gpmail-exist/slice";
import CheckFundSlugReducer from "./features/operation/setup-fund/check-slug-exits/slice";
import AddCancelSetupStateReducer from "./features/operation/setup-fund/create-update-state/slice";
import GetFundTypeDropdownReducer from "./features/operation/setup-fund/fund-type-dropdown/slice";
import GetSetupFundEntityListReducer from "./features/operation/setup-fund/get-setup-entity-list/slice";
import GetSetupFundInfoReducer from "./features/operation/setup-fund/get-setup-fund/slice";
import GetEdgarReportListReducer from "./features/operation/setup-fund/setup-fund-edgar-report/slice";
import stateCheckSetupFundReducer from "./features/operation/setup-fund/setup-fund-state-check/slice";
import setupFundTabNameReducer from "./features/operation/setup-fund/setup-fund-tabname/slice";
import ProfileReducer from "./features/profile/slice";
import GetSponsorRegulatoryDashboardDetailsReduceer from "./features/reports/Getsponsorregulatorydashboarddetails/slice";
import InvestorsinFundReducer from "./features/reports/Investors-in-Fund/slice";
import OutstandingBlueSkyFilingReducer from "./features/reports/Outstanding-Bluesky-Filings/slice";
import SetupFundReducer from "./features/reports/Setup-Fund-Report/slice";
import AnnualizedReturnsCompletedReducer from "./features/reports/annualized-returns-completed/slice";
import BlueSkyFilingReducer from "./features/reports/blue-sky-filing/slice";
import ConsolidatedPlatformReducer from "./features/reports/consolidated-platfrom//slice";
import CumulativeEarningsReducer from "./features/reports/cumulative-earning/slice";
import EarningsErrataReducer from "./features/reports/earnings-errata-report/slice";
import GetApprovedAccreddInvestorsForAllFundsReducer from "./features/reports/get-approved-accredd-investors-for-allFunds/slice";
import GetCurrentOpportunitiesReducer from "./features/reports/get-current-opportunities/slice";
import GetConsolidatedDashboardDetailsReducer from "./features/reports/getconsolidateddashboarddetails/slice";
import GetInvestorsVerifiedByAccreddReducer from "./features/reports/getinvestors-verified-by-accredd/slice";
import InvestmentAllocationReducer from "./features/reports/investment-allocation-report/slice";
import InvestmentreturnsBySupplierReducer from "./features/reports/investment-returns-by-supplier-report/slice";
import InvestorAssetsReducer from "./features/reports/investor-assets/slice";
import InvestorLoginHistoryReducer from "./features/reports/investor-login-history/slice";
import MissedExitDateReducer from "./features/reports/late-investment-exits/slice";
import SearchInvestorEmployeeReducer from "./features/reports/search-investor-employee/slice";
import SoftCommitsReducer from "./features/reports/soft-commits/slice";
import GetManageTransactionReducer from "./features/reports/transaction-reports/all-transaction/slice";
import sideBarStateReducer from "./features/sidebar/slice";
import BindBlueSkyStateDropdownReducer from "./features/sponser/manage-bluesky-filings/bluesky-state-dropdown/slice";
import BlueSkyFilingPendingStateDataExistReducer from "./features/sponser/manage-bluesky-filings/blueskyfilingpendingstatedataexist/slice";
import CheckStateFilingFrequencyReducer from "./features/sponser/manage-bluesky-filings/checkstatefilingfrequency/slice";
import DeleteBlueSkyFilingDataReducer from "./features/sponser/manage-bluesky-filings/deleteblueskyfilingdata/slice";
import GetBlueSkyFillingDetailsReducer from "./features/sponser/manage-bluesky-filings/getblueskyfillingdetails/slice";
import SaveBlueskyFilingDataReducer from "./features/sponser/manage-bluesky-filings/saveblueskyfilingdata/slice";
import UpdateBlueskyFilingDataReducer from "./features/sponser/manage-bluesky-filings/updateblueskyfilingdata/slice";
import AddHelpVideoLinkReducer from "./features/sponser/manage-help-videos/addhelpvideolink/slice";
import deleteHelpVideoReducer from "./features/sponser/manage-help-videos/delete-help-video/slice";
import DisableEnableMenuLinkReducer from "./features/sponser/manage-help-videos/disableenablemenulink/slice";
import HelpVedioGetVideoLinkReducer from "./features/sponser/manage-help-videos/getvideolink/slice";
import HelpVedioSubMenuDropdownReducer from "./features/sponser/manage-help-videos/helpvediosubmenudropdown/slice";
import GetInvestorIncomeListReducer from "./features/taxmanagement/1099/InterestIncomeByInvestor/getinvestorincomelist/slice";
import inputForm1099DivReducer from "./features/taxmanagement/1099/input-form-1099/input-1099-div/1099-div-list/slice";
import InputForm1099ListReducer from "./features/taxmanagement/1099/input-form-1099/input-form-list/slice";
import SupplierDropdownListReducer from "./features/taxmanagement/1099/input-form-1099/supplier-dropdown/slice";
import GetFundLevelReconcileIncomeReducer from "./features/taxmanagement/1099/interestincomeatfundlevel/getfundlevelreconcileincome/slice";
import BindRevisionListReducer from "./features/taxmanagement/Revision/bindrevisionlist/slice";
import GetCapitalLossDetailsReducer from "./features/taxmanagement/baddebtexpenses/getcapitallossdetails/slice";
import GetcalculateExpensesReducer from "./features/taxmanagement/fundexpensesproratacalculation/getcalculateexpenses/slice";
import GetK1GenDetailsReducer from "./features/taxmanagement/generation/getk1gendetails/slice";
import GetPartnerCapitalAccCalReducer from "./features/taxmanagement/getpartnercapitalacccal/slice";
import GetProdductListReducer from "./features/taxmanagement/getpartnerprodatabyinvestment/getproductlist/slice";
import GetPartnerProDataByInvestmentReducer from "./features/taxmanagement/getpartnerprodatabyinvestment/slice";
import GetRevisionListReducer from "./features/taxmanagement/getrevisionliist/slice";
import GetK1InputSupplierLabelReducer from "./features/taxmanagement/k1-input-supplier/get-label-form/slice";
import AddK1InputSupplierFormReducer from "./features/taxmanagement/k1-input-supplier/save-form/slice";
import GetK1ReconcilationInfoReducer from "./features/taxmanagement/k1-reconcilationreport/getk1reconcilationinfo/slice";
import GetK1ReconciliationByInvestmentReducer from "./features/taxmanagement/k1reconcilationinvestment/getfundinvestmentsreport/slice";
import K1ReconciliationByInvestorReducer from "./features/taxmanagement/k1reconciliationbyinvestot/k1reconciliationbyinvestor/slice";
import K1ReconciliationLPBreakdownReducer from "./features/taxmanagement/lplevelbreakdown/k1reconciliationlpbreakdown/slice";
import GetLPWiseManagementListReducer from "./features/taxmanagement/managefeespaid/getLPwisemanagementlist/slice";
import GetAllocatedInvestmentReducer from "./features/taxmanagement/ownershipbyproductdata/getallocatedinvestment/slice";
import GetLPOwnershipDataReducer from "./features/taxmanagement/ownershipchangereport/slice";
import GetK1PartnerInformationReducer from "./features/taxmanagement/partnerinformation/getk1partnerinformation/slice";
import GetPartnerLevelCashDistributionsDetailsReducer from "./features/taxmanagement/pro-ratacashdistributions/getpartnerlevelcashdistributionsdetails/slice";
import GetSummaryDetailsReducer from "./features/taxmanagement/summaryreportbypartner/getsummarydetails/slice";
import GetUploadInvestorK1ListReducer from "./features/taxmanagement/upload-investork1/k1-document-list/slice";
import UploadK1sRevisionDropdownReducer from "./features/taxmanagement/upload-investork1/revision-dropdown/slice";
import GetViewDocumentInvestorK1sReducer from "./features/taxmanagement/upload-investork1/view-k1-document/slice";

import UserMenuReducer from "./features/user-menu/slice";
import CheckEmailExitViewGatherSoftCommitReducer from "./features/view-gather-softcommit/check-email-exist/slice";
import AddViewGatherSoftCommitReducer from "./features/view-gather-softcommit/create-softcommit/slice";
import InvestorBasicInfoReducer from "./features/view-gather-softcommit/investor-basic-info/slice";
import InvestorListReducer from "./features/view-gather-softcommit/investor-list-dropdown/slice";
import InvestorTypeReducer from "./features/view-gather-softcommit/investortype-dropdown/slice";
import ListViewGatherSoftCommitReducer from "./features/view-gather-softcommit/list-softcommits/slice";
import getPartnerInvestorInfoReducer from "./finance/getPartnerInvestorInfo/slice";
import getCustomerPlaidTypeReducer from "./finance/plaid/getCustomerType/slice";
import PlaidLinkTokenReducer from "./finance/plaid/getPlaidLinkToken/slice";
import TransactionReportReducer from "./finance/transaction/getWirelessTransaction/slice";
import isAccountExistReducer from "./multi-fund-registration/IsFundAccountExist/slice";
import navbardropdownReducer from "./navdropdown/slice";
import opportunityImageDataReducer from "./opportunity/image-data/slice";
import opportunityinvestmentDetailsReducer from "./opportunity/opportunity-investment-details/slice";
import opportunityProjectDetailsReducer from "./opportunity/project-details/slice";
import opportunityProjectUpdateReducer from "./opportunity/project-update/slice";
import opportunityRelevantDocumentsReducer from "./opportunity/relevant-documents/slice";
import opportunityReducer from "./opportunity/slice";
import addNewPoolfundsUnitsReducer from "./poolfunds/add-new-poolfunds-units";
import AddPoolFundsReducer from "./poolfunds/add-pool-funds-state";
import EditPoolFundReducer from "./poolfunds/edit-pool-funds-slice";
import exitPoolFundReducer from "./poolfunds/exit-pool-funds";
import getAfterEarningStakeByinvestmentReducer from "./poolfunds/get-after-earning-stake-byinvestment";
import getAllInvestorPoolFundReducer from "./poolfunds/get-all-Investor-list-pool-funds";
import getAllAllocatedInvesterPoolFundReducer from "./poolfunds/get-allocatedinvestors-Investment-poolfunds";
import getAllInvestorDealListPoolFundsReducer from "./poolfunds/get-investmentdeal-for-pooledfund";
import PortfolioReducer from "./portfolio/slice";
import questReducer from "./quest/slice";
import getSasKeyReducer from "./sas-key/slice";

const store = configureStore({
  reducer: {
    color: ColorReducer,
    b2c: b2cReducer,
    userMenu: UserMenuReducer,
    opportunityDetails: opportunityinvestmentDetailsReducer,
    Map: mapReducer,
    isFundExist: isFundExistReducer,
    profile: ProfileReducer,
    missedExitDate: MissedExitDateReducer,
    softCommit: SoftCommitsReducer,
    consolidatedPlatform: ConsolidatedPlatformReducer,
    investorsinFund: InvestorsinFundReducer,
    getInvRegistration: getInvRegistrationReducer,
    opportunityRelevantDocuments: opportunityRelevantDocumentsReducer,
    legalEntityList: LegalEntityListReducer,
    BlueSkyFiling: BlueSkyFilingReducer,
    CumulativeEarning: CumulativeEarningsReducer,
    SetupFund: SetupFundReducer,
    OutstandingBlueSkyFiling: OutstandingBlueSkyFilingReducer,
    sideBarState: sideBarStateReducer,
    earningsErrataReducer: EarningsErrataReducer,
    investorAssets: InvestorAssetsReducer,
    InvestorLoginHistory: InvestorLoginHistoryReducer,
    GetCurrentOpportunities: GetCurrentOpportunitiesReducer,
    annualizedReturnsCompleted: AnnualizedReturnsCompletedReducer,
    SearchInvestorEmployee: SearchInvestorEmployeeReducer,
    AllUsersList: AllUsersListReducer,
    GetInvestorsVerifiedByAccredd: GetInvestorsVerifiedByAccreddReducer,
    GetApprovedAccreddInvestorsForAllFunds:
      GetApprovedAccreddInvestorsForAllFundsReducer,
    productList: ProductListReducer,
    investmentReturnType: InvestmentReturnTypeReducer,
    investmentreturnsBySupplier: InvestmentreturnsBySupplierReducer,
    investmentAllocation: InvestmentAllocationReducer,
    getAccountStatement: getAccountStatementReducer,
    InvestorDetailsForAdmin: InvestorDetailsForAdminReducer,
    portfolio: PortfolioReducer,
    portfolioInvestment: InvestmentReducer,
    PortfolioInvestmentDropdown: PortfolioInvestmentDropdownReducer,
    portfolioTransaction: TransactionReducer,
    opportunityProjectDetails: opportunityProjectDetailsReducer,
    opportunityImageData: opportunityImageDataReducer,
    opportunityProjectUpdate: opportunityProjectUpdateReducer,
    inverstorList: inverstorListReducer,
    opportunity: opportunityReducer,
    nonregisterinvestor: NonRegisterInvestorReducer,
    registerinvestor: RegisterInvestorReducer,
    navbardropdown: navbardropdownReducer,
    supplierList: SupplierListReducer,
    addInvestment: AddInvestmentReducer,
    dropdownList: DropdownListReducer,
    GetInvestorList: GetInvestorListReducer,
    GetSupplierTypes: GetSupplierTypesReducer,
    AddUpdateFund: AddUpdateFundReducer,
    GetSupplierList: GetSupplierListReducer,
    DeleteSupplier: DeleteSupplierReducer,
    GetCountries: GetCountriesReducer,
    UpdateCountryStatus: UpdateCountryStatusReducer,
    GetMenuList: GetMenuListStateReducer,
    SaveMenuDetails: SaveMenuDetailsReducer,
    uplodedFiles: UplodedFilesReducer,
    GetParentMenuList: GetParentMenuListReducer,
    GetBlueSkyFillingDetails: GetBlueSkyFillingDetailsReducer,
    BindBlueSkyStateDropdown: BindBlueSkyStateDropdownReducer,
    SaveBlueskyFilingData: SaveBlueskyFilingDataReducer,
    DeleteBlueSkyFilingData: DeleteBlueSkyFilingDataReducer,
    UpdateBlueskyFilingData: UpdateBlueskyFilingDataReducer,
    HelpVedioSubMenuDropdown: HelpVedioSubMenuDropdownReducer,
    HelpVedioGetVideoLink: HelpVedioGetVideoLinkReducer,
    DisableEnableMenuLink: DisableEnableMenuLinkReducer,
    AddHelpVideoLink: AddHelpVideoLinkReducer,
    CheckStateFilingFrequency: CheckStateFilingFrequencyReducer,
    BlueSkyFilingPendingStateDataExist:
      BlueSkyFilingPendingStateDataExistReducer,
    deleteInvestor: deleteInvestorReducer,
    getInvestment: GetInvestmentsReducer,
    getPrivateFiles: GetPrivateFilesFileManagerReducer,
    getPublicFiles: GetPublicFilesFileManagerReducer,
    GetFeeList: GetFeeListReducer,
    GetNonRegisteredInvestorList: GetNonRegisteredInvestorListReducer,
    addSection: AddSectionReducer,
    listSection: ListSectionReducer,
    getListInvestmentsUserId: ListInvestmentByUserId,
    GetTransactionType: GetTransactionTypeReducer,
    updatePage: UpdatePageReducer,
    GetRoleList: GetRoleListReducer,
    ManageUserList: ManageUserListReducer,
    GetCopyRoleList: GetCopyRoleListReducer,
    GetCapabilityMenuList: GetCapabilityMenuListReducer,
    BindUserInformation: BindUserInformationReducer,
    GetUserListDetails: GetUserListDetailsReducer,
    GetDynamicUserList: GetDynamicUserListReducer,
    GetNotificationList: GetNotificationListResucer,
    GetYearMonthExpensesList: GetYearMonthExpensesListReducer,
    GetTotalYearMonthExpenses: GetTotalYearMonthExpensesReducer,
    sideNavPanel: SideNavPanelReducer,
    softCommitList: SoftCommitListReducer,
    dwollasetupFundentity: SetupFundentityReducer,
    setupFundentityOwnersReducer: SetupFundentityOwnersReducer,
    investmentByFilter: InvestmentByFilterReducer,
    lookupDropDown: LookupDropDownRedcure,
    dwollaToken: DwollaTokenRedcure,
    dwollaCustomerInfo: DwollaCustomerInfoReducer,
    setupESign: SetupESignReducer,
    GetRevisionList: GetRevisionListReducer,
    fundBank: FundBankReducer,
    plaidToken: PlaidLinkTokenReducer,
    GetK1PartnerInformation: GetK1PartnerInformationReducer,
    GetK1GenDetails: GetK1GenDetailsReducer,
    agreementDropDown: AgreementDropDownReducer,
    executeEsignStatus: ExecuteEsignStatusReducer,
    executeEsignCommitment: ExecuteEsignCommitmentReducer,
    getExecuteEsign: ExcuteEsignReducer,
    GetPartnerLevelCashDistributionsDetails:
      GetPartnerLevelCashDistributionsDetailsReducer,
    sendExecuteEsign: SendExecuteEsignReducer,
    viewSignDocument: ViewSignDocumentReducer,
    BindRevisionList: BindRevisionListReducer,
    investorType: InvestorTypeReducer,
    investorList: InvestorListReducer,
    addViewGatherSoftCommit: AddViewGatherSoftCommitReducer,
    listViewGatherSoftCommit: ListViewGatherSoftCommitReducer,
    investorBasicInfo: InvestorBasicInfoReducer,
    GetProdductList: GetProdductListReducer,
    GetAllocatedInvestment: GetAllocatedInvestmentReducer,
    investingAccountsLookups: getInvestingAccountsLookupsReducer,
    getLookUpbyAccountId: getLookUpbyAccountIdReducer,
    getInvestorProfileDetails: getInvestorProfileDetailsReducer,
    getESignDocument: getESignDocumentReducer,
    getUploadedAccredDocumentLink: getUploadedAccredDocumentLinkReducer,
    getInvAccrediationStatusDetails: getInvAccrediationStatusDetailsReducer,
    getEmbedUIAcreddLink: getEmbedUIAcreddLinkReducer,
    uploadAcrredFile: uploadAcrredFileReducer,
    getInvestingAccountDetailsById: getInvestingAccountDetailsByIdReducer,
    getStepStatus: getStepStatusReducer,
    isAccountNickNameforInvestorExists:
      isAccountNickNameforInvestorExistsReducer,
    addInvestorProfile: addInvestorProfileReducer,
    GetLPWiseManagementList: GetLPWiseManagementListReducer,
    GetPartnerProDataByInvestment: GetPartnerProDataByInvestmentReducer,
    GetLPOwnershipData: GetLPOwnershipDataReducer,
    GetPartnerCapitalAccCal: GetPartnerCapitalAccCalReducer,
    GetK1ReconcilationInfo: GetK1ReconcilationInfoReducer,
    GetcalculateExpenses: GetcalculateExpensesReducer,
    GetCapitalLossDetails: GetCapitalLossDetailsReducer,
    K1ReconciliationLPBreakdown: K1ReconciliationLPBreakdownReducer,
    GetK1ReconciliationByInvestment: GetK1ReconciliationByInvestmentReducer,
    K1ReconciliationByInvestor: K1ReconciliationByInvestorReducer,
    GetSummaryDetails: GetSummaryDetailsReducer,
    inputform1099List: InputForm1099ListReducer,
    supplierDropdownList: SupplierDropdownListReducer,
    getUploadInvestorK1List: GetUploadInvestorK1ListReducer,
    viewDocumentInvestorK1s: GetViewDocumentInvestorK1sReducer,
    AddUpdateIAEnrollentInfo: AddUpdateIAEnrollentInfoReducer,
    GetFundLevelReconcileIncome: GetFundLevelReconcileIncomeReducer,
    GetInvestorIncomeList: GetInvestorIncomeListReducer,
    revisionDropdown: UploadK1sRevisionDropdownReducer,
    investorDocumentList: GetInvestorDocumentListReducer,
    investorDocumentTypeDropdown: InvestorDocumentTypeDropdownReducer,
    fundDocumentList: FundDocumentListReducer,
    fundDocumentTypeDropdown: FundDocumentTypeDropdownRwducer,
    GetInvoiceCustomerSubscriptionDetails:
      GetInvoiceCustomerSubscriptionDetailsReducer,
    GetEndDate: GetEndDateReducer,
    GetDailyAccountValue: GetDailyAccountValueReducer,
    ProcessDailyAccount: ProcessDailyAccountReducer,
    GetEarningTransactions: GetEarningTransactionsReducer,
    DailyAccountProcessList: DailyAccountProcessListReducer,
    ReverseDailyAccountProcess: ReverseDailyAccountProcessReducer,
    getAccrediationLetter: getAccrediationLetterReducer,
    getIAEnrollmentData: getIAEnrollmentDataReducer,
    getDocumentLinkSignatureDualSigner:
      getDocumentLinkSignatureDualSignerReducer,
    GetFeeDetails: GetFeeDetailsReducer,
    CalculateFeeDetails: CalculateFeeDetailsReducer,
    getK1InputSupplierLabel: GetK1InputSupplierLabelReducer,
    addK1InputSupplierForm: AddK1InputSupplierFormReducer,
    GetInvoiceDAV: GetInvoiceDAVReducer,
    GetProRataList: GetProRataListReducer,
    GetAllExpensesList: GetAllExpensesListReducer,
    getInvestorInviteList: getInvestorInviteListReducer,
    approveDeleteInvestorInvite: approveDeleteInvestorInviteReducer,
    addUpdateInvestorInvite: addUpdateInvestorInviteReducer,
    fromFundList: FromFundListReducer,
    toFundList: ToFundListReducer,
    GetStateList: GetStateListReducer,
    GetCustodianList: GetCustodianListReducer,
    GetDashboardInfo: GetDashboardInfoReducer,
    transactionReport: TransactionReportReducer,
    getFundTypeDropdown: GetFundTypeDropdownReducer,
    addFundProfile: AddFundProfileReducer,
    getSetupFundInfo: GetSetupFundInfoReducer,
    PortfolioAssetDropdown: PortfolioAssetDropdownReducer,
    getSetupFundEntityList: GetSetupFundEntityListReducer,
    addGPProfile: AddGPProfileReducer,
    addBankMailInfo: AddBankMailInfoReducer,
    checkFundSlug: CheckFundSlugReducer,
    GetSponsorRegulatoryDashboardDetails:
      GetSponsorRegulatoryDashboardDetailsReduceer,
    GetConsolidatedDashboardDetails: GetConsolidatedDashboardDetailsReducer,
    getCustomerTypePlaid: getCustomerPlaidTypeReducer,
    getPartnerInvestorInfo: getPartnerInvestorInfoReducer,
    addUpdateInvestorCumIAInfo: addUpdateInvestorCumIAInfoReducer,
    GetReconciliationList: GetReconciliationListReducer,
    GetReconciliationPopup: GetReconciliationPopupReducer,
    addCancelSetupState: AddCancelSetupStateReducer,
    addThirdPartyApi: AddThirdPartyApiReducer,
    getAllocatedEarningData: GetAllocateEarningDataReducer,
    getCalculateEarning: GetCalculateEarningReducer,
    getAssetResponseStatus: getAssetResponseStatusReducer,
    dwollaWebhook: DwollaWebhookReducer,
    deleteInvestingAccountData: deleteInvestingAccountDataReducer,
    addUpdateDeleteInvestmentReturn: AddInvestmentReturnReducer,
    investmentFilterById: InvestmnentFilterByIdReducer,
    edgarReportList: GetEdgarReportListReducer,
    getInvestmentIdByProduct: GetInvestmentIdByProductReducer,
    getEarningsData: GetEarningsDataReducer,
    getEarningsDataContinuous: GetEarningsDataContinuoustReducer,
    getStatusAndFlag: getStatusAndFlagReducer,
    GetAdminMenuUsersDetails: GetAdminMenuUsersDetailsReducer,
    getEarningEntries: GetEarningEntriesReducer,
    getLastInvestmentReturnId: GetLastInvestmentReturnIdReducer,
    allocationSlices: AllocationSlicesReducer,
    getAzureLoginHistory: getAzureLoginHistoryReducer,
    isAccountExist: isAccountExistReducer,
    getNotificationCount: GetNotificationCountReducer,
    getSasKey: getSasKeyReducer,
    checkEmailExistViewGatherCommit: CheckEmailExitViewGatherSoftCommitReducer,
    checkRoleAccessExist: checkRoleAccessExistReducer,
    chatBotState: chatBotReducer,
    dashboardMenus: DashboardMenusReducer,
    setupFundTabName: setupFundTabNameReducer,
    statecheckSetupFund: stateCheckSetupFundReducer,
    deleteHelpVideo: deleteHelpVideoReducer,
    getInfoByEmail: getInfoByEmailReducer,
    projectUpdateLog: ProjectUpdateLogReducer,
    GetLPPartnersByInvestmentId: GetLPPartnersByInvestmentIdReducer,
    allocationSliceInvestorState: AllocationSliceInvestorReducer,
    getAllInvestorAllocationData: GetAllInvestorAllocationReducer,
    addUpdateAllocation: AddUpdateAllocationReducer,
    getAllocatedInvestorList: GetAllocatedInvestorListReducer,
    updateAllocationSliceState: UpdateAllocationSliceStateReducer,
    GetManageFeeCUD: ManageFeeCUDReducer,
    checkGPEmailExist: checkGPEMailExistReducer,
    ManagerFeePayoutCUD: ManagerFeePayoutCUDReducer,
    GetGPPartner: GetGPPartnerReducer,
    AddPoolFunds: AddPoolFundsReducer,
    editPoolFunds: EditPoolFundReducer,
    getAllInvestorPoolFund: getAllInvestorPoolFundReducer,
    addNewPoolfundsUnits: addNewPoolfundsUnitsReducer,
    getAllAllocatedInvesterPoolFund: getAllAllocatedInvesterPoolFundReducer,
    managerFees: addUpdateManagerFeesConfigReducer,
    managerFeesTypes: getManagerFeesTypesReducer,
    exitPoolFund: exitPoolFundReducer,
    getAfterEarningStakeByinvestment: getAfterEarningStakeByinvestmentReducer,
    NewInvestment: NewInvestmentReducer,
    getAllInvestorDealListPoolFunds: getAllInvestorDealListPoolFundsReducer,
    addUpdateDeleteInvestmentReturnPooledFund:
      AddInvestmentReturnPooledFundReducer,
    getAllocatedEarningDataPooledFund: GetAllocateEarningDataPooledFundReducer,
    getCalculateEarningPooledfund: GetCalculateEarningPooledFundReducer,
    capitalQuest: questReducer,
    getTemplateByProduct: GetTemplateByProductReducer,
    getManageTransaction: GetManageTransactionReducer,
    reverseAllocationSlice: ReverseAllocationSliceReducer,
    documentDwollacustomer: DocumentDwollacustomerReducer,
    dwollaDocumentType: DwollaDocumentTypeReducer,
    getGAAPAccountingBalanceSheet: getGAAPAccountingBalanceSheetReducer,
    getGAAPAccountingProfitLoss: getGAAPAccountingProfitLossReducer,
    getReportTypeList: getReportTypeListReducer,
    inputForm1099Div: inputForm1099DivReducer,
    getGAAPReconciliationList: getGAAPReconciliationListReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
