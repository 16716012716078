import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../../../utils/date-formatter";

type CreateInvestmentStep7Props = {
  handlePrevious: () => void;
  handleNext: any;
  toast: any;
};
const CreateInvestmentStep7: React.FC<CreateInvestmentStep7Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const stateInvestment = useAppSelector((state) => state.addInvestment.data);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      targetRaiseAmt: undefined,
      targetCloseDate: "",
      excludeFromMarketing: undefined,
      showRemainingAllocation: undefined,
      isReinvest: undefined,
    },
    validate: (data) => {
      const errors: any = {};
      if (stateInvestment?.productState === "SoftCommit") {
        if (!data.targetRaiseAmt) {
          errors.targetRaiseAmt = "Target raise amount is required";
        } else if (!/^[+-]?(\d*\.)?\d+$/.test(data.targetRaiseAmt)) {
          errors.targetRaiseAmt = "Target raise amount should be a number";
        }

        if (!data.targetCloseDate) {
          errors.targetCloseDate = "Target close date is required";
        }
        if (data.targetRaiseAmt && data.targetRaiseAmt > 20_000_000_000) {
          errors.targetRaiseAmt =
            "The target raise amount should be less than equal to $20B";
        }
      }
      return errors;
    },
    onSubmit: () => {
      handleNext(stateInvestment);
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  const handleChangeState = () => {
    const targetCloseDate = convertLocalDateToUTC(
      formik.values.targetCloseDate
    );
    dispatch(
      setForm({
        ...stateInvestment,
        targetRaiseAmt: values.targetRaiseAmt,
        targetCloseDate,
        showRemainingAllocation: values.showRemainingAllocation,
        excludeFromMarketing: values.excludeFromMarketing,
        isReinvest: values.isReinvest,
      })
    );
  };
  useEffect(() => {
    setFieldValue("targetRaiseAmt", stateInvestment?.targetRaiseAmt);
    setFieldValue(
      "targetCloseDate",
      stateInvestment?.targetCloseDate === null ||
        stateInvestment?.targetCloseDate === ""
        ? stateInvestment?.targetCloseDate
        : convertUTCToLocalDate(stateInvestment?.targetCloseDate!)
    );
    setFieldValue(
      "excludeFromMarketing",
      stateInvestment?.excludeFromMarketing
    );
    setFieldValue(
      "showRemainingAllocation",
      stateInvestment?.showRemainingAllocation
    );
    setFieldValue(
      "isReinvest",
      stateInvestment?.isReinvest ? stateInvestment?.isReinvest : false
    );
  }, []);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {stateInvestment?.productState !== "SoftCommit" ? (
        <></>
      ) : (
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
            <label className="inputLabel">
              How much are you trying to raise?
            </label>
            <InputNumber
              name="targetRaiseAmt"
              value={
                values.targetRaiseAmt === 0 ? undefined : values.targetRaiseAmt
              }
              onValueChange={(e) => handleChange(e)}
              // maxLength={23}
              mode="currency"
              currency="USD"
              locale="en-US"
              // maxFractionDigits={2}
              className={classNames({
                "p-invalid": isFormFieldInvalid("targetRaiseAmt"),
                "w-100": true,
              })}
              placeholder="Enter $ amount"
            />
            {getFormErrorMessage("targetRaiseAmt")}
          </div>
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
            <label className="inputLabel">What is the target close date?</label>

            <Calendar
              name="targetCloseDate"
              value={
                values.targetCloseDate === "" || values.targetCloseDate === null
                  ? undefined
                  : new Date(values.targetCloseDate)
              }
              onChange={(e) => handleChange(e)}
              className={classNames({
                "p-invalid": isFormFieldInvalid("targetCloseDate"),
                "card-inputBox": true,
              })}
              placeholder="Select Target close Date"
              readOnlyInput
            />

            {getFormErrorMessage("targetCloseDate")}
          </div>
        </div>
      )}
      <div className="d-flex flex-column p-2 col-12">
        <label className="inputLabel">
          Make offering visible on opportunities page?
        </label>
        <div className="d-flex mt-2">
          <div className="flex align-items-center pr-4">
            <RadioButton
              inputId="excludeFromMarketing"
              name="excludeFromMarketing"
              value={false}
              onChange={(e) => handleChange(e)}
              checked={values.excludeFromMarketing === false}
            />

            <label htmlFor="excludeFromMarketing" className="ml-2 mb-0">
              Yes
            </label>
          </div>
          <div className="flex align-items-center pr-4">
            <RadioButton
              inputId="excludeFromMarketing"
              name="excludeFromMarketing"
              value={true}
              onChange={(e) => handleChange(e)}
              checked={values.excludeFromMarketing === true}
            />

            <label htmlFor="excludeFromMarketing" className="ml-2 mb-0">
              No
            </label>
          </div>
        </div>
        {getFormErrorMessage("excludeFromMarketing")}
      </div>
      {stateInvestment?.productState !== "SoftCommit" ? (
        <div className="d-flex flex-column p-2 col-12">
          <label className="inputLabel">
            {" "}
            Show remaining allocation to investors?
          </label>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <RadioButton
                inputId="showRemainingAllocation"
                name="showRemainingAllocation"
                value={true}
                onChange={(e) => handleChange(e)}
                checked={values.showRemainingAllocation === true}
              />

              <label htmlFor="showRemainingAllocation" className="ml-2 mb-0">
                Yes
              </label>
            </div>
            <div className="flex align-items-center pr-4">
              <RadioButton
                inputId="showRemainingAllocation"
                name="showRemainingAllocation"
                value={false}
                onChange={(e) => handleChange(e)}
                checked={values.showRemainingAllocation === false}
              />

              <label htmlFor="showRemainingAllocation" className="ml-2 mb-0">
                No
              </label>
            </div>
          </div>
          {getFormErrorMessage("showRemainingAllocation")}
        </div>
      ) : (
        <></>
      )}
      {stateInvestment?.productState !== "SoftCommit" ? (
        <div className="d-flex flex-column p-2 col-12">
          <label className="inputLabel">
            Automatically reinvest Investor earnings?
          </label>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <RadioButton
                inputId="isReinvest"
                name="isReinvest"
                value={true}
                onChange={(e) => handleChange(e)}
                checked={values.isReinvest === true}
              />

              <label htmlFor="isReinvest" className="ml-2 mb-0">
                Yes
              </label>
            </div>
            <div className="flex align-items-center pr-4">
              <RadioButton
                inputId="isReinvest"
                name="isReinvest"
                value={false}
                onChange={(e) => handleChange(e)}
                checked={values.isReinvest === false}
              />

              <label htmlFor="isReinvest" className="ml-2 mb-0">
                No
              </label>
            </div>
          </div>
          {getFormErrorMessage("isReinvest")}
        </div>
      ) : (
        <></>
      )}

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>

          <Button
            className="btn-nav btnFocus"
            // tabIndex={0}

            onClick={(e) => {
              e.preventDefault();
              handlePrevious();
            }}
          >
            <span className="ml-auto mr-auto ">Back</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>
          <Button
            className="btn-dialog btnFocus"
            type="submit"
            // tabIndex={1}
            onClick={(e) => {
              e.preventDefault();
              handleChangeState();
              handleSubmit();
            }}
          >
            <span className="ml-auto mr-auto ">Next</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep7;
