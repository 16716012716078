import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetGAAPReconciliationListService } from "../../../../services/gaap-accounting/gaap-accounting";
import error from "../../../../utils/error";
import {
  IGetGAAPReconciliationListparameters,
  IGetGAAPReconciliationListState,
} from ".";

const initialState: IGetGAAPReconciliationListState = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
  status: [],
};
export const GetGAAPReconciliationListThunk = createAsyncThunk(
  "GetGAAPReconciliationList",
  async (data: IGetGAAPReconciliationListparameters) => {
    try {
      const response = await GetGAAPReconciliationListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetGAAPReconciliationList",
  initialState,
  reducers: {
    resetGAAPMonthlyReconciliation(state) {
      state.data = [];
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetGAAPReconciliationListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetGAAPReconciliationListThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("GAAP", action.payload.data[0]);
        state.data = action.payload ? action.payload.data[0] : [];
        // state.status = action.payload
        //   ? action.payload._response.data.getReconciliationflag[0]
        //   : [];
      }
    );
    builder.addCase(GetGAAPReconciliationListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { resetGAAPMonthlyReconciliation } = slice.actions;

export default slice.reducer;
