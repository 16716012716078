import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
// import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import InvestmentDropdownFilter from "../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../components/loader/Loader";
import { getManagerFeesConfigByIdPoolfundsThunk } from "../../../../store/ManagerFees";
import { getThunkInvestmentById } from "../../../../store/features/investment/create/slice";
import { setProductId } from "../../../../store/features/investment/investment-filter/investment-filter-id/slice";
import { getThunkInvestmentIdByProduct } from "../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { errorToastMessage } from "../../../../utils/toast-message";
import AllocateEarning from "./AllocateEarning";
import AllocateEarningContinuous from "./AllocateEarningContinuous";
import AllocateEarningContinuousList from "./AllocateEarningContinuousList";
import AllocateEarningList from "./AllocateEarningList";

const AllocateAllEarning: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const firstUpdate = useRef(true);
  const [investmentID, setInvestmentId] = useState(0);
  const [tab, setTab] = useState("Fixed");
  const [openDailog, setOpenDailog] = useState(false);
  const [loadForm, setLoadForm] = useState(false);
  const location = useLocation();
  const [showAddEarnings, setShowAddEarnings] = useState(true);
  const searchParams = new URLSearchParams(location.search);

  const [productObjectByFilter, setProductObjectByFilter] = useState<any>({
    productID: 0,
    lookupCodeObj: "",
    address: "",
    zipCode: "",
  });
  // eslint-disable-next-line unicorn/no-null
  const [editData, setEditData] = useState<any>(null);
  console.log("productObjectByFilter", productObjectByFilter, investmentID);
  const dispatch = useAppDispatch();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const FixedData = useAppSelector(
    (state) => state.getAllocatedEarningData.data.investmentReturns
  );
  const ContiniousData = useAppSelector(
    (state) => state.getAllocatedEarningDataPooledFund.data.investmentReturns
  );
  console.log("earningData Fixed", FixedData, ContiniousData, tab);

  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
      fundingDate: "",
    });
    setInvestmentId(0);
    setTab("Fixed");
    setLoadForm(false);
    setShowAddEarnings(true);
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const pId = searchParams.get("productId");
    if (pId) {
      setProductObjectByFilter({
        ...productObjectByFilter,
        productID: +pId,
      });
    }
    return () => {
      setProductObjectByFilter({
        productID: 0,
        lookupCodeObj: "",
        address: "",
        zipCode: "",
        fundingDate: "",
      });
      setInvestmentId(0);
    };
  }, [searchParams.get("productId")]);
  useEffect(() => {
    setLoading(true);
    if (
      productObjectByFilter !== undefined &&
      Object.keys(productObjectByFilter).length > 0 &&
      productObjectByFilter?.productID !== 0
    ) {
      const parameter = {
        productId: productObjectByFilter?.productID,
      };
      dispatch(getThunkInvestmentIdByProduct(parameter))
        .then((response) => {
          console.log("getThunkInvestmentIdByProduct", response);

          if (response.payload._response.data.externalInvestmentId === 0) {
            console.log("externalInvestmentId === 0", response.payload);

            errorToastMessage(
              toast,
              "Can't add the investment return for this investment"
            );
            dispatch(setProductId({ productId: 0, resetFilters: true }));
            setProductObjectByFilter({
              productID: 0,
              lookupCodeObj: "",
              address: "",
              zipCode: "",
            });
            setInvestmentId(0);
          } else {
            console.log(
              "externalInvestmentId === 0",
              response.payload._response.data.externalInvestmentId
            );
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );
            dispatch(
              getThunkInvestmentById(productObjectByFilter?.productID)
            ).then((resp) => {
              console.log(
                "resp.payload.data.investorDetails[0]",
                resp.payload.data.investorDetails[0]
              );
              setProductObjectByFilter({
                ...productObjectByFilter,
                lookupCodeObj:
                  resp.payload.data.investorDetails[0].supplierProductCode,
                address:
                  resp.payload.data.investorDetails[0].productListingAddress,
                zipCode: resp.payload.data.investorDetails[0].zipCode,
                fundingDate: resp.payload.data.investorDetails[0].fundingDate,
                isPooledFund: resp.payload.data.investorDetails[0].isPooledFund,
                equityInvestment:
                  resp.payload.data.investorDetails[0].equityInvestment,
              });
              setTab(
                resp.payload.data.investorDetails[0].isPooledFund
                  ? "Continuous"
                  : "Fixed"
              );
            });

            dispatch(
              getManagerFeesConfigByIdPoolfundsThunk({
                id: 0,
                investmentId:
                  response.payload._response.data.externalInvestmentId.key,
                managerFeesType: 2,
              })
            ).finally(() => setLoading(false));
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [productObjectByFilter?.productID]);
  // console.log(
  //   activeIndexTab === 0 && FixedData,
  //   activeIndexTab,
  //   FixedData.length === 0
  // );
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div className="col-12 flex p-1">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2"> Allocate Earnings</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-start">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              (tab === "Fixed" && FixedData.length === 0) ||
              (tab === "Continuous" && ContiniousData.length === 0)
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      <div className="grid col-12">
        <div className="col-12">
          <div className="card">
            <Card>
              <div className="mt-4 pb-2">
                <InvestmentDropdownFilter
                  listPage={false}
                  // pageName="AllocateEarning"
                  setProductObjectByFilter={setProductObjectByFilter}
                  productObjectByFilter={productObjectByFilter}
                  productState="PreFunded"
                />
              </div>
              <div className="formgrid grid">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Investment Id </label>
                  <InputText
                    name="productId"
                    value={
                      productObjectByFilter?.productID === 0
                        ? ""
                        : productObjectByFilter?.productID
                    }
                    // value={investmentID === 0 ? "" : investmentID}
                    // onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": false,
                      "card-inputBox": true,
                    })}
                    placeholder="Investment Id"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Lookup Code </label>
                  <InputText
                    name="lookupCode"
                    value={productObjectByFilter?.lookupCodeObj}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Lookup code"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Zip Code </label>
                  <InputText
                    name="zipCode"
                    value={productObjectByFilter?.zipCode}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Zip Code"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Address </label>
                  <InputText
                    name="address"
                    value={productObjectByFilter?.address}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Address"
                    disabled
                  />
                </div>
              </div>
              {productObjectByFilter?.isPooledFund === true && loadForm ? (
                <AllocateEarningContinuous
                  productObjectByFilter={productObjectByFilter}
                  setProductObjectByFilter={setProductObjectByFilter}
                  investmentID={investmentID}
                  setInvestmentId={setInvestmentId}
                  editData={editData}
                  setEditData={setEditData}
                  setLoadForm={setLoadForm}
                  setTab={setTab}
                  setShowAddEarnings={setShowAddEarnings}
                />
              ) : (
                <></>
              )}
              {productObjectByFilter?.isPooledFund === false && loadForm ? (
                <AllocateEarning
                  productObjectByFilter={productObjectByFilter}
                  setProductObjectByFilter={setProductObjectByFilter}
                  investmentID={investmentID}
                  setInvestmentId={setInvestmentId}
                  editData={editData}
                  setEditData={setEditData}
                  setLoadForm={setLoadForm}
                  setShowAddEarnings={setShowAddEarnings}
                />
              ) : (
                <></>
              )}

              <div className="tabHeader-wrap flex col-12 p-0 mt-2">
                <div className="col-10 p-0">
                  <Button
                    className={
                      tab === "Fixed"
                        ? "tabHeadText Active mb-2"
                        : "tabHeadText mb-2"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      // setTranscationTab("allTransactionReport");
                      setTab("Fixed");
                    }}
                  >
                    <span className="ml-auto mr-auto ">Fixed</span>
                  </Button>
                  <Button
                    className={
                      tab === "Continuous"
                        ? "tabHeadText Active mb-2"
                        : "tabHeadText mb-2 "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      // setTranscationTab("capitalContributionsReport");
                      setTab("Continuous");
                    }}
                  >
                    <span className="ml-auto mr-auto ">Continuous</span>
                  </Button>
                </div>
                {showAddEarnings ? (
                  <div className="justify-content-end flex col-2 p-0">
                    <Button
                      className="btn-navActive "
                      type="button"
                      onClick={(e) => {
                        setLoadForm(true);
                        setShowAddEarnings(false);
                      }}
                      disabled={productObjectByFilter?.productID === 0}
                      label="Add Earnings"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {tab === "Fixed" ? (
                <>
                  <AllocateEarningList
                    productObjectByFilter={productObjectByFilter}
                    setProductObjectByFilter={setProductObjectByFilter}
                    investmentID={investmentID}
                    setInvestmentId={setInvestmentId}
                    setEditData={setEditData}
                    openDailog={openDailog}
                    setOpenDailog={setOpenDailog}
                    setLoadForm={setLoadForm}
                    setShowAddEarnings={setShowAddEarnings}
                  />
                </>
              ) : (
                <>
                  <AllocateEarningContinuousList
                    productObjectByFilter={productObjectByFilter}
                    setProductObjectByFilter={setProductObjectByFilter}
                    investmentID={investmentID}
                    setInvestmentId={setInvestmentId}
                    setEditData={setEditData}
                    openDailog={openDailog}
                    setOpenDailog={setOpenDailog}
                    setLoadForm={setLoadForm}
                    setShowAddEarnings={setShowAddEarnings}
                  />
                </>
              )}
              {/* <TabView
                  activeIndex={activeIndexTab}
                  onTabChange={(e) => {
                    setActiveIndexTab(e.index);
                  }}
                  className="pl-2"
                >
                  <TabPanel header="Fixed">
                    <AllocateEarningList
                      productObjectByFilter={productObjectByFilter}
                      setProductObjectByFilter={setProductObjectByFilter}
                      investmentID={investmentID}
                      setInvestmentId={setInvestmentId}
                      setEditData={setEditData}
                      openDailog={openDailog}
                      setOpenDailog={setOpenDailog}
                    />
                  </TabPanel>
                  <TabPanel header="Continuous">
                    <AllocateEarningContinuousList
                      productObjectByFilter={productObjectByFilter}
                      setProductObjectByFilter={setProductObjectByFilter}
                      investmentID={investmentID}
                      setInvestmentId={setInvestmentId}
                      setEditData={setEditData}
                      openDailog={openDailog}
                      setOpenDailog={setOpenDailog}
                    />
                  </TabPanel>
                </TabView> */}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllocateAllEarning;
