import { LoadScript } from "@react-google-maps/api";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { getThunkMap } from "../../../../store/Googlemap/slice";
import { getInvestingAccountsLookupsThunk } from "../../../../store/Investor/investor-approval/investing-accounts-lookups/slice";
import { getManagerFeesConfigByIdPoolfundsThunk } from "../../../../store/ManagerFees";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
import { getThunkSupplier } from "../../../../store/features/dropdown/supplier/slice";
import {
  addThunkInvestment,
  getThunkInvestmentById,
  thunkReverseInvestmentToDeal,
  setForm,
} from "../../../../store/features/investment/create/slice";
import { DeleteDealThunk } from "../../../../store/features/investment/delete-deal/slice";
import { deletePooledfundInvestmentbyProductIdThunk } from "../../../../store/features/investment/deletepooledfund-investment";
import { setPageName } from "../../../../store/features/investment/update/update-page/slice";
import { getThunkAllocateEarningPooledFund } from "../../../../store/features/operation/allocate-earning/get-earning-data-pooledfund";
import { getThunkInvestmentIdByProduct } from "../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getAllocatedInvestorsByInvestmentPoolfundsThunk,
  resetAllocaterInvestoresByInvestmentPoolFund,
} from "../../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

// import FormSkeleton from "./FormSkeleton";

interface IProps {
  pageName: string;
  handlePrevious?: any;
  handleNext?: any;
  offering?: string;
}

export default function FormDetails(props: IProps) {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const countryDropdown = useAppSelector(
    (state) => state.investingAccountsLookups.countriesDropdown
  );
  // const todayDate = new Date();

  const stateInvestment = useAppSelector((state) => state.addInvestment);
  //  const status = useAppSelector((state) => state.addInvestment.loading);
  const stateDropDownList = useAppSelector((state) => state.dropdownList.data);
  const supplierDropDown = useAppSelector((state) => state.supplierList.data);
  const [excludeOpportunities, setExcludeOpportunities] = useState<any>();
  const [isReinvest, setIsReInvest] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openReverseInvConfirmDialog, setReverseInvConfirmDialog] =
    useState(false);
  const [allocation, setAllocation] = useState<any>();
  const [openWarningDailog, setOpenWarningDailog] = useState<boolean>(false);
  const [warningType, setWarningType] = useState<any>("");
  const [warningMessage, setWarningMessage] = useState<any>("");
  const [openDeleteInvestmentDialog, setOpenDeleteInvestmentDialog] =
    useState(false);
  const [resetChange, setResetChange] = useState<boolean>(false);
  // const [location, setLocation] = useState<any>();
  const googleMap1 = useAppSelector((state) =>
    state.Map.gmap ? state.Map?.gmap[0]?.name : ""
  );
  const countryValue: any = countryDropdown
    ? countryDropdown?.find((item) => item.value === "United States")
    : 0;
  const supplierDropdownList: any = [];

  supplierDropDown?.map((item: any) => {
    //
    return supplierDropdownList.push({
      name: item.supplierName,
      code: item.supplierID,
    });
  });

  const formik = useFormik({
    initialValues: {
      productName: "",
      supplierProductCode: "",
      isPhysicalAddress: "",
      productListingAddress: "",
      address2: "",
      supplierID: 0,
      zipCode: "",
      city: "",
      stateCode: "",
      interestRate: "",
      equityInvestment: "",
      assetClassID: "",
      investmentLengthID: "",
      earningsPayoutTiming: "",
      estExitDate: "",
      dealHealth: "",
      latitude: undefined,
      longitude: undefined,
      unitPriceCalculated: 10,
      numberOfUnits: undefined,
      fundingDate: "",
      externalInvestmentID: "",
      listPrice: undefined,
      actualExitDate: "",
      targetRaiseAmt: 0,
      targetCloseDate: "",
      timingOfWithdrawals: "",
      timingOfDeposits: "",
      descriptions: "",
      countryId: countryValue.key,
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (
        stateInvestment.data?.productState === "PreFunded" &&
        stateInvestment.data?.isPooledFund === false
      ) {
        if (!data.listPrice) {
          errors.listPrice = " Total investment is required";
        } else if (!/^[+-]?(\d*\.)?\d+$/.test(data.listPrice)) {
          errors.listPrice = "Total investment should be a no.";
        } else if (!/^[1-9]\d*0$/.test(data.listPrice)) {
          errors.listPrice = "Number should be divisible by 10 ";
        }
        if (data.listPrice && data.listPrice > 20_000_000_000) {
          errors.listPrice =
            "Total investment should be less than equal to $20B";
        }
        if (!data.fundingDate) {
          errors.fundingDate = "Funding date is required";
        }
        // if (!data.actualExitDate) {
        //   errors.actualExitDate = "Actual exit date is required";
        // }
      } else if (
        stateInvestment.data?.productState === "PreFunded" &&
        stateInvestment.data?.isPooledFund === true
      ) {
        if (data.listPrice === "") {
          errors.listPrice = " Total investment is required";
        } else {
          delete errors.listPrice;
        }
        if (!data.fundingDate) {
          errors.fundingDate = "Funding date is required";
        }
      }
      if (!data.productName) {
        errors.productName = "Name is required";
      }
      if (!data.supplierProductCode) {
        errors.supplierProductCode = "Lookup code is required";
      }
      if (
        data.supplierProductCode &&
        !/^[\d A-Za-z-]+$/.test(data.supplierProductCode)
      ) {
        errors.supplierProductCode =
          "Lookup Code can only contain letters, numbers, hyphens (dashes) and spaces";
      }
      if (!data.interestRate) {
        console.log("testing------------------");
        errors.interestRate = "Interest rate is required";
      }
      if (data.interestRate && data.interestRate.length > 50) {
        errors.interestRate =
          "Enter interest rate with maximum length of 50 characters";
      }
      if (stateInvestment.data?.productState === "SoftCommit") {
        if (!data.targetCloseDate) {
          errors.targetCloseDate = "Target close date is required";
        }
        if (!data.targetRaiseAmt) {
          errors.targetRaiseAmt = "Target raised amount is required";
        }

        if (data.targetRaiseAmt && data.targetRaiseAmt > 20_000_000_000) {
          errors.targetRaiseAmt =
            "The target raise amount should be less than equal to $20B";
        }
      }
      // if (!data.equityInvestment) {
      //   errors.equityInvestment = "Equity investment is required";
      // }
      if (!data.supplierID) {
        errors.supplierID = "Supplier is required";
      }
      if (!data.assetClassID) {
        errors.assetClassID = "Asset class is required";
      }
      if (!data.investmentLengthID) {
        errors.investmentLengthID = "Investment duration is required";
      }
      // if (
      //   stateInvestment.data?.productState !== "SoftCommit" &&
      //   !data.earningsPayoutTiming
      // ) {
      //   errors.earningsPayoutTiming = "Earnings timing is required";
      // }
      if (!data.estExitDate) {
        errors.estExitDate = "Estimate exit date is required";
      }

      if (data.isPhysicalAddress === "yes") {
        if (data.zipCode) {
          if (/^[0^-]*$/.test(data.zipCode)) {
            errors.zipCode = "Invalid zipcode";
          } else if (
            data.zipCode?.length !== 5 &&
            data.zipCode?.length !== 10 &&
            formik.values.countryId === countryValue.key
          ) {
            errors.zipCode = "Zip code should be 5 or 9 digits";
          }
        } else {
          errors.zipCode = "Zipcode is required";
        }
        if (!data.city) {
          errors.city = "City is required";
        } else if (!/^(\D*)$/.test(data.city)) {
          errors.city = "Invalid city";
        }

        if (!data.stateCode) {
          errors.stateCode = "State is required";
        }
        if (!data.countryId) {
          errors.countryId = "Country is required";
        }
        if (!data.productListingAddress) {
          errors.productListingAddress = "Address is required";
        }
      }

      // if (!data.timingOfDeposits) {
      //   errors.timingOfDeposits = "Timing deposit is required";
      // }
      // if (!data.timingOfWithdrawals) {
      //   errors.timingOfWithdrawals = "Timing Withdrawal is required";
      // }
      // if (!data.descriptions) {
      //   errors.descriptions = "Description field cannot be empty";
      // }

      return errors;
    },
    onSubmit: async (data) => {
      setLoading(true);
      console.log("Before Submit", data);
      await dispatch(addThunkInvestment(stateInvestment.data!))
        .then((response) => {
          console.log("1");
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
            console.log("2");
          } else if (response.meta.requestStatus === "fulfilled") {
            if (!response.payload.success) {
              setLoading(false);
              errorToastMessage(toast, "Lookup code already exists");
            } else if (
              response.payload.data.productDetails[0].productID !== 0
            ) {
              successToastMessage(toast, "Data Save Successfully");

              if (props.pageName === "CreateNew") {
                setTimeout(() => {
                  props.handleNext();
                }, 600);
              }
            }
          }
        })
        .catch((error: any) => {
          console.log("error", error);

          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  // const dateStateChange = (e: any) => {
  //   const { name, value } = e.target;
  //   const dateFormat = value.toLocaleDateString("en-us", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "numeric",
  //   });

  //   formik.setFieldValue(name, dateFormat);
  // };
  const handleChangeState = () => {
    let isFinal;
    if (stateInvestment.data?.productState === "PreFunded") {
      isFinal = true;
    }
    if (
      stateInvestment.data?.fundingDate !== "" &&
      stateInvestment.data?.fundingDate !== null &&
      stateInvestment.data?.fundingDate !== undefined
    ) {
      console.log(
        "Split",
        stateInvestment.data?.fundingDate.split("T")[0],
        convertLocalDateToUTC(formik.values.fundingDate).split("T")[0]
      );
    }
    console.log("datafund", formik.values.fundingDate);
    // console.log("dataactual", formik.values.actualExitDate);
    // console.log("dataest", formik.values.estExitDate);
    // console.log("dattarget", formik.values.targetCloseDate);
    // console.log("dattarget", formik.values);

    const fundingDate =
      stateInvestment.data?.fundingDate !== "" &&
      stateInvestment.data?.fundingDate !== null &&
      stateInvestment.data?.fundingDate !== undefined
        ? stateInvestment.data?.fundingDate?.split("T")[0] ===
          convertLocalDateToUTC(formik.values.fundingDate).split("T")[0]
          ? stateInvestment.data?.fundingDate
          : convertLocalDateToUTC(formik.values.fundingDate)
        : formik.values.fundingDate === "" || formik.values.fundingDate === null
        ? formik.values.fundingDate
        : convertLocalDateToUTC(formik.values.fundingDate!);
    // stateInvestment.data?.fundingDate !== "" &&
    // stateInvestment.data?.fundingDate !== null &&
    // stateInvestment.data?.fundingDate !== undefined
    //   ? stateInvestment.data?.fundingDate?.split("T")[0] ===
    //     convertLocalDateToUTC(formik.values.fundingDate).split("T")[0]
    //     ? stateInvestment.data?.fundingDate
    //     : convertLocalDateToUTC(formik.values.fundingDate)
    // : formik.values.fundingDate === "" || formik.values.fundingDate === null
    // ? formik.values.fundingDate
    // : convertLocalDateToUTC(formik.values.fundingDate!);

    const actualExitDate =
      stateInvestment.data?.actualExitDate !== "" &&
      stateInvestment.data?.actualExitDate !== null &&
      stateInvestment.data?.actualExitDate !== undefined
        ? stateInvestment.data?.actualExitDate?.split("T")[0] ===
          convertLocalDateToUTC(formik.values.actualExitDate).split("T")[0]
          ? stateInvestment.data?.actualExitDate
          : convertLocalDateToUTC(formik.values.actualExitDate)
        : formik.values.actualExitDate === "" ||
          formik.values.actualExitDate === null
        ? formik.values.actualExitDate
        : convertLocalDateToUTC(formik.values.actualExitDate!);

    const estExitDate =
      stateInvestment.data?.estExitDate !== "" &&
      stateInvestment.data?.estExitDate !== null &&
      stateInvestment.data?.estExitDate !== undefined
        ? stateInvestment.data?.estExitDate?.split("T")[0] ===
          convertLocalDateToUTC(formik.values.estExitDate).split("T")[0]
          ? stateInvestment.data?.estExitDate
          : convertLocalDateToUTC(formik.values.estExitDate)
        : formik.values.estExitDate === "" || formik.values.estExitDate === null
        ? formik.values.estExitDate
        : convertLocalDateToUTC(formik.values.estExitDate!);
    // formik.values.estExitDate === "" || formik.values.estExitDate === null
    //   ? formik.values.estExitDate
    //   : convertLocalDateToUTC(formik.values.estExitDate!);
    console.log("Ea", estExitDate);
    const targetCloseDate =
      stateInvestment.data?.targetCloseDate !== "" &&
      stateInvestment.data?.targetCloseDate !== null &&
      stateInvestment.data?.targetCloseDate !== undefined
        ? stateInvestment.data?.targetCloseDate?.split("T")[0] ===
          convertLocalDateToUTC(formik.values.targetCloseDate).split("T")[0]
          ? stateInvestment.data?.targetCloseDate
          : convertLocalDateToUTC(formik.values.targetCloseDate)
        : formik.values.targetCloseDate === "" ||
          formik.values.targetCloseDate === null
        ? formik.values.targetCloseDate
        : convertLocalDateToUTC(formik.values.targetCloseDate!);
    // formik.values.targetCloseDate === "" ||
    // formik.values.targetCloseDate === null
    //   ? formik.values.targetCloseDate
    //   : convertLocalDateToUTC(formik.values.targetCloseDate);
    console.log("testing", formik.values.isPhysicalAddress);
    console.log("testing", formik.values.city);
    console.log("testing", formik.values.stateCode);
    console.log("testing", formik.values.isPhysicalAddress);
    dispatch(
      setForm({
        ...stateInvestment.data,
        productName: formik.values.productName,
        supplierID: formik.values.supplierID,
        supplierProductCode: formik.values.supplierProductCode,
        isPhysicalAddress: formik.values.isPhysicalAddress,
        productListingAddress: formik.values.productListingAddress,
        address2: formik.values.address2,
        city: formik.values.city,
        stateCode: formik.values.stateCode,
        zipCode: formik.values.zipCode,
        interestRate: formik.values.interestRate,
        equityInvestment: formik.values.equityInvestment,
        assetClassID: formik.values.assetClassID,
        investmentLengthID: formik.values.investmentLengthID,
        earningsPayoutTiming: formik.values.earningsPayoutTiming,
        estExitDate,
        listPrice: formik.values.listPrice,
        actualExitDate,
        fundingDate,
        unitPriceCalculated: formik.values.unitPriceCalculated,
        numberOfUnits: formik.values.numberOfUnits,
        isFinalstep: isFinal,
        excludeFromMarketing: excludeOpportunities,
        showRemainingAllocation: allocation,
        dealHealth: formik.values.dealHealth,
        targetRaiseAmt: formik.values.targetRaiseAmt,
        targetCloseDate,
        latitude: formik.values.latitude,
        longitude: formik.values.longitude,
        countryId: formik.values.countryId,
        descriptions: formik.values.descriptions,
        isReinvest,
      })
    );
  };

  const getLatLong = (
    city = stateInvestment.data?.city,
    stateCode = stateInvestment.data?.stateCode,
    zipcode = stateInvestment.data?.zipCode
  ) => {
    console.log("test----------");
    if (googleMap1 !== undefined && googleMap1 !== "") {
      let state: any = "";
      if (stateInvestment.data !== undefined) {
        for (const item of stateDropDownList.stateDetails) {
          state = item.key.toString() === stateCode ? item.value : stateCode;
        }
      }
      // const address = `${stateInvestment.data?.city},${state},${stateInvestment.data?.zipCode}`;
      const address = `${city},${state},${zipcode}`;
      console.log("test", address, state);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const geometry = results![0].geometry.location;
          const latitude = geometry.lat();
          console.log("test k", latitude);
          const longitude = geometry.lng();
          console.log("test k12", longitude);
          formik.setFieldValue("latitude", latitude);
          formik.setFieldValue("longitude", longitude);
          // alert("ok" + results);
        }
      });
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let city, state, zipcode: any;
    switch (name) {
      case "listPrice": {
        const units = value / formik.values.unitPriceCalculated;
        formik.setFieldValue("numberOfUnits", units);

        formik.setFieldValue(name, value);

        break;
      }
      case "city": {
        city = value;
        formik.setFieldValue(name, value);
        break;
      }
      case "stateCode": {
        state = value;
        formik.setFieldValue(name, value);
        break;
      }
      case "countryId": {
        formik.setFieldValue("stateCode", "");
        formik.setFieldValue("zipCode", "");
        formik.setFieldValue(name, value);
        break;
      }
      case "zipCode": {
        if (formik.values.countryId === countryValue.key) {
          const digitsOnly = value.replace(/\D/g, "");
          let formattedValue = digitsOnly;
          // Add hyphen after every 5 digits if length is greater than 5

          if (formattedValue.length > 5) {
            formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
          }
          zipcode = formattedValue;
          formik.setFieldValue(name, formattedValue);
        } else {
          formik.setFieldValue(name, value);
        }

        break;
      }
      // case "estExitDate":
      // case "targerCloseDate":
      // case "actualExistDate":
      // case "fundingDate": {
      //   const date = convertLocalDateToUTC(value);
      //   formik.setFieldValue(name, date);
      //   break;
      // }
      case "isPhysicalAddress": {
        formik.setFieldValue(name, value);
        if (value === "yes") {
          formik.setFieldValue("stateCode", stateInvestment.data?.stateCode);
          formik.setFieldValue("city", stateInvestment.data?.city);
          formik.setFieldValue("zipCode", stateInvestment.data?.zipCode);
          formik.setFieldValue(
            "countryId",
            stateInvestment.data?.countryId === null ||
              stateInvestment.data?.countryId === undefined
              ? countryValue.key
              : stateInvestment.data?.countryId
          );
          formik.setFieldValue(
            "productListingAddress",
            stateInvestment.data?.productListingAddress
          );
          formik.setFieldValue("address2", stateInvestment.data?.address2);
          formik.setFieldValue("longitude", stateInvestment.data?.longitude);
          formik.setFieldValue("latitude", stateInvestment.data?.latitude);
        } else {
          console.log("yesy");
          formik.setFieldValue("productListingAddress", "");
          formik.setFieldValue("address2", "");
          formik.setFieldValue("city", "");
          formik.setFieldValue("countryId", 0);
          formik.setFieldValue("stateCode", "");
          formik.setFieldValue("zipCode", "");
          formik.setFieldValue("longitude", 0);
          formik.setFieldValue("latitude", 0);
        }
        break;
      }
      default: {
        formik.setFieldValue(name, value);
      }
    }
    if (name === "zipCode" || name === "stateCode" || name === "city") {
      console.log("test lpoioe");
      getLatLong(city, state, zipcode);
    }
  };
  const changeFormik = () => {
    formik.setFieldValue("productName", stateInvestment.data?.productName);
    formik.setFieldValue(
      "supplierProductCode",
      stateInvestment.data?.supplierProductCode
    );
    formik.setFieldValue("supplierID", stateInvestment.data?.supplierID);
    formik.setFieldValue("assetClassID", stateInvestment.data?.assetClassID);
    formik.setFieldValue(
      "investmentLengthID",
      stateInvestment.data?.investmentLengthID
    );
    formik.setFieldValue(
      "productListingAddress",
      stateInvestment.data?.productListingAddress
    );
    formik.setFieldValue(
      "estExitDate",
      stateInvestment.data?.estExitDate === null
        ? stateInvestment.data?.estExitDate
        : convertUTCToLocalDate(stateInvestment.data?.estExitDate!)
    );
    formik.setFieldValue(
      "isPhysicalAddress",
      stateInvestment.data?.isPhysicalAddress
    );
    formik.setFieldValue("address2", stateInvestment.data?.address2);
    formik.setFieldValue("stateCode", stateInvestment.data?.stateCode);
    formik.setFieldValue("city", stateInvestment.data?.city);
    formik.setFieldValue("zipCode", stateInvestment.data?.zipCode);
    formik.setFieldValue("interestRate", stateInvestment.data?.interestRate);
    // formik.setFieldValue(
    //   "estExitDate",
    //   convertUTCToLocalDate(stateInvestment.data?.estExitDate!)
    // );

    formik.setFieldValue(
      "earningsPayoutTiming",
      stateInvestment.data?.earningsPayoutTiming
    );
    formik.setFieldValue(
      "equityInvestment",
      stateInvestment.data?.equityInvestment
    );
    formik.setFieldValue("listPrice", stateInvestment.data?.listPrice);
    formik.setFieldValue("numberOfUnits", stateInvestment.data?.numberOfUnits);
    formik.setFieldValue(
      "fundingDate",
      stateInvestment.data?.fundingDate === null
        ? stateInvestment.data?.fundingDate
        : convertUTCToLocalDate(stateInvestment.data?.fundingDate!)
    );
    formik.setFieldValue(
      "unitPriceCalculated",
      stateInvestment.data?.unitPriceCalculated
    );
    formik.setFieldValue(
      "actualExitDate",
      stateInvestment.data?.actualExitDate === null
        ? stateInvestment.data?.actualExitDate
        : convertUTCToLocalDate(stateInvestment.data?.actualExitDate!)
    );
    formik.setFieldValue("longitude", stateInvestment.data?.longitude);
    formik.setFieldValue("latitude", stateInvestment.data?.latitude);
    formik.setFieldValue("dealHealth", stateInvestment.data?.dealHealth);
    formik.setFieldValue(
      "targetCloseDate",
      stateInvestment.data?.targetCloseDate === null
        ? stateInvestment.data?.targetCloseDate
        : convertUTCToLocalDate(stateInvestment.data?.targetCloseDate!)
    );
    formik.setFieldValue("countryId", stateInvestment.data?.countryId);
    formik.setFieldValue(
      "targetRaiseAmt",
      stateInvestment.data?.targetRaiseAmt
    );
    formik.setFieldValue("isPooledFund", stateInvestment.data?.isPooledFund);
    formik.setFieldValue(
      "timingOfDeposits",
      stateInvestment.data?.timingOfDeposits
    );
    formik.setFieldValue(
      "timingOfWithdrawals",
      stateInvestment.data?.timingOfWithdrawals
    );
    formik.setFieldValue("descriptions", stateInvestment.data?.descriptions);
    setExcludeOpportunities(stateInvestment.data?.excludeFromMarketing);
    setAllocation(stateInvestment.data?.showRemainingAllocation);
    setIsReInvest(
      stateInvestment.data?.isReinvest
        ? stateInvestment.data?.isReinvest
        : false
    );
  };
  const resetClick = () => {
    // changeFormik();
    setResetChange((prevState) => !prevState);
    setLoading(true);
    dispatch(getThunkInvestmentById(+id!)).finally(() => {
      setLoading(false);
    });
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };
  const onDelete = () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    // api call
    const parameters = {
      productId: stateInvestment.data?.productID,
      type: "D",
    };
    console.log("test", stateInvestment.data, parameters);
    dispatch(DeleteDealThunk(parameters))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          console.log("test-----", response);
          formik.resetForm();
          successToastMessage(toast, "Data deleted successfully");
          dispatch(setPageName({ ...pageUpdateName, isReloadCardPage: true }));
          setTimeout(() => {
            navigate("/Continuous/ManageOffering");
          }, 500);
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
    // navigate
  };

  const onDeleteInvestment = () => {
    setOpenDeleteInvestmentDialog(false);
    setLoading(true);
    // api call
    const parameters = {
      productId: stateInvestment.data?.productID,
    };
    dispatch(deletePooledfundInvestmentbyProductIdThunk(parameters))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          formik.resetForm();
          successToastMessage(toast, "Investment deleted successfully");
          dispatch(setPageName({ ...pageUpdateName, isReloadCardPage: true }));
          setTimeout(() => {
            navigate("/Continuous/ManageOffering");
          }, 1000);
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
    // navigate
  };

  const onCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleReverseInvesment = () => {
    setReverseInvConfirmDialog(true);
  };
  const onConfirmReverseInvestment = async () => {
    setLoading(true);
    await dispatch(
      thunkReverseInvestmentToDeal(stateInvestment.data?.productID!)
    )
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          successToastMessage(
            toast,
            "Investment converted to deal successfully"
          );

          setTimeout(() => {
            navigate("/Continuous/ManageOffering");
          }, 1000);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
        setReverseInvConfirmDialog(false);
      });
  };

  const oncancelConfirmReverseInvestment = () => {
    setReverseInvConfirmDialog(false);
  };

  // useEffect(() => {
  //   if (
  //     formik.values.city !== "" &&
  //     formik.values.productListingAddress !== "" &&
  //     formik.values.zipCode
  //   ) {
  //     getLatLong();
  //   }
  // }, [
  //   formik.values.city,
  //   formik.values.productListingAddress,
  //   formik.values.zipCode,
  // ]);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
      };
      dispatch(getThunkSupplier(parameters));
      dispatch(getInvestingAccountsLookupsThunk());
    }
  }, [fundDropdown.fundid]);
  useEffect(() => {
    if (
      stateInvestment.data?.legalEntityID &&
      stateInvestment.data?.supplierID
    ) {
      const parameters = {
        legalEntityID: stateInvestment.data?.legalEntityID,
        supplierID: stateInvestment.data?.supplierID,
      };

      dispatch(getThunkDropdown(parameters));
    }
    changeFormik();
    if (stateInvestment.data?.isPooledFund) {
      formik.setFieldValue("unitPriceCalculated", 1 / 100);
      formik.setFieldValue("listPrice", 0);
      formik.setFieldValue("numberOfUnits", 0);
    }
  }, [
    stateInvestment.data?.legalEntityID,
    stateInvestment.data?.supplierID,
    resetChange,
  ]);

  // const checkDropdownData = (data: any[], val: any) => {
  //   return data.find((item) => item.value === val);
  // };

  useEffect(() => {
    dispatch(getThunkMap());
  }, []);

  const checkInvestmentData = async (type: string = "") => {
    const param = {
      productId: stateInvestment.data?.productID
        ? stateInvestment?.data?.productID
        : 0,
    };
    setLoading(true);
    dispatch(getThunkInvestmentIdByProduct(param))
      .then((response) => {
        if (
          response.payload._response.data !== null &&
          response.payload._response.data.externalInvestmentId.key !== 0 &&
          response.payload._response.data.externalInvestmentId !== 0
        ) {
          const externalInvestmentId =
            response.payload._response.data.externalInvestmentId.key;

          const parameter = {
            legalEntityId: fundDropdown.fundid,
            investmentId: externalInvestmentId,
          };

          dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter))
            .then((res) => {
              const paramObj = {
                legalEntityID: fundDropdown.fundid,
                investmenId: externalInvestmentId,
              };
              if (res.payload.data.output.length === 0) {
                dispatch(getThunkAllocateEarningPooledFund(paramObj))
                  .then((result) => {
                    if (
                      result.payload._response.data.investmentReturns.length > 0
                    ) {
                      setLoading(false);
                      setOpenWarningDailog(true);
                      setWarningType("allocateEarning");
                      setWarningMessage(
                        `Please reverse Earnings, before ${type} investment`
                      );
                    } else {
                      dispatch(
                        getManagerFeesConfigByIdPoolfundsThunk({
                          id: 0,
                          investmentId: externalInvestmentId,
                          managerFeesType: 1,
                        })
                      )
                        .then((feeResult) => {
                          if (feeResult.payload.data.output.length === 0) {
                            dispatch(
                              getManagerFeesConfigByIdPoolfundsThunk({
                                id: 0,
                                investmentId: externalInvestmentId,
                                managerFeesType: 2,
                              })
                            )
                              .then((feeResponse) => {
                                if (
                                  feeResponse.payload.data.output.length === 0
                                ) {
                                  if (type === "delete") {
                                    setOpenDeleteInvestmentDialog(true);
                                  } else if (type === "reverse") {
                                    setReverseInvConfirmDialog(true);
                                  } else {
                                    setOpenDeleteInvestmentDialog(false);
                                    setReverseInvConfirmDialog(false);
                                  }
                                  setLoading(false);
                                  setWarningType("");
                                  setWarningMessage("");
                                  setOpenWarningDailog(false);
                                } else {
                                  setOpenWarningDailog(true);
                                  setWarningType("managerFees2");
                                  setLoading(false);
                                  setWarningMessage(
                                    `Please delete manager fees, before ${type} investment`
                                  );
                                }
                              })
                              .catch(() => setLoading(false));
                          } else {
                            setOpenWarningDailog(true);
                            setWarningType("managerFees1");
                            setLoading(false);
                            setWarningMessage(
                              `Please delete manager fees, before ${type} investment`
                            );
                          }
                        })
                        .catch(() => setLoading(false));
                    }
                  })
                  .catch(() => setLoading(false));
              } else {
                setOpenWarningDailog(true);
                setWarningType("allocationReverse");
                setWarningMessage(
                  `Please reverse allocation, before ${type} investment`
                );
                setLoading(false);
              }
            })
            .catch(() => setLoading(false));
        } else {
          setLoading(false);
          if (type === "delete") {
            setOpenDeleteInvestmentDialog(true);
          } else if (type === "reverse") {
            setReverseInvConfirmDialog(true);
          } else {
            setOpenDeleteInvestmentDialog(false);
            setReverseInvConfirmDialog(false);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCloseWarnigDailog = () => {
    setOpenWarningDailog(false);
    setWarningType("");
  };

  const handleNavigate = (type: string) => {
    dispatch(resetAllocaterInvestoresByInvestmentPoolFund());
    let path = "";
    if (type === "allocationReverse") {
      path = `/PoolFunds/ReverseStake?productId=${stateInvestment.data?.productID}`;
    }
    if (type === "managerFees1") {
      path = `/ManagerFees?productId=${stateInvestment.data?.productID}&feeType=1`;
    }
    if (type === "managerFees2") {
      path = `/ManagerFees?productId=${stateInvestment.data?.productID}&feeType=2`;
    }
    if (type === "allocateEarning") {
      path = `/ManageInvestmentReturn/Index?productId=${stateInvestment.data?.productID}`;
    }
    navigate(path);
  };

  return (
    <>
      {" "}
      {googleMap1 && (
        <LoadScript
          googleMapsApiKey={googleMap1}
          onLoad={getLatLong}
        ></LoadScript>
      )}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        onConfirm={onDelete}
        onCancel={onCancelDelete}
        textContent="Are you sure you want to delete ?"
      />
      <ConfirmDailogbox
        openDialog={openReverseInvConfirmDialog}
        setOpenDialog={setReverseInvConfirmDialog}
        onConfirm={onConfirmReverseInvestment}
        onCancel={oncancelConfirmReverseInvestment}
        textContent="Are you sure you want to reverse this investment to deal?"
      />
      <ConfirmDailogbox
        openDialog={openDeleteInvestmentDialog}
        setOpenDialog={setOpenDeleteInvestmentDialog}
        onConfirm={onDeleteInvestment}
        onCancel={() => setOpenDeleteInvestmentDialog(false)}
        textContent="Are you sure you want to delete ?"
      />
      <Dialog
        visible={openWarningDailog}
        onHide={() => onCloseWarnigDailog()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        style={{ width: "50vw" }}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">{warningMessage}</h4>
        <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleNavigate(warningType);
            }}
            className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
          >
            {warningType === "allocationReverse"
              ? "Visit Continuous Allocation Reverse"
              : warningType === "allocateEarning"
              ? "Visit Allocate Earning"
              : "Visit Manager Fees"}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onCloseWarnigDailog();
            }}
            className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
          >
            No
          </Button>
        </div>
      </Dialog>
      {loading === true ? <Loader /> : <></>}
      <form>
        <div className="mt-2">
          <label className="inputLabel inputLabelColor ml-2">
            Basic Information
          </label>
          <div
            className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
            style={{ height: "auto" }}
          >
            <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12 ml-4">
              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">
                    {" "}
                    {stateInvestment.data?.productState === "SoftCommit"
                      ? "Deal Name"
                      : "Investment Name"}
                  </label>
                  <InputText
                    name="productName"
                    value={formik.values.productName}
                    onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("productName"),
                      "card-inputBox": true,
                    })}
                    placeholder="Enter Name"
                  />
                  {getFormErrorMessage("productName")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">Lookup Code</label>
                  <InputText
                    name="supplierProductCode"
                    value={formik.values.supplierProductCode}
                    onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("supplierProductCode"),
                      "card-inputBox": true,
                    })}
                    placeholder="Enter lookup code"
                  />

                  {getFormErrorMessage("supplierProductCode")}
                </div>
              </div>

              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">Supplier</label>

                  <Dropdown
                    inputId="supplierID"
                    name="supplierID"
                    value={formik.values.supplierID}
                    options={
                      supplierDropdownList === null ? [] : supplierDropdownList
                    }
                    filter
                    showOnFocus
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Select Supplier"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("supplierID"),
                      "card-inputBox": true,
                    })}
                  />
                  {getFormErrorMessage("supplierID")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 annualClass">
                  <label className="inputLabel">Asset Class</label>

                  <Dropdown
                    inputId="assetClassID"
                    name="assetClassID"
                    value={
                      formik.values.assetClassID === null
                        ? ""
                        : formik.values.assetClassID
                    }
                    options={
                      stateDropDownList === null
                        ? []
                        : stateDropDownList.assetClassDetails
                    }
                    showOnFocus
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Asset Class"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("assetClassID"),
                      "card-inputBox": true,
                    })}
                    disabled={stateInvestment.data?.isEditAssetClass === true}
                  />

                  {getFormErrorMessage("assetClassID")}
                </div>
              </div>
              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">Deal Health</label>

                  <Dropdown
                    inputId="dealHealth"
                    name="dealHealth"
                    value={formik.values.dealHealth}
                    options={
                      stateDropDownList === null
                        ? []
                        : stateDropDownList.dealHealth
                    }
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Deal Health"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    showOnFocus
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("dealHealth"),
                      "card-inputBox": true,
                    })}
                  />
                  {getFormErrorMessage("dealHealth")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">Investment Duration</label>

                  <Dropdown
                    inputId="investmentLengthID"
                    name="investmentLengthID"
                    value={formik.values.investmentLengthID}
                    options={
                      stateDropDownList === null
                        ? []
                        : stateDropDownList.investmentLength
                    }
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Duration"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    showOnFocus
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("investmentLengthID"),
                      "card-inputBox": true,
                    })}
                  />

                  {getFormErrorMessage("investmentLengthID")}
                </div>
              </div>
            </div>
          </div>
          <label className="inputLabel inputLabelColor ml-2 mt-4">
            Address
          </label>
          <div
            className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
            style={{ height: "auto" }}
          >
            <div className="d-flex flex-column p-2 col-12 ml-5">
              <label className="inputLabel">
                Does this investment have a physical address?
              </label>
              <div className="d-flex mt-2">
                <div className="flex align-items-center pr-4">
                  <RadioButton
                    inputId="isPhysicalAddress"
                    name="isPhysicalAddress"
                    value="yes"
                    onChange={(e) => handleChange(e)}
                    checked={formik.values.isPhysicalAddress === "yes"}
                  />

                  <label htmlFor="isPhysicalAddress" className="ml-2 mb-0">
                    Yes
                  </label>
                </div>
                <div className="flex align-items-center pr-4">
                  <RadioButton
                    inputId="isPhysicalAddress"
                    name="isPhysicalAddress"
                    value="no"
                    onChange={(e) => handleChange(e)}
                    checked={formik.values.isPhysicalAddress === "no"}
                  />

                  <label htmlFor="isPhysicalAddress" className="ml-2 mb-0">
                    No
                  </label>
                </div>
              </div>
              {getFormErrorMessage("isPhysicalAddress")}
            </div>
            {formik.values.isPhysicalAddress === "no" ? (
              <></>
            ) : (
              <>
                {/* <label className="inputLabel inputLabelColor ml-2 mt-4">
                Address
              </label>
              <div
                className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
                style={{ height: "auto" }}
              > */}
                <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12 ml-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Address</label>
                      <InputText
                        name="productListingAddress"
                        value={formik.values.productListingAddress}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "productListingAddress"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={formik.values.isPhysicalAddress === "no"}
                        placeholder="Enter Address line 1"
                      />
                      {getFormErrorMessage("productListingAddress")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">City</label>
                      <InputText
                        name="city"
                        value={formik.values.city}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("city"),
                          "card-inputBox": true,
                        })}
                        placeholder="City"
                      />
                      {getFormErrorMessage("city")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Country</label>
                      <Dropdown
                        inputId="countryId"
                        name="countryId"
                        value={formik.values.countryId}
                        options={
                          countryDropdown === null ? [] : countryDropdown
                        }
                        filter
                        resetFilterOnHide={true}
                        showFilterClear={true}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select Country"
                        // className="card-inputBox"
                        showOnFocus
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("countryId"),
                          "card-inputBox": true,
                        })}
                      />

                      {getFormErrorMessage("countryId")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">State</label>
                      {formik.values.countryId === countryValue.key ? (
                        <Dropdown
                          inputId="stateCode"
                          name="stateCode"
                          value={
                            formik.values.stateCode === null
                              ? ""
                              : formik.values.stateCode
                          }
                          options={
                            stateDropDownList === null
                              ? []
                              : stateDropDownList.stateDetails
                          }
                          filter
                          resetFilterOnHide={true}
                          showFilterClear={true}
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select State"
                          showOnFocus
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={formik.values.isPhysicalAddress === "no"}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("stateCode"),
                            "card-inputBox": true,
                          })}
                        />
                      ) : (
                        <InputText
                          name="stateCode"
                          value={formik.values.stateCode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("stateCode"),
                            "card-inputBox": true,
                          })}
                          placeholder="State"
                          maxLength={100}
                        />
                      )}

                      {getFormErrorMessage("stateCode")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">ZIP Code</label>
                      <InputText
                        name="zipCode"
                        value={formik.values.zipCode}
                        onChange={handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("zipCode"),
                          "card-inputBox": true,
                        })}
                        maxLength={
                          formik.values.countryId === countryValue.key ? 10 : 20
                        }
                        disabled={formik.values.isPhysicalAddress === "no"}
                        placeholder="Enter zip code"
                      />
                      {formik.errors.zipCode && formik.touched.zipCode && (
                        <div className="error">
                          {getFormErrorMessage("zipCode")}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            )}
          </div>

          <label className="inputLabel inputLabelColor ml-2 mt-4">
            Advanced Information
          </label>
          <div
            className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
            style={{ height: "auto" }}
          >
            <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12 ml-4">
              <div className="formgrid grid px-2">
                <div
                  className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3"
                  // style={{ fontSize: "10px" }}
                >
                  <label className="inputLabel">
                    Target IRR/ Interest Rate (%)
                  </label>
                  <InputText
                    name="interestRate"
                    value={formik.values.interestRate}
                    onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("interestRate"),
                      "card-inputBox": true,
                    })}
                    // maxLength={50}
                    placeholder="Enter Interest Rate"
                  />
                  {getFormErrorMessage("interestRate")}
                </div>
                {stateInvestment.data?.productState === "SoftCommit" ? (
                  <>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                      <label className="inputLabel">Target Raise Amount</label>
                      <InputNumber
                        name="targetRaiseAmt"
                        value={
                          formik.values.targetRaiseAmt === 0
                            ? undefined
                            : formik.values.targetRaiseAmt
                        }
                        onValueChange={(e) => handleChange(e)}
                        // maxLength={24}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("targetRaiseAmt"),
                          "w-100": true,
                        })}
                        placeholder="Enter target raise amount"
                      />
                      {getFormErrorMessage("targetRaiseAmt")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                      <label className="inputLabel">Target Close Date</label>

                      <Calendar
                        name="targetCloseDate"
                        value={
                          formik.values.targetCloseDate === "" ||
                          formik.values.targetCloseDate === null
                            ? undefined
                            : new Date(formik.values.targetCloseDate)
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("targetCloseDate"),
                          "card-inputBox": true,
                        })}
                        // minDate={todayDate}
                        placeholder="Select Target close Date"
                        readOnlyInput
                      />

                      {getFormErrorMessage("targetCloseDate")}
                    </div>
                  </>
                ) : (
                  <>
                    {stateInvestment.data?.isPooledFund ? (
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                        <label className="inputLabel">
                          Target Raise Amount
                        </label>
                        <InputNumber
                          name="targetRaiseAmt"
                          value={
                            formik.values.targetRaiseAmt === 0
                              ? undefined
                              : formik.values.targetRaiseAmt
                          }
                          onValueChange={(e) => handleChange(e)}
                          // maxLength={24}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("targetRaiseAmt"),
                            "w-100": true,
                          })}
                          placeholder="Enter target raise amount"
                        />
                        {getFormErrorMessage("targetRaiseAmt")}
                      </div>
                    ) : (
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                        <label className="inputLabel">Total Investment</label>
                        <InputNumber
                          name="listPrice"
                          value={formik.values.listPrice}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          onValueChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("listPrice"),
                            "w-100": true,
                          })}
                          placeholder="Enter Total Investment"
                          disabled={
                            stateInvestment.data?.isAllocationDone === true ||
                            stateInvestment.data?.isPooledFund
                          }
                        />
                        {getFormErrorMessage("listPrice")}
                      </div>
                    )}

                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                      <label className="inputLabel">Funding Date</label>

                      <Calendar
                        name="fundingDate"
                        value={
                          formik.values.fundingDate === null ||
                          formik.values.fundingDate === ""
                            ? undefined
                            : new Date(formik.values.fundingDate)
                        }
                        onChange={(e) => {
                          // console.log("e", e);
                          formik.setFieldValue("fundingDate", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("fundingDate"),
                          "card-inputBox": true,
                        })}
                        showOnFocus
                        // minDate={todayDate}
                        placeholder="Select Fund Date"
                        readOnlyInput
                        disabled={
                          stateInvestment.data?.isAllocationDone ||
                          stateInvestment.data?.isDistributionDone
                        }
                      />
                      {getFormErrorMessage("fundingDate")}
                    </div>
                  </>
                )}
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Numbers of Units</label>
                  <InputText
                    name="numberOfUnits"
                    value={
                      // formik.values.numberOfUnits === 0 ||
                      // formik.values.numberOfUnits === undefined
                      //   ? ""
                      //   :
                      formik.values.numberOfUnits
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("numberOfUnits"),
                      "card-inputBox": true,
                    })}
                    disabled
                    placeholder="Number of units"
                  />
                  {getFormErrorMessage("numberOfUnits")}
                </div>
              </div>
              <div className="formgrid grid px-2 align-items-baseline">
                {/* <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                  <label className="inputLabel">
                    {stateInvestment.data?.productState === "SoftCommit"
                      ? "Entry Timing"
                      : "When do investor earnings actuals start?"}
                  </label>
                  {!checkDropdownData(
                    stateDropDownList.investorEarningsAccurual || [],
                    formik.values.timingOfDeposits
                  ) ? (
                    <InputText
                      name="timingOfDeposits"
                      value={formik.values.timingOfDeposits}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("timingOfDeposits"),
                        "card-inputBox": true,
                      })}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled
                      placeholder="Enter Timing of deposits"
                    />
                  ) : (
                    <Dropdown
                      inputId="timingOfDeposits"
                      name="timingOfDeposits"
                      value={formik.values.timingOfDeposits}
                      options={
                        stateDropDownList === null
                          ? []
                          : stateDropDownList.investorEarningsAccurual
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Timing of deposits"
                      showOnFocus
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("timingOfDeposits"),
                        "card-inputBox": true,
                      })}
                    />
                  )}
                  {getFormErrorMessage("timingOfDeposits")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                  <label className="inputLabel">
                    {stateInvestment.data?.productState === "SoftCommit"
                      ? "Exit Timing"
                      : "When are investor withdrawals processed?"}
                  </label>
                  {!checkDropdownData(
                    stateDropDownList.investorwithdrawalsAccurual || [],
                    formik.values.timingOfWithdrawals
                  ) ? (
                    <InputText
                      name="timingOfWithdrawals"
                      value={formik.values.timingOfWithdrawals}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("timingOfWithdrawals"),
                        "card-inputBox": true,
                      })}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled
                      placeholder="Enter Timing of withdrawals"
                    />
                  ) : (
                    <Dropdown
                      inputId="timingOfWithdrawals"
                      name="timingOfWithdrawals"
                      value={formik.values?.timingOfWithdrawals}
                      options={
                        stateDropDownList === null
                          ? []
                          : stateDropDownList.investorwithdrawalsAccurual
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Timing of withdrawals"
                      showOnFocus
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("timingOfWithdrawals"),
                        "card-inputBox": true,
                      })}
                    />
                  )}

                  {getFormErrorMessage("timingOfWithdrawals")}
                </div> */}

                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Unit Price ($)</label>
                  <InputNumber
                    name="unitPriceCalculated"
                    value={formik.values.unitPriceCalculated}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("unitPriceCalculated"),
                      "w-100": true,
                    })}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    disabled
                    placeholder="Unit price"
                  />

                  {getFormErrorMessage("unitPriceCalculated")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                  <label className="inputLabel">Estimate Exit Date</label>

                  <Calendar
                    name="estExitDate"
                    value={
                      formik.values.estExitDate === null ||
                      formik.values.estExitDate === ""
                        ? undefined
                        : new Date(formik.values.estExitDate)
                    }
                    onChange={(e) =>
                      formik.setFieldValue("estExitDate", e.target.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("estExitDate"),
                      "card-inputBox": true,
                    })}
                    // minDate={todayDate}
                    placeholder="Select Estimate Exit Date"
                    readOnlyInput
                  />
                  {getFormErrorMessage("estExitDate")}
                </div>
                {stateInvestment.data?.productState === "SoftCommit" ? (
                  <></>
                ) : (
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                    <label className="inputLabel">Actual Exit Date</label>

                    <Calendar
                      name="actualExitDate"
                      value={
                        formik.values.actualExitDate === null ||
                        formik.values.actualExitDate === ""
                          ? undefined
                          : new Date(formik.values.actualExitDate)
                      }
                      onChange={(e) =>
                        formik.setFieldValue("actualExitDate", e.target.value)
                      }
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("actualExitDate"),
                        "card-inputBox": true,
                      })}
                      // minDate={todayDate}
                      placeholder="Select Actual Exit Date"
                      readOnlyInput
                    />
                    {getFormErrorMessage("actualExitDate")}
                  </div>
                )}
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Longitude</label>
                  <InputText
                    name="longitude"
                    value={
                      formik.values.longitude === 0 ||
                      formik.values.longitude === undefined
                        ? ""
                        : formik.values.longitude
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("longitude"),
                      "card-inputBox": true,
                    })}
                    disabled
                    placeholder="Longitude"
                  />
                  {getFormErrorMessage("longitude")}
                </div>
              </div>
              <div className="formgrid grid px-2 align-items-baseline">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Latitude</label>
                  <InputText
                    name="latitude"
                    value={
                      formik.values.latitude === 0 ||
                      formik.values.latitude === undefined
                        ? ""
                        : formik.values.latitude
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("latitude"),
                      "card-inputBox": true,
                    })}
                    disabled
                    placeholder="Latitude"
                  />
                  {getFormErrorMessage("latitude")}
                </div>

                {/* {stateInvestment.data?.productState !== "SoftCommit" && (
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                    <label className="inputLabel">
                      Timing of Earnings Payout (Ex. Monthly etc.)
                    </label>

                    <Dropdown
                      inputId="earningsPayoutTiming"
                      name="earningsPayoutTiming"
                      value={formik.values.earningsPayoutTiming}
                      options={
                        stateDropDownList === null
                          ? []
                          : stateDropDownList.earningsPayoutTiming
                      }
                      filter
                      showOnFocus
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select earnings timing"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("earningsPayoutTiming"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("earningsPayoutTiming")}
                  </div>
                )} */}
              </div>

              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12">
                  <label className="inputLabel">Description</label>
                  <InputTextarea
                    name="descriptions"
                    rows={6}
                    value={formik.values.descriptions}
                    onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("descriptions"),
                      "card-inputBox": true,
                    })}
                    placeholder="Enter investment descriptions"
                  />
                  {getFormErrorMessage("descriptions")}
                </div>
              </div>

              {/* manage investment id & external id */}

              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-4 sm:col-4">
                  <label className="inputLabel">
                    Exclude from Opportunities Page?{" "}
                  </label>
                  <div className="d-flex mt-2">
                    <div className="flex align-items-center pr-4">
                      <RadioButton
                        inputId="excludeOpportunities"
                        name="excludeOpportunities"
                        value={true}
                        onChange={(e) => setExcludeOpportunities(true)}
                        checked={excludeOpportunities === true}
                      />

                      <label
                        htmlFor="excludeOpportunities"
                        className="ml-2 mb-0"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center pr-4">
                      <RadioButton
                        inputId="excludeOpportunities"
                        name="excludeOpportunities"
                        value={false}
                        onChange={(e) => setExcludeOpportunities(false)}
                        checked={excludeOpportunities === false}
                      />

                      <label
                        htmlFor="excludeOpportunities"
                        className="ml-2 mb-0"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {stateInvestment.data?.productState === "PreFunded" &&
                  stateInvestment.data?.isPooledFund && (
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-4 sm:col-4">
                      <label className="inputLabel">
                        Show Remaining Allocation{" "}
                      </label>
                      <div className="d-flex mt-2">
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="allocation"
                            name="allocation"
                            value={true}
                            onChange={(e) => setAllocation(true)}
                            checked={allocation === true}
                          />

                          <label htmlFor="allocation" className="ml-2 mb-0">
                            Yes
                          </label>
                        </div>
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="allocation"
                            name="allocation"
                            value={false}
                            onChange={(e) => setAllocation(false)}
                            checked={allocation === false}
                          />

                          <label htmlFor="allocation" className="ml-2 mb-0">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                {stateInvestment.data?.isPooledFund && (
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-4 sm:col-4">
                    <label className="inputLabel">
                      Automatically reinvest Investor earnings?
                    </label>
                    <div className="d-flex mt-2">
                      <div className="flex align-items-center pr-4">
                        <RadioButton
                          inputId="isReinvest"
                          name="isReinvest"
                          value={true}
                          onChange={(e) => setIsReInvest(true)}
                          checked={isReinvest === true}
                        />

                        <label htmlFor="isReinvest" className="ml-2 mb-0">
                          Yes
                        </label>
                      </div>
                      <div className="flex align-items-center pr-4">
                        <RadioButton
                          inputId="isReinvest"
                          name="isReinvest"
                          value={false}
                          onChange={(e) => setIsReInvest(false)}
                          checked={isReinvest === false}
                        />

                        <label htmlFor="isReinvest" className="ml-2 mb-0">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {props.pageName === "viewDetails" ? (
            <>
              <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                  <Button
                    className="btn-dialog"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();

                      // if (Object.keys(formik.errors).length === 0) {
                      //   handleChangeState();
                      // }
                      handleChangeState();
                      formik.handleSubmit();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Save</span>
                  </Button>
                </div>
                <div className="d-flex flex-column p-2 col-12 sm:col-3">
                  <Button
                    className="btn-nav "
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      resetClick();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Cancel Changes</span>
                  </Button>
                </div>
                {!stateInvestment.data?.isAllocationDone &&
                  stateInvestment.data?.productState === "PreFunded" &&
                  !stateInvestment.data?.isPooledFund && (
                    <div className="d-flex flex-column p-2 col-12 sm:col-3">
                      <Button
                        className="btn-nav"
                        onClick={(e) => {
                          e.preventDefault();
                          handleReverseInvesment();
                        }}
                      >
                        <span className="ml-auto mr-auto ">
                          Reverse Investment Back to Deal
                        </span>
                      </Button>
                    </div>
                  )}
                {stateInvestment.data?.productState === "PreFunded" &&
                  stateInvestment.data?.isPooledFund && (
                    <div className="d-flex flex-column p-2 col-12 sm:col-3">
                      <Button
                        className="btn-nav"
                        onClick={(e) => {
                          e.preventDefault();
                          checkInvestmentData("reverse");
                        }}
                      >
                        <span className="ml-auto mr-auto ">
                          Reverse Investment Back to Deal
                        </span>
                      </Button>
                    </div>
                  )}
                {stateInvestment.data?.productState === "PreFunded" &&
                  stateInvestment.data?.isPooledFund && (
                    <div className="d-flex flex-column p-2 col-12 sm:col-3">
                      <Button
                        className="btn-nav"
                        onClick={(e) => {
                          e.preventDefault();
                          checkInvestmentData("delete");
                        }}
                      >
                        <span className="ml-auto mr-auto ">
                          Delete Investement
                        </span>
                      </Button>
                    </div>
                  )}
                {stateInvestment.data?.productState === "SoftCommit" ? (
                  <div className="d-flex flex-column p-2 col-12 sm:col-3">
                    <Button
                      className="btn-nav "
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Delete Deal</span>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
              <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                <Button
                  className="btn-nav btnFocus"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handlePrevious();
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Smooth scrolling
                    });
                  }}
                >
                  <span className="ml-auto mr-auto ">Back</span>
                </Button>
              </div>
              <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                <Button
                  className="btn-dialog btnFocus"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();

                    // if (Object.keys(formik.errors).length === 0) {
                    //   handleChangeState();
                    // }
                    handleChangeState();
                    formik.handleSubmit();
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    Publish{" "}
                    {stateInvestment.data?.productState === "SoftCommit"
                      ? "Deal"
                      : "Investment"}
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}
