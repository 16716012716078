import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
// import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import {
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiIndeterminateCircleLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { setInvestorProfileDetailsRestLoading } from "../../../../store/Investor/investor-approval/verify-profile/get-data/slice";
import { AdditionalFundThunk } from "../../../../store/features/investor/additionalFund/slice";
import { deleteInvestorThunk } from "../../../../store/features/investor/delete-investor/slice";
import { excelDownloadInvestorlistThunk } from "../../../../store/features/investor/excel-download-investor-list/slice";
import { GetInvestorListThunk } from "../../../../store/features/investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import "../investor.scss";
import { exportExcel } from "../../../../utils/excel-dataformat";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  filterValue?: string;
}

const Viewapproveregistrations: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [id, setId] = useState<any>();
  const [invAccId, setInvAccId] = useState<any>();
  const [walkinId, setWlkingId] = useState<any>();
  const [openDialog, setOpenDailog] = useState(false);
  const [values, setValues] = useState<any>();

  const Viewapproveregistor = useAppSelector(
    (state) => state.GetInvestorList.data
  );
  const isDualSignerEnable = useAppSelector(
    (state) => state.GetInvestorList.isDualsignerEnable
  );
  const portState = useAppSelector((state) => state.GetInvestorList.loading);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  console.log("Viewapproveregistor", Viewapproveregistor);

  useEffect(() => {
    if (fundDropdown?.fundid) {
      const parameters = {
        LegalEntityId: fundDropdown?.fundid,
        ApprovalStatus: "P", // uncommnet once api issue is fixed. Made change so UI work doesn't stop
        // InvType: "A",
      };
      dispatch(setInvestorProfileDetailsRestLoading("loading"));
      dispatch(GetInvestorListThunk(parameters));
    }
  }, [fundDropdown?.fundid]);

  // console.log("Viewapproveregistor", Viewapproveregistor);

  // const fullNameTemplate = (rowData: any) => {
  //   return `${rowData.firstName} ${rowData?.lastName}`;
  // };

  const actionBodyViewTemplate = (rowData: any) => {
    let dynamicClass = "btn-nav";
    let text = "View Details";
    if (
      (rowData.loginCreated &&
        rowData.counterAgreementVerification &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval") ||
      (rowData.loginCreated &&
        // rowData.counterAgreementVerification &&
        !isDualSignerEnable &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval")
    ) {
      dynamicClass = "btn-navActive";
      text = "View & Approve";
    }
    return (
      <React.Fragment>
        <Button
          // icon="pi pi-pencil"
          className={`${dynamicClass}`}
          onClick={() => {
            if (rowData.investorId > 0 && rowData.isReg) {
              // Navigate to the Enroll page
              navigate(
                `../Enroll/${rowData?.investorId}/${fundDropdown?.fundid}/${rowData?.investingAccountId}`
              );
            } else {
              // Set the view detail state
              setViewDetail(true);
              setValues(rowData);
            }
            // Set values in either case
          }}
          type="button"
        >
          <span className="ml-auto mr-auto ">{text}</span>
        </Button>
      </React.Fragment>
    );
  };
  const loginCreateTemplate = (rowData: any) => {
    console.log("loginCreateTemplate", rowData);
    if (
      (rowData.loginCreated &&
        rowData.counterAgreementVerification &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval") ||
      (rowData.loginCreated &&
        // rowData.counterAgreementVerification &&
        !isDualSignerEnable &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval")
    ) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " className="invstComplete" /> */}
          <RiCheckboxCircleFill
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }
    if (rowData.loginCreated) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " className="invstComplete" /> */}
          <RiCheckboxCircleLine
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="Awaiting Approval"
            data-pr-position="top"
            title="Awaiting Approval"
          />
        </>
      );
    }

    if (
      rowData.loginCreated &&
      rowData.isReg &&
      rowData.counterAgreementVerification &&
      rowData.stepIAInformationStatus === "Awaiting Approval" &&
      rowData.investorProfileStatus === "Awaiting Approval"
    ) {
      return (
        <>
          {/* <Tooltip target=".invstReadyToApprove-icon" /> */}
          <RiCheckboxCircleFill
            className="invstReadyToApprove text-2xl invstReadyToApprove-icon"
            data-pr-tooltip="READY TO APPROVE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }

    return (
      <>
        {/* <Tooltip
          target=".invstIncomplete-icon"
          className="invstToolTipInComplete"
        /> */}
        <RiCloseCircleLine
          className="invstIncomplete text-2xl invstIncomplete-icon"
          data-pr-tooltip="NOT STARTED"
          data-pr-position="top"
          title="NOT STARTED"
        />
      </>
    );
  };
  const profileTemplate = (rowData: any) => {
    if (
      (rowData.loginCreated &&
        rowData.counterAgreementVerification &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval") ||
      (rowData.loginCreated &&
        // rowData.counterAgreementVerification &&
        !isDualSignerEnable &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval")
    ) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " className="invstComplete" /> */}
          <RiCheckboxCircleFill
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }
    if (rowData.investorProfileStatus === "Not Completed") {
      return (
        <>
          {/* <Tooltip target=".invstNotStarted-icon" /> */}
          <RiIndeterminateCircleLine
            className="invstNotStarted text-2xl invstNotStarted-icon"
            data-pr-tooltip="Not Complete"
            data-pr-position="top"
            title="Not Completed"
          />
        </>
      );
    }
    if (rowData.investorProfileStatus === "Awaiting approval") {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " /> */}
          <RiCheckboxCircleLine
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="Awaiting Approval"
            data-pr-position="top"
            title="Awaiting Approval"
          />
        </>
      );
    }

    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return (
        <>
          {/* <Tooltip target=".invstReadyToApprove-icon toolTipReadyApprove" /> */}
          <RiCheckboxCircleFill
            className="invstReadyToApprove text-2xl invstReadyToApprove-icon"
            data-pr-tooltip="READY TO APPROVE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }

    return (
      <>
        {/* <Tooltip target=".invstIncomplete-icon toolTipNotStarted" /> */}
        <RiCloseCircleLine
          className="invstIncomplete text-2xl invstIncomplete-icon"
          data-pr-tooltip="NOT STARTED"
          data-pr-position="top"
          title="NOT STARTED"
        />
      </>
    );
  };
  const enrollmentTemplate = (rowData: any) => {
    if (
      (rowData.loginCreated &&
        rowData.counterAgreementVerification &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval") ||
      (rowData.loginCreated &&
        // rowData.counterAgreementVerification &&
        !isDualSignerEnable &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval")
    ) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " className="invstComplete" /> */}
          <RiCheckboxCircleFill
            className=" invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
          {!isDualSignerEnable && (
            <RiArrowRightSLine
              className="ArrowIconComplete text-2xl invstComplete-icon"
              data-pr-tooltip="COMPLETE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
        </>
      );
    }
    if (rowData.iaEnrollentInfoStatus === "Not Completed") {
      return (
        <>
          {/* <Tooltip target=".invstNotStarted-icon" /> */}
          {!isDualSignerEnable && (
            <RiIndeterminateCircleLine
              className=" invstNotStarted text-2xl invstNotStarted-icon"
              data-pr-tooltip="Not Complete"
              data-pr-position="top"
              title="Not Complete"
            />
          )}
          {isDualSignerEnable && (
            <RiIndeterminateCircleLine
              className="invstNotStarted text-2xl invstNotStarted-icon"
              data-pr-tooltip="Not Complete"
              data-pr-position="top"
              title="Not Complete"
            />
          )}
          {!isDualSignerEnable && (
            <RiArrowRightSLine
              className="ArrowIconComplete text-2xl invstComplete-icon"
              data-pr-tooltip="COMPLETE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
        </>
      );
    }
    if (rowData.iaEnrollentInfoStatus === "Awaiting approval") {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " /> */}
          {!isDualSignerEnable && (
            <RiCheckboxCircleLine
              className=" 2 invstComplete text-2xl invstComplete-icon"
              data-pr-tooltip="Awaiting Approval"
              data-pr-position="top"
              title="Awaiting Approval"
            />
          )}

          {isDualSignerEnable && (
            <RiCheckboxCircleLine
              className="invstComplete text-2xl invstComplete-icon"
              data-pr-tooltip="Awaiting Approval"
              data-pr-position="top"
              title="Awaiting Approval"
            />
          )}
          {!isDualSignerEnable && (
            <RiArrowRightSLine
              className="ArrowIconComplete text-2xl invstComplete-icon"
              data-pr-tooltip="COMPLETE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
        </>
      );
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return (
        <>
          {/* <Tooltip target=".invstReadyToApprove-icon" /> */}
          {!isDualSignerEnable && (
            <RiCheckboxCircleFill
              className=" 3 invstReadyToApprove text-2xl invstReadyToApprove-icon"
              data-pr-tooltip="READY TO APPROVE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
          {isDualSignerEnable && (
            <RiCheckboxCircleFill
              className="invstReadyToApprove text-2xl invstReadyToApprove-icon"
              data-pr-tooltip="READY TO APPROVE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
          {!isDualSignerEnable && (
            <RiArrowRightSLine
              className="ArrowIconComplete text-2xl invstComplete-icon"
              data-pr-tooltip="COMPLETE"
              data-pr-position="top"
              title="COMPLETE"
            />
          )}
        </>
      );
    }
    return (
      <>
        {/* <Tooltip target=".invstReadyToApprove-icon" autoHide={false} /> */}
        {!isDualSignerEnable && (
          <RiCloseCircleLine
            className="invstIncomplete text-2xl invstIncomplete-icon"
            data-pr-tooltip="NOT STARTED"
            data-pr-position="top"
            title="NOT STARTED"
          />
        )}

        {isDualSignerEnable && (
          <RiCloseCircleLine
            className="invstIncomplete text-2xl invstIncomplete-icon"
            data-pr-tooltip="NOT STARTED"
            data-pr-position="top"
            title="NOT STARTED"
          />
        )}

        {!isDualSignerEnable && (
          <RiArrowRightSLine
            className="ArrowIconComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
        )}
      </>
    );
  };
  const dualSignerTemplate = (rowData: any) => {
    if (
      (rowData.loginCreated &&
        rowData.counterAgreementVerification &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval") ||
      (rowData.loginCreated &&
        // rowData.counterAgreementVerification &&
        !isDualSignerEnable &&
        rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
        rowData.investorProfileStatus === "Awaiting approval")
    ) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " className="invstComplete" /> */}
          <RiCheckboxCircleFill
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }
    if (rowData.counterAgreementVerification) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " /> */}
          <RiCheckboxCircleLine
            className="invstComplete text-2xl invstComplete-icon"
            data-pr-tooltip="Awaiting Approval"
            data-pr-position="top"
            title="Awaiting Approval"
          />
        </>
      );
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return (
        <>
          {/* <Tooltip target=".invstReadyToApprove-icon" /> */}
          <RiCheckboxCircleFill
            className="invstReadyToApprove text-2xl invstReadyToApprove-icon"
            data-pr-tooltip="READY TO APPROVE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }
    return (
      <>
        {/* <Tooltip target=".invstIncomplete-icon" /> */}
        <RiCloseCircleLine
          className="invstIncomplete text-2xl invstIncomplete-icon"
          data-pr-tooltip="NOT STARTED"
          data-pr-position="top"
          title="NOT STARTED"
        />
      </>
    );
  };

  const Template = (rowData: any) => {
    if (rowData.counterAgreementVerification) {
      return (
        <>
          {/* <Tooltip target=".invstComplete-icon " /> */}

          <RiArrowRightSLine
            className="ArrowIconComplete text-2xl invstComplete-icon"
            data-pr-tooltip="COMPLETE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return (
        <>
          {/* <Tooltip target=".invstReadyToApprove-icon" /> */}

          <RiArrowRightSLine
            className="ArrowIcon text-2xl invstReadyToApprove-icon"
            data-pr-tooltip="READY TO APPROVE"
            data-pr-position="top"
            title="COMPLETE"
          />
        </>
      );
    }

    return (
      <>
        {/* <Tooltip target=".invstIncomplete-icon" /> */}

        <RiArrowRightSLine
          className="ArrowIconIncomplete text-2xl invstIncomplete-icon"
          data-pr-tooltip="NOT STARTED"
          data-pr-position="top"
          title="NOT STARTED"
        />
      </>
    );
  };

  const getLoginCreatedBodyClassName = (rowData: any) => {
    console.log("getLoginCreatedBodyClassName", rowData);

    if (rowData.loginCreated) {
      console.log("1", rowData.loginCreated);
      return "text-center justify-content-center LoginCreatedColumn complete ";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.stepIAInformationStatus === "Awaiting Approval" &&
      rowData.investorProfileStatus === "Awaiting Approval"
    ) {
      console.log("2", rowData.loginCreated);

      return "text-center  LoginCreatedColumn complete";
    }
    return "text-center justify-content-center  LoginCreatedColumn notstarted";
  };

  const getprofileTemplateBodyClassName = (rowData: any) => {
    if (rowData.investorProfileStatus === "Not Completed") {
      console.log("1", rowData.loginCreated);
      return "text-center ProfileColumn notComplete";
    }
    if (rowData.investorProfileStatus === "Awaiting approval") {
      console.log("2", rowData.loginCreated);

      return "text-center ProfileColumn complete";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return "text-center ProfileColumn complete";
    }
    return "text-center ProfileColumn notstarted";
  };

  const getEnrollmentTemplateBodyClassName = (rowData: any) => {
    if (
      rowData.iaEnrollentInfoStatus === "Not Completed" &&
      !isDualSignerEnable
    ) {
      console.log("1", rowData.loginCreated);
      return "text-center EnrollmentColumn lastCol notComplete";
    }
    if (
      rowData.iaEnrollentInfoStatus === "Not Completed" &&
      isDualSignerEnable
    ) {
      console.log("1", rowData.loginCreated);
      return "text-center EnrollmentColumn 2 notComplete";
    }
    if (
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      !isDualSignerEnable
    ) {
      console.log("2", rowData.loginCreated);

      return "text-center EnrollmentColumn lastCol complete";
    }
    if (
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      isDualSignerEnable
    ) {
      console.log("2", rowData.loginCreated);

      return "text-center EnrollmentColumn 2 complete";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval" &&
      !isDualSignerEnable
    ) {
      return "text-center EnrollmentColumn lastCol complete";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval" &&
      isDualSignerEnable
    ) {
      return "text-center EnrollmentColumn 2 complete";
    }
    return !isDualSignerEnable
      ? "text-center EnrollmentColumn lastCol notstarted"
      : "text-center EnrollmentColumn 2  notstarted";
  };

  const getDualSignerTemplateBodyClassName = (rowData: any) => {
    if (rowData.counterAgreementVerification) {
      return "text-center DualSignerDualColumn complete";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return "text-center DualSignerDualColumn complete";
    }

    return "text-center DualSignerDualColumn notstarted";
  };

  const getTemplateBodyClassName = (rowData: any) => {
    if (rowData.counterAgreementVerification) {
      return "text-center TempletColumn complete";
    }
    if (
      rowData.loginCreated &&
      rowData.counterAgreementVerification &&
      rowData.iaEnrollentInfoStatus === "Awaiting approval" &&
      rowData.investorProfileStatus === "Awaiting approval"
    ) {
      return "text-center TempletColumn complete";
    }

    return "text-center TempletColumn notstarted";
  };

  const countWordsAndAddEllipsis = (text: string): string => {
    if (!text) {
      return ""; // Handle empty input
    }

    const words = text.split(" ");

    const modifiedText =
      window.innerWidth < 768
        ? words.length > 4
          ? `${words.slice(0, 4).join(" ")} ...`
          : words.join(" ")
        : words.length > 4
        ? `${words.slice(0, 6).join(" ")} ...`
        : words.join(" ");

    return modifiedText;
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center widthdeletebtn"
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.investorId);
              setInvAccId(rowData.investingAccountId);
              setWlkingId(rowData.walkinUserId);
              setVisible(true);
            }}
            // disabled={rowData.investorId === 0}
          >
            <span className="text-white">DELETE</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    const deleteParms = {
      investorId: id,
      // eslint-disable-next-line unicorn/no-null
      walkinUserId: walkinId > 0 ? walkinId : 0,
      legalEntityId: fundDropdown.fundid,
      // eslint-disable-next-line unicorn/no-null
      investingAccountId: invAccId > 0 ? invAccId : 0,
    };
    dispatch(deleteInvestorThunk(deleteParms))
      .then(() => {
        const parameters = {
          LegalEntityId: fundDropdown?.fundid,
          ApprovalStatus: "P", // uncommnet once api issue is fixed. Made change so UI work doesn't stop
          // InvType: "A",
        };
        dispatch(setInvestorProfileDetailsRestLoading("loading"));
        dispatch(GetInvestorListThunk(parameters));
        successToastMessage(toast, "Deleted successfully");
      })
      .finally(() => {
        //
      });
  };

  const onYesClick = (e: any) => {
    console.log("dialog 1", e);
    const addParam = {
      email: e,
      slug: localStorage.getItem("tenentName"),
    };
    setLoader(true);
    dispatch(AdditionalFundThunk(addParam))
      .then(() => {
        const parameters = {
          LegalEntityId: fundDropdown?.fundid,
          ApprovalStatus: "P", // uncommnet once api issue is fixed. Made change so UI work doesn't stop
          // InvType: "A",
        };
        dispatch(setInvestorProfileDetailsRestLoading("loading"));
        dispatch(GetInvestorListThunk(parameters));
        successToastMessage(
          toast,
          "Investor successfully enrolled in this fund"
        );
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const dataElement = [
    // {
    //   // field: "",
    //   header: "",
    //   body: actionBodyViewTemplate,
    //   bodyClassName: "text-center",
    // },
    {
      // field: "",
      header: "",
      body: actionBodyViewTemplate,
      bodyClassName: "text-center",
    },
    {
      field: "fullName",
      header: "Investor Name",
      bodyClassName: "font-bold",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <span
          className="left-align"
          title={countWordsAndAddEllipsis(rowData.fullName)}
        >
          {countWordsAndAddEllipsis(rowData.fullName)}
        </span>
      ),
      // body: fullNameTemplate,
    },
    {
      field: "iA_NickName",
      header: "Investing Account Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <span
          className="left-align"
          title={countWordsAndAddEllipsis(rowData.iA_NickName)}
        >
          {countWordsAndAddEllipsis(rowData.iA_NickName)}
        </span>
      ),
    },
    {
      header: "Login Created",
      body: loginCreateTemplate,
      headerClassName: "headerAK", // headerClassName: "text-center-header",
      // bodyClassName: "text-center LoginCreatedColumn incomplete",
      style: { minWidth: "80px" },
      bodyClassName: (rowData: any) => getLoginCreatedBodyClassName(rowData),
      // bodyClassName: "text-center",
    },
    {
      header: "Profile",
      headerClassName: "headerAK",
      body: profileTemplate,
      style: { minWidth: "80px" },
      bodyClassName: (rowData: any) => getprofileTemplateBodyClassName(rowData),

      // bodyClassName: "text-center",
    },
    {
      header: "Enrollment",
      headerClassName: "headerAK",
      body: enrollmentTemplate,
      style: { minWidth: "80px" },
      bodyClassName: (rowData: any) =>
        getEnrollmentTemplateBodyClassName(rowData),

      // bodyClassName: "text-center",
    },
    {
      header: "Dual Signer",
      headerClassName: "headerAK",
      body: dualSignerTemplate,
      bodyClassName: (rowData: any) =>
        getDualSignerTemplateBodyClassName(rowData),
      style: { display: isDualSignerEnable ? "" : "none", minWidth: "150px" },
    },
    {
      header: "",
      body: Template,
      bodyClassName: (rowData: any) => getTemplateBodyClassName(rowData),
      style: { display: isDualSignerEnable ? "" : "none", width: "20%" },
      // style: { width: "20%" },
    },
    {
      // field: "",
      header: "",
      body: actionBodyViewTemplate,
      bodyClassName: "text-center",
    },
    {
      // field: "",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
      // style: { width: "20%" },
      bodyClassName: "text-center",
    },
  ];

  function convert(obj: any) {
    const header: any[] = [];
    for (const item in obj) {
      const key =
        item.charAt(0).toUpperCase() + item.slice(1).replace(/_/g, " ");
      header.push({
        field: item,
        header: key,
        ...(item === "creation_Date" && { dataType: "date" }),
      });
    }
    return header;
  }

  const exportExcelFunc = () => {
    if (fundDropdown?.fundid) {
      const para = {
        LegalEntityId: fundDropdown?.fundid,
        ApprovalStatus: "E",
      };
      setLoader(true);
      dispatch(excelDownloadInvestorlistThunk(para))
        .then((res: any) => {
          if (
            res.payload?.data &&
            res.payload?.data !== null &&
            res.payload?.data?.[0].length > 0
          ) {
            const getHeaderArray = convert(res.payload.data[0][0]);
            exportExcel(
              getHeaderArray,
              res.payload.data[0],
              "Users in registration",
              true
            );
          } else {
            errorToastMessage(toast, "No Data Found!");
          }
        })
        .catch((error) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  let content;
  switch (portState) {
    case "loading":
      content = <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>;
      break;
    case "succeeded":
      content = (
        <>
          <div className="col-12 col-sm-11 col-md-12 p-0 col-xl-12 ">
            <div className="card ProdCard m-0 p-0">
              <Card className="cardBackground">
                <div className="m-0 p-0">
                  <div className="row align-items-center col-12 m-0 p-0">
                    <div className="col-12 md:col-2 m-0 mb-md-0">
                      <div className="d-flex align-items-center">
                        <RiCheckboxCircleFill
                          className="invstReadyToApprove text-2xl invstReadyToApprove-icon"
                          data-pr-tooltip="READY TO APPROVE"
                          data-pr-position="top"
                          title="COMPLETE"
                        />
                        <span className="ml-2 mr-3">Complete</span>
                      </div>
                    </div>
                    <div className="col-12 md:col-2 m-0 mb-md-0">
                      <div className="d-flex align-items-center">
                        <RiCheckboxCircleLine
                          className="invstComplete text-2xl invstComplete-icon"
                          data-pr-tooltip="Awaiting Approval"
                          data-pr-position="top"
                          title="Awaiting Approval"
                        />
                        <span className="ml-2 mr-3">Awaiting Approval</span>
                      </div>
                    </div>
                    <div className="col-12 md:col-2 m-0 mb-md-0">
                      <div className="d-flex align-items-center">
                        <RiIndeterminateCircleLine
                          className="invstNotStarted text-2xl invstNotStarted-icon"
                          data-pr-tooltip="Not Complete"
                          data-pr-position="top"
                          title="Not Completed"
                        />
                        <span className="ml-2 mr-3">Not Complete</span>
                      </div>
                    </div>
                    <div className="col-12 md:col-2">
                      <div className="d-flex align-items-center">
                        <RiCloseCircleLine
                          className="invstIncomplete text-2xl invstIncomplete-icon"
                          data-pr-tooltip="NOT STARTED"
                          data-pr-position="top"
                          title="NOT STARTED"
                        />
                        <span className="ml-2 mr-3">Not Started</span>
                      </div>
                    </div>
                    <div className=" col-12 md:col-4 flex align-items-center justify-content-end gap-2">
                      <Button
                        className="remove-shadow"
                        type="button"
                        // icon="pi pi-file"
                        // rounded
                        onClick={() => exportExcelFunc()}
                        data-pr-tooltip="exportExcel"
                        disabled={
                          Viewapproveregistor === undefined ||
                          Viewapproveregistor?.length === 0 ||
                          Viewapproveregistor === null
                        }
                      >
                        {" "}
                        <span className="blueButtonShadowText">Download </span>
                      </Button>
                      <Button
                        className="btn-navActive"
                        type="button"
                        icon="pi pi-window-maximize"
                        onClick={() => setOpenDailog(true)}
                        title="View Full Screen"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="flex align-items-center justify-content-end gap-2 mb-2">
                  <Button
                    className="blueButtonShadow"
                    type="button"
                    // icon="pi pi-file"
                    // rounded
                    onClick={() => exportExcel()}
                    data-pr-tooltip="exportExcel"
                    disabled={
                      Viewapproveregistor === undefined ||
                      Viewapproveregistor?.length === 0 ||
                      Viewapproveregistor === null
                    }
                  >
                    {" "}
                    <span className="blueButtonShadowText">Download </span>
                  </Button>
                </div> */}
                <DataTableComponent
                  valueData={Viewapproveregistor}
                  fieldsElements={dataElement}
                  isPaginationAllowed={true}
                  globalFilter={props.filterValue}
                  className={"viewandapprovetable  view deletebtn zeropadding"}
                  noGridLines={true}
                  scrollable={true}
                  openDailog={openDialog}
                  setOpenDailog={setOpenDailog}
                  maxHeight="calc(-240px + 100vh)"
                />
              </Card>
            </div>
          </div>
        </>
      );
      break;
  }

  return (
    <>
      {loader && <Loader />}

      <div className="col-12 col-sm-11 col-md-12 p-0 col-xl-12">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
                onDelete();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={viewDetail}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setViewDetail(false)}
        >
          {console.log("dialog", values)}

          {values && (
            <>
              {values.investorId === 0 && values.isReg === false && (
                <>
                  <h4 className="pageHeader text-center mb-5 pt-5">
                    User has not begun the registration process. Please check
                    back later.
                  </h4>
                  <div className=" align-self-center flex align-items-center justify-content-center mx-3">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setViewDetail(false);
                      }}
                      className="blueButtonShadow w-6rem mx-1 d-block h-auto"
                    >
                      Ok
                    </Button>
                  </div>
                </>
              )}
              {values.investorId > 0 && values.isReg === false && (
                <>
                  <h4 className="pageHeader text-center mb-5 pt-5">
                    This investor has originally created a login under a diff
                    fund. And the investor has not begun registration or
                    provided consent to register in this fund. Do you still want
                    to continue to enroll this investor in this fund?
                  </h4>
                  <div className=" align-self-center flex align-items-center justify-content-center mx-3">
                    <Button
                      onClick={(e) => {
                        onYesClick(values.emailAddress);
                        setViewDetail(false);
                      }}
                      className="blueButtonShadow w-6rem mx-2 d-block h-auto"
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setViewDetail(false);
                      }}
                      className="blueButtonShadow w-6rem mx-1 d-block h-auto"
                    >
                      No
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Dialog>
      </div>

      {content}
    </>
  );
};
export default Viewapproveregistrations;
